import { Provider } from "react-redux";
import { persistor, store } from "./redux/store";
import { RouterProvider } from "react-router-dom";
import { rootRouter } from "./route/RootRoute/Route";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "@emotion/react";
import { muiTheme } from "./utils/ThemeHelper";

function App() {
    return (
        <ThemeProvider theme={muiTheme}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <RouterProvider router={rootRouter}></RouterProvider>
                </PersistGate>
            </Provider>
        </ThemeProvider>
    );
}

export default App;

import { Grid } from "@mui/material";
import { useScreenType } from "../../../../hooks/useScreenType";
import {
    ShimmerThumbnail,
    ShimmerCircularImage,
    ShimmerSectionHeader,
    ShimmerCategoryItem,
    ShimmerPostItem,
} from "react-shimmer-effects";
import { useScreenDimension } from "../../../../hooks/useScreenDimension";

export const ProfileShimmer = () => {
    /* Custom hooks */
    const { rs } = useScreenType();
    const { browserHeight } = useScreenDimension();

    /* Render */
    if (["xs", "sm"]?.includes(rs)) {
        return (
            <Grid container width="100vw">
                <Grid item xs={12}>
                    <Grid item marginTop={6}>
                        <Grid container direction="column">
                            <Grid item alignSelf="center">
                                <ShimmerCircularImage size={120} />
                            </Grid>
                            <Grid item>
                                <ShimmerSectionHeader center width={250} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} paddingX={2}>
                    <Grid
                        container
                        flexWrap="nowrap"
                        overflow="hidden"
                        columnGap={3}
                        position="relative"
                        width="100vh"
                        paddingBottom={3}
                    >
                        <Grid item width={400}>
                            <ShimmerPostItem card title imageHeight={100} />
                        </Grid>
                        <Grid item width={400}>
                            <ShimmerPostItem card title imageHeight={100} />
                        </Grid>
                        <Grid item width={400}>
                            <ShimmerPostItem card title imageHeight={100} />
                        </Grid>
                    </Grid>
                    <Grid container marginTop={5} direction="column">
                        <Grid item flex={1} xs={12}>
                            <ShimmerCategoryItem
                                hasImage
                                imageType="thumbnail"
                                imageWidth={100}
                                imageHeight={100}
                                title
                            />
                        </Grid>
                        <Grid item flex={1} xs={12}>
                            <ShimmerCategoryItem
                                hasImage
                                imageType="thumbnail"
                                imageWidth={100}
                                imageHeight={100}
                                title
                            />
                        </Grid>
                        <Grid item flex={1} xs={12}>
                            <ShimmerCategoryItem
                                hasImage
                                imageType="thumbnail"
                                imageWidth={100}
                                imageHeight={100}
                                title
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container width="100vw" position="fixed">
            <Grid item md={3}>
                <Grid
                    container
                    direction="column"
                    justifyContent="space-between"
                    height={browserHeight - 25}
                >
                    <Grid item marginTop={6}>
                        <Grid container direction="column">
                            <Grid item alignSelf="center">
                                <ShimmerCircularImage size={120} />
                            </Grid>
                            <Grid item>
                                <ShimmerSectionHeader center width={250} />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item alignSelf="center">
                        <ShimmerThumbnail rounded height={50} width={250} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={9} paddingX={2} marginTop={2}>
                <Grid container>
                    <Grid item md={12} paddingBottom={5}>
                        <Grid
                            container
                            flex={1}
                            md={12}
                            justifyContent="space-between"
                        >
                            <Grid item md={3.5}>
                                <ShimmerPostItem
                                    card
                                    title
                                    cta
                                    imageHeight={150}
                                />
                            </Grid>
                            <Grid item md={3.5}>
                                <ShimmerPostItem
                                    card
                                    title
                                    cta
                                    imageHeight={150}
                                />
                            </Grid>
                            <Grid item md={3.5}>
                                <ShimmerPostItem
                                    card
                                    title
                                    cta
                                    imageHeight={150}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item flex={1} md={12}>
                        <ShimmerCategoryItem
                            hasImage
                            imageType="thumbnail"
                            imageWidth={100}
                            imageHeight={100}
                            title
                        />
                    </Grid>
                    <Grid item flex={1} md={12}>
                        <ShimmerCategoryItem
                            hasImage
                            imageType="thumbnail"
                            imageWidth={100}
                            imageHeight={100}
                            title
                        />
                    </Grid>
                    <Grid item flex={1} md={12}>
                        <ShimmerCategoryItem
                            hasImage
                            imageType="thumbnail"
                            imageWidth={100}
                            imageHeight={100}
                            title
                        />
                    </Grid>
                    <Grid item flex={1} md={12}>
                        <ShimmerCategoryItem
                            hasImage
                            imageType="thumbnail"
                            imageWidth={100}
                            imageHeight={100}
                            title
                        />
                    </Grid>
                    <Grid item flex={1} md={12}>
                        <ShimmerCategoryItem
                            hasImage
                            imageType="thumbnail"
                            imageWidth={100}
                            imageHeight={100}
                            title
                        />
                    </Grid>
                    <Grid item flex={1} md={12}>
                        <ShimmerCategoryItem
                            hasImage
                            imageType="thumbnail"
                            imageWidth={100}
                            imageHeight={100}
                            title
                        />
                    </Grid>
                </Grid>
                {/* <ShimmerThumbnail height={250} rounded />
                <ShimmerThumbnail height={browserHeight - 300} rounded /> */}
            </Grid>
        </Grid>
    );
};

import React from "react";
import { BackIcon } from "../../../../assets/icons/BackIcon";
import { Grid } from "@mui/material";

export const PayBackIcon = () => {
    return (
        <Grid
            container
            height={40}
            width={40}
            borderRadius={20}
            justifyContent="center"
            alignItems="center"
            border={1}
            borderColor="#FFFFFF24"
        >
            <BackIcon />
        </Grid>
    );
};

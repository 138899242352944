import { CircularProgress, Grid, Typography } from "@mui/material";
import { ReactNode } from "react";

type AppButtonType = {
    children: ReactNode;
    hideBg?: boolean;
    outlineButton?: boolean;
    onClick?: any;
    background?: string;
    disabled?: boolean;
    showShadow?: boolean;
    loading?: boolean;
};

export const AppButton = (props: AppButtonType) => {
    /* Props */
    const {
        children,
        hideBg,
        outlineButton,
        onClick,
        background = "linear-gradient(#F8DC71, #CC8718)",
        disabled,
        showShadow = true,
        loading,
    } = props;

    /* Render */

    if (outlineButton) {
        return (
            <Grid
                container
                borderRadius={44}
                alignItems="center"
                justifyContent="center"
                style={{
                    padding: 1,
                    background: disabled ? "#565555" : background,
                }}
                boxShadow={
                    showShadow ? "0px 0px 24px 0px #FFDF4340" : undefined
                }
                onClick={!disabled ? onClick : undefined}
            >
                <Grid
                    xs={12}
                    borderRadius={43}
                    paddingY={1.8}
                    item
                    sx={{
                        background: "linear-gradient(#0B0B0C, #0B0B0C)",
                        // TODO: Background color
                    }}
                >
                    {children}
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid
            item
            borderRadius={44}
            border={1}
            paddingY={1.8}
            borderColor={"#353535"}
            alignItems="center"
            onClick={!disabled ? onClick : undefined}
            style={{
                background: disabled ? "#565555" : background,
            }}
            boxShadow={"0px 0px 24px 0px #FFDF4340"}
        >
            {loading ? (
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item>
                        <CircularProgress size={14} />
                    </Grid>
                </Grid>
            ) : (
                children
            )}
        </Grid>
    );
};

import { Grid } from "@mui/material";

import {
    ACCEPT_ALL,
    TERM_AND_CONDITIION,
} from "../../../../constants/AppString";
import { Terms } from "../Terms";
import { LockedContentCard } from "../LockedContentCard";
import { FullSpaceGrid } from "../../../../components/FullSpaceGrid";

type LockedContentDetailType = {
    title?: any;
    desc?: any;
    termCondition?: any;
    amount?: any;
    input?: any;
    termConditionHandler?: any;
    goldPrice?: any;
    goldPriceTime?: any;
    getNewGoldPrice?: any;
    supportUserPaymentFinalizer?: any;
    termOfUseHandler?: any;
};

export const LockedContentDetail = (props: LockedContentDetailType) => {
    /* Props */
    const {
        amount,
        input,
        termCondition,
        termConditionHandler,
        goldPrice,
        goldPriceTime,
        getNewGoldPrice,
        supportUserPaymentFinalizer,
        termOfUseHandler,
    } = props;

    /* Render */

    return (
        <>
            <FullSpaceGrid
                borderRadius="9px 9px 0px 0px"
                bgColor="rgba(11, 11, 12, 1)"
                justifyContent="space-between"
            >
                <Grid item position="relative" paddingX={2} top={-30}>
                    <LockedContentCard
                        weight={input}
                        amount={amount}
                        isWeb={false}
                    />
                </Grid>
                <Grid item>
                    <Terms
                        checked={termCondition}
                        title={ACCEPT_ALL}
                        subTitle={TERM_AND_CONDITIION}
                        checkHandler={termConditionHandler}
                        linkHandler={termOfUseHandler}
                    />
                </Grid>
            </FullSpaceGrid>
        </>
    );
};

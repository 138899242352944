import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

export const usePaymentRD = () => {
    /* Hooks */
    const amount = useSelector(
        (state: RootState) => state?.contentConstant?.payment?.amount
    );
    const mobile = useSelector(
        (state: RootState) => state?.contentConstant?.payment?.mobile
    );
    const name = useSelector(
        (state: RootState) => state?.contentConstant?.payment?.name
    );
    const pancard = useSelector(
        (state: RootState) => state?.contentConstant?.payment?.pancard
    );
    const priceSecond = useSelector(
        (state: RootState) => state?.contentConstant?.payment?.priceSecond
    );
    const priceMinute = useSelector(
        (state: RootState) => state?.contentConstant?.payment?.priceMinute
    );
    const shortId = useSelector(
        (state: RootState) => state?.contentConstant?.payment?.shortId
    );
    const title = useSelector(
        (state: RootState) => state?.contentConstant?.payment?.title
    );
    const description = useSelector(
        (state: RootState) => state?.contentConstant?.payment?.description
    );
    const weight = useSelector(
        (state: RootState) => state?.contentConstant?.payment?.weight
    );
    const expiredAt = useSelector(
        (state: RootState) => state?.contentConstant?.payment?.expiredAt
    );
    const createdAt = useSelector(
        (state: RootState) => state?.contentConstant?.payment?.createdAt
    );
    const thumbnail = useSelector(
        (state: RootState) => state?.contentConstant?.payment?.thumbnail
    );
    const minWeight = useSelector(
        (state: RootState) => state?.contentConstant?.payment?.minWeight
    );
    const transactionCount = useSelector(
        (state: RootState) => state?.contentConstant?.payment?.transactionCount
    );
    const image = useSelector(
        (state: RootState) => state?.contentConstant?.payment?.image
    );
    const gstRate = useSelector(
        (state: RootState) => state?.contentConstant?.payment?.gstRate
    );
    const goldWeightPrice = useSelector(
        (state: RootState) => state?.contentConstant?.payment?.goldWeightPrice
    );
    const goldPrice = useSelector(
        (state: RootState) => state?.contentConstant?.payment?.goldPrice
    );
    const gstPrice = useSelector(
        (state: RootState) => state?.contentConstant?.payment?.gstPrice
    );
    const viewCount = useSelector(
        (state: RootState) => state?.contentConstant?.payment?.viewCount
    );
    const isFreeContent = useSelector(
        (state: RootState) => state?.contentConstant?.payment?.isFreeContent
    );
    const paymentType = useSelector(
        (state: RootState) => state?.contentConstant?.payment?.paymentType
    );
    const category = useSelector(
        (state: RootState) => state?.contentConstant?.payment?.category
    );
    const amountType = useSelector(
        (state: RootState) => state?.contentConstant?.payment?.amountType
    );

    return {
        paymentType,
        viewCount,
        gstPrice,
        goldPrice,
        goldWeightPrice,
        gstRate,
        image,
        transactionCount,
        minWeight,
        thumbnail,
        expiredAt,
        createdAt,
        weight,
        name,
        mobile,
        paymentAmount: amount,
        pancard,
        priceMinute: Number(priceMinute),
        priceSecond: Number(priceSecond),
        shortId,
        title,
        description,
        amount,
        isFreeContent,
        category,
        amountType,
    };
};

import React from "react";

export const TelegramIcon = () => {
    return (
        <svg
            width={56}
            height={56}
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 28c0 15.464 12.536 28 28 28s28 -12.536 28 -28S43.464 0 28 0 0 12.536 0 28Z"
                fill="#0088CC"
            />
            <path
                d="M14.174 27.49a1227.548 1227.548 0 0 1 15.036 -6.476c7.162 -2.979 8.649 -3.497 9.618 -3.514a1.697 1.697 0 0 1 1 0.3 1.086 1.086 0 0 1 0.367 0.7c0.034 0.199 0.078 0.655 0.045 1.014 -0.389 4.077 -2.069 13.972 -2.923 18.536 -0.361 1.932 -1.072 2.582 -1.761 2.643 -1.498 0.14 -2.632 -0.988 -4.085 -1.94 -2.268 -1.484 -3.55 -2.414 -5.754 -3.864 -2.545 -1.68 -0.896 -2.598 0.557 -4.108 0.378 -0.392 6.975 -6.392 7.104 -6.938 0.017 -0.067 0.031 -0.322 -0.12 -0.456 -0.151 -0.132 -0.372 -0.087 -0.532 -0.05 -0.23 0.05 -3.856 2.447 -10.878 7.188 -1.028 0.706 -1.96 1.053 -2.794 1.033 -0.921 -0.02 -2.691 -0.521 -4.01 -0.949 -1.613 -0.524 -2.895 -0.801 -2.786 -1.694 0.059 -0.462 0.7 -0.938 1.918 -1.422Z"
                fill="white"
            />
        </svg>
    );
};

import React from "react";
import { Helmet } from "react-helmet";

type SearchEngineTypeType = {
    title: string;
    description: string;
    image: string;
};

export const SearchEngineType = (props: SearchEngineTypeType) => {
    /* Props */
    const { description, image, title } = props;

    /* Render */
    return (
        <Helmet>
            <script type="application/ld+json">
                {/* {
                "@context": "http://schema.org",
                "@type": "ProfilePage",
                "name": "Title of the Profile",
                "description": "Description of the profile.",
                "image": {
                    "@type": "ImageObject",
                    "url": "https://example.com/image.jpg",
                    "height": "800",
                    "width": "800"
                }
            } */}
            </script>
            <title>{title}</title>
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
            {/* <meta property="og:url" content="URL of your page" />
            <meta property="og:type" content="website" />{" "} */}
            {/* or "article", "video", etc. */}
        </Helmet>
    );
};

export const GoldBricks = () => {
    return (
        <svg
            width="54"
            height="53"
            viewBox="0 0 54 53"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_d_470_7422)">
                <path
                    d="M13.9877 22.293L25.9161 15.1219C26.0302 15.0527 26.0567 15.0416 26.4097 15.2521C26.7626 15.4626 30.0399 17.3987 30.3876 17.6203C30.7352 17.8419 30.8334 18.1327 30.9899 18.6119C31.1465 19.0911 32.4866 24.2318 32.6087 24.6168C32.7308 25.0018 32.9988 25.481 32.3513 25.8882C31.7038 26.2953 24.7033 30.5165 24.7033 30.5165L20.7467 32.9207L14.675 25.9519L13.9824 22.2985L13.9877 22.293Z"
                    fill="url(#paint0_radial_470_7422)"
                />
                <g>
                    <path
                        d="M13.9877 22.293L25.9161 15.1219C26.0302 15.0527 26.0567 15.0416 26.4097 15.2521C26.7626 15.4626 30.0399 17.3987 30.3876 17.6203C30.7352 17.8419 30.8334 18.1327 30.9899 18.6119C31.1465 19.0911 32.4866 24.2318 32.6087 24.6168C32.7308 25.0018 32.9988 25.481 32.3513 25.8882C31.7038 26.2953 24.7033 30.5165 24.7033 30.5165L20.7467 32.9207L14.675 25.9519L13.9824 22.2985L13.9877 22.293Z"
                        fill="black"
                    />
                </g>
                <path
                    d="M30.3881 17.6174C30.0405 17.3958 26.7632 15.4597 26.4102 15.2492C26.0573 15.0387 26.0307 15.0498 25.9166 15.119L13.9883 22.2928L14.4421 24.6859L15.2621 25.4061L17.8096 26.2536L18.9295 25.2288L30.7729 18.0384C30.6827 17.8694 30.5686 17.7337 30.3908 17.6174H30.3881Z"
                    fill="url(#paint1_radial_470_7422)"
                />
                <path
                    d="M20.8243 32.2196C20.2644 30.145 19.2268 26.1094 19.0914 25.6302C18.9561 25.1511 18.834 25.0513 18.2767 24.6885C17.7168 24.3256 14.6571 22.4588 14.3334 22.3065C14.0096 22.1541 13.9194 22.3535 13.8875 22.4228C13.853 22.492 12.2343 26.6329 12.0565 26.993C11.8787 27.3558 12.2343 28.0178 12.5262 28.2062C12.8154 28.3917 20.1688 32.8234 20.3466 32.9176C20.5244 33.0118 21.0552 33.0727 20.8269 32.2168L20.8243 32.2196Z"
                    fill="url(#paint2_radial_470_7422)"
                />
                <path
                    d="M15.7871 23.1596C16.5912 23.7606 18.6876 24.4226 19.4014 24.237C20.1153 24.0515 27.6173 19.9521 27.6173 19.9521C27.6173 19.9521 19.0538 25.381 18.9291 25.2286C18.8044 25.0763 15.7871 23.1596 15.7871 23.1596Z"
                    fill="#FCFFFF"
                />
                <path
                    d="M24.2681 26.3256C24.629 26.4087 25.5896 26.863 25.847 26.6636C26.1151 26.4558 25.9293 26.2952 25.1173 26.0265C24.3848 25.7855 23.0341 25.0903 23.9178 24.1181C24.1354 23.8799 24.3769 23.722 24.6237 23.6168C24.5626 23.4533 24.4353 23.0933 24.4379 22.9936C24.4406 22.8634 24.9129 22.747 25.0801 22.736C25.2473 22.7249 25.3163 23.4506 25.3163 23.4506C25.9983 23.3952 26.5768 23.6223 26.5768 23.6223C26.5768 23.6223 26.3804 24.6444 26.0354 24.4366C25.7037 24.2372 24.7059 24.6277 24.8015 24.9213C24.8944 25.2177 25.786 25.1014 26.6378 25.0737C27.5905 25.0432 27.7497 27.3615 26.8581 27.6164C26.675 27.669 26.4521 27.6967 26.2159 27.705C26.3247 27.9349 26.468 28.3725 26.3167 28.4778C26.1655 28.5858 25.6772 28.7326 25.6613 28.6107C25.6454 28.4889 25.5021 27.6856 25.5021 27.6828C24.9235 27.6302 24.4034 27.5083 24.2893 27.3976C24.0823 27.2009 23.9231 26.2481 24.2654 26.3284L24.2681 26.3256Z"
                    fill="#FCFFFF"
                />
                <path
                    d="M24.5708 26.3256C24.9317 26.4087 25.8924 26.863 26.1498 26.6636C26.4178 26.4558 26.232 26.2952 25.42 26.0265C24.6876 25.7855 23.3369 25.0903 24.2205 24.1181C24.4381 23.8799 24.6796 23.722 24.9264 23.6168C24.8654 23.4533 24.738 23.0933 24.7407 22.9936C24.7433 22.8634 25.2157 22.747 25.3829 22.736C25.55 22.7249 25.619 23.4506 25.619 23.4506C26.301 23.3952 26.8795 23.6223 26.8795 23.6223C26.8795 23.6223 26.6832 24.6444 26.3382 24.4366C26.0065 24.2372 25.0087 24.6277 25.1042 24.9213C25.1971 25.2177 26.0887 25.1014 26.9406 25.0737C27.8932 25.0432 28.0525 27.3615 27.1608 27.6164C26.9777 27.669 26.7548 27.6967 26.5186 27.705C26.6274 27.9349 26.7707 28.3725 26.6195 28.4778C26.4682 28.5858 25.9799 28.7326 25.964 28.6107C25.9481 28.4889 25.8048 27.6856 25.8048 27.6828C25.2263 27.6302 24.7062 27.5083 24.5921 27.3976C24.3851 27.2009 24.2258 26.2481 24.5682 26.3284L24.5708 26.3256Z"
                    fill="#CC3202"
                />
                <path
                    d="M24.4302 26.3256C24.7911 26.4087 25.7517 26.863 26.0091 26.6636C26.2772 26.4558 26.0914 26.2952 25.2794 26.0265C24.547 25.7855 23.1962 25.0903 24.0799 24.1181C24.2975 23.8799 24.539 23.722 24.7858 23.6168C24.7248 23.4533 24.5974 23.0933 24.6 22.9936C24.6027 22.8634 25.075 22.747 25.2422 22.736C25.4094 22.7249 25.4784 23.4506 25.4784 23.4506C26.1604 23.3952 26.7389 23.6223 26.7389 23.6223C26.7389 23.6223 26.5425 24.6444 26.1976 24.4366C25.8658 24.2372 24.8681 24.6277 24.9636 24.9213C25.0565 25.2177 25.9481 25.1014 26.7999 25.0737C27.7526 25.0432 27.9118 27.3615 27.0202 27.6164C26.8371 27.669 26.6142 27.6967 26.378 27.705C26.4868 27.9349 26.6301 28.3725 26.4788 28.4778C26.3276 28.5858 25.8393 28.7326 25.8234 28.6107C25.8075 28.4889 25.6642 27.6856 25.6642 27.6828C25.0857 27.6302 24.5655 27.5083 24.4514 27.3976C24.2444 27.2009 24.0852 26.2481 24.4275 26.3284L24.4302 26.3256Z"
                    fill="#EF8800"
                />
                <g>
                    <path
                        d="M17.1909 24.0128C16.7531 23.7441 16.2489 23.4366 15.7871 23.1597L16.7 30.7351C17.305 31.1007 17.9021 31.458 18.4302 31.7765L17.1909 24.0128Z"
                        fill="#FCFFFF"
                    />
                </g>
                <g>
                    <path
                        d="M14.4526 29.3807C14.9807 29.6992 15.5725 30.0565 16.1722 30.4194L14.9303 22.6501C14.6278 22.47 14.4048 22.3454 14.3305 22.3094C14.0068 22.157 13.9166 22.3565 13.8847 22.4257C13.8768 22.4396 13.7998 22.639 13.6777 22.9464L14.4526 29.3807Z"
                        fill="#FCFFFF"
                    />
                </g>
                <g>
                    <path
                        d="M12.0137 27.1232C12.2392 27.9015 19.5767 32.3166 20.4445 32.4468C21.3122 32.577 32.3834 25.5527 32.6116 24.6138C32.6594 24.7523 32.8584 25.1705 32.7337 25.4696C32.609 25.7688 32.1924 25.9904 31.1203 26.6385C30.0482 27.2866 21.1158 32.6961 20.9089 32.8207C20.6992 32.9454 20.6515 33.0312 20.4047 32.9454C20.1579 32.8595 12.9133 28.4472 12.9133 28.4472C12.9133 28.4472 12.6426 28.2865 12.4675 28.1647C12.2923 28.0428 11.9288 27.4667 12.0163 27.1232H12.0137Z"
                        fill="#734706"
                    />
                </g>
                <path
                    d="M23.2143 28.314L35.1426 21.1429C35.2568 21.0737 35.2833 21.0626 35.6362 21.2731C35.9892 21.4836 39.2665 23.4197 39.6141 23.6413C39.9617 23.8629 40.0599 24.1537 40.2165 24.6329C40.3731 25.1121 41.7132 30.2528 41.8353 30.6378C41.9573 31.0228 42.2253 31.502 41.5778 31.9092C40.9303 32.3163 33.9299 36.5375 33.9299 36.5375L29.9732 38.9417L23.9016 31.9729L23.209 28.3195L23.2143 28.314Z"
                    fill="url(#paint3_radial_470_7422)"
                />
                <path
                    d="M39.6147 23.6389C39.267 23.4173 35.9897 21.4812 35.6368 21.2707C35.2838 21.0602 35.2573 21.0712 35.1432 21.1405L23.2148 28.3115L23.6686 30.7046L24.4886 31.4248L27.0362 32.2724L28.156 31.2475L39.9995 24.0571C39.9092 23.8881 39.7951 23.7524 39.6173 23.6361L39.6147 23.6389Z"
                    fill="url(#paint4_radial_470_7422)"
                />
                <path
                    d="M30.0508 38.2411C29.4909 36.1665 28.4533 32.1309 28.318 31.6517C28.1826 31.1725 28.0606 31.0728 27.5033 30.71C26.946 30.3471 23.8837 28.4803 23.5599 28.3279C23.2362 28.1756 23.1459 28.375 23.1141 28.4443C23.0822 28.5135 21.4608 32.6544 21.283 33.0145C21.1052 33.3773 21.4608 34.0393 21.7527 34.2276C22.042 34.4132 29.3954 38.8449 29.5732 38.9391C29.751 39.0333 30.2817 39.0942 30.0535 38.2383L30.0508 38.2411Z"
                    fill="url(#paint5_radial_470_7422)"
                />
                <path
                    d="M25.0137 29.1811C25.8177 29.7821 27.9142 30.4441 28.628 30.2585C29.3418 30.0729 36.8438 25.9736 36.8438 25.9736C36.8438 25.9736 28.2804 31.4025 28.1556 31.2501C28.0309 31.0978 25.0137 29.1811 25.0137 29.1811Z"
                    fill="#FCFFFF"
                />
                <g>
                    <path
                        d="M26.4175 30.0343C25.9796 29.7656 25.4754 29.4581 25.0137 29.1812L25.9265 36.7566C26.5316 37.1222 27.1287 37.4795 27.6567 37.798L26.4175 30.0343Z"
                        fill="#FCFFFF"
                    />
                </g>
                <g>
                    <path
                        d="M23.6792 35.4022C24.2073 35.7207 24.799 36.078 25.3988 36.4409L24.1568 28.6716C23.8543 28.4915 23.6314 28.3669 23.5571 28.3309C23.2334 28.1785 23.1431 28.378 23.1113 28.4472C23.1033 28.4611 23.0264 28.6605 22.9043 28.9679L23.6792 35.4022Z"
                        fill="#FCFFFF"
                    />
                </g>
                <g>
                    <path
                        d="M21.2402 33.1447C21.4658 33.923 28.8033 38.3381 29.671 38.4683C30.5388 38.5985 41.61 31.5742 41.8382 30.6353C41.886 30.7737 42.085 31.192 41.9603 31.4911C41.8356 31.7903 41.4189 32.0118 40.3468 32.66C39.2747 33.3081 30.3424 38.7176 30.1354 38.8422C29.9258 38.9668 29.878 39.0527 29.6312 38.9668C29.3844 38.881 22.1398 34.4687 22.1398 34.4687C22.1398 34.4687 21.8692 34.308 21.694 34.1889C21.5189 34.0698 21.1553 33.4909 21.2429 33.1475L21.2402 33.1447Z"
                        fill="#734706"
                    />
                </g>
                <g>
                    <path
                        d="M25.0137 28.9043C25.6797 28.8268 37.1676 22.1792 37.1676 22.1792L36.053 21.52L25.0137 28.9071V28.9043Z"
                        fill="black"
                    />
                </g>
                <path
                    d="M18.4301 18.2153L30.3585 11.0443C30.4726 10.9751 30.4991 10.964 30.852 11.1745C31.205 11.385 34.4823 13.3211 34.8299 13.5427C35.1776 13.7643 35.2758 14.0551 35.4323 14.5343C35.5889 15.0134 36.929 20.1542 37.0511 20.5392C37.1731 20.9242 37.4412 21.4034 36.7937 21.8105C36.1462 22.2177 29.1457 26.4389 29.1457 26.4389L25.1891 28.8431L19.1174 21.8743L18.4248 18.2209L18.4301 18.2153Z"
                    fill="url(#paint6_radial_470_7422)"
                />
                <path
                    d="M34.8305 13.5427C34.4829 13.3211 31.2055 11.385 30.8526 11.1745C30.4997 10.964 30.4731 10.9751 30.359 11.0443L18.4307 18.2153L18.8844 20.6084L19.7044 21.3286L22.252 22.1762L23.3718 21.1513L35.2153 13.9609C35.125 13.792 35.0109 13.6562 34.8331 13.5399L34.8305 13.5427Z"
                    fill="url(#paint7_radial_470_7422)"
                />
                <path
                    d="M25.2667 28.1454C24.7094 26.0708 23.6691 22.0352 23.5338 21.556C23.3985 21.0768 23.2764 20.9771 22.7191 20.6143C22.1592 20.2514 19.0995 18.3846 18.7757 18.2322C18.452 18.0799 18.3618 18.2793 18.3299 18.3486C18.2981 18.4178 16.6767 22.5587 16.4989 22.9188C16.3211 23.2816 16.6767 23.9436 16.9686 24.1319C17.2605 24.3203 24.6112 28.7492 24.789 28.8434C24.9668 28.9376 25.4975 28.9985 25.2693 28.1426L25.2667 28.1454Z"
                    fill="url(#paint8_radial_470_7422)"
                />
                <path
                    d="M20.2295 19.0854C21.0336 19.6864 23.13 20.3484 23.8438 20.1628C24.5577 19.9772 32.0597 15.8779 32.0597 15.8779C32.0597 15.8779 23.4962 21.3068 23.3715 21.1544C23.2467 21.0021 20.2295 19.0854 20.2295 19.0854Z"
                    fill="#FCFFFF"
                />
                <g>
                    <path
                        d="M21.6333 19.9386C21.1954 19.6699 20.6912 19.3624 20.2295 19.0854L21.1424 26.6609C21.7474 27.0265 22.3445 27.3838 22.8726 27.7023L21.6333 19.9386Z"
                        fill="#FCFFFF"
                    />
                </g>
                <g>
                    <path
                        d="M18.894 25.3031C19.4221 25.6216 20.0139 25.9789 20.6136 26.3418L19.3717 18.5724C19.0692 18.3924 18.8463 18.2678 18.7719 18.2318C18.4482 18.0794 18.358 18.2788 18.3261 18.3481C18.3182 18.3619 18.2412 18.5614 18.1191 18.8688L18.894 25.3031Z"
                        fill="#FCFFFF"
                    />
                </g>
                <g>
                    <path
                        d="M16.4561 23.049C16.6816 23.8273 24.0191 28.2424 24.8868 28.3726C25.7546 28.5028 36.8258 21.4785 37.054 20.5396C37.1018 20.678 37.3008 21.0963 37.1761 21.3954C37.0514 21.6946 36.6347 21.9161 35.5627 22.5643C34.4906 23.2124 25.5582 28.6219 25.3512 28.7465C25.1416 28.8711 25.0938 28.957 24.847 28.8711C24.6002 28.7853 17.3557 24.373 17.3557 24.373C17.3557 24.373 17.085 24.2123 16.9098 24.0932C16.7347 23.9741 16.3711 23.3952 16.4587 23.0518L16.4561 23.049Z"
                        fill="#734706"
                    />
                </g>
            </g>
            <defs>
                <filter
                    id="filter0_d_470_7422"
                    x="0"
                    y="0.874512"
                    width="54.001"
                    height="52.002"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1.875" />
                    <feGaussianBlur stdDeviation="6" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 1 0 0 0 0 0.87451 0 0 0 0 0.262745 0 0 0 0.5 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_470_7422"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_470_7422"
                        result="shape"
                    />
                </filter>
                <radialGradient
                    id="paint0_radial_470_7422"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(26.1125 21.8858) scale(10.7395 11.2094)"
                >
                    <stop stopColor="#F6F700" />
                    <stop offset="0.27" stopColor="#F4E100" />
                    <stop offset="0.8" stopColor="#F0AB00" />
                    <stop offset="1" stopColor="#EF9600" />
                </radialGradient>
                <radialGradient
                    id="paint1_radial_470_7422"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(21.2674 19.4344) scale(10.7713 11.2427)"
                >
                    <stop stopColor="#F6F700" />
                    <stop offset="0.27" stopColor="#F4E100" />
                    <stop offset="0.8" stopColor="#F0AB00" />
                    <stop offset="1" stopColor="#EF9600" />
                </radialGradient>
                <radialGradient
                    id="paint2_radial_470_7422"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(13.6275 24.9516) scale(6.10349 6.37056)"
                >
                    <stop stopColor="#F6F700" />
                    <stop offset="0.27" stopColor="#F4E100" />
                    <stop offset="0.8" stopColor="#F0AB00" />
                    <stop offset="1" stopColor="#EF9600" />
                </radialGradient>
                <radialGradient
                    id="paint3_radial_470_7422"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(32.0299 32.7567) scale(10.7395 11.2094)"
                >
                    <stop stopColor="#F6F700" />
                    <stop offset="0.27" stopColor="#F4E100" />
                    <stop offset="0.8" stopColor="#F0AB00" />
                    <stop offset="1" stopColor="#EF9600" />
                </radialGradient>
                <radialGradient
                    id="paint4_radial_470_7422"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(30.4913 25.4559) scale(10.7713 11.2427)"
                >
                    <stop stopColor="#F6F700" />
                    <stop offset="0.27" stopColor="#F4E100" />
                    <stop offset="0.8" stopColor="#F0AB00" />
                    <stop offset="1" stopColor="#EF9600" />
                </radialGradient>
                <radialGradient
                    id="paint5_radial_470_7422"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(22.854 30.9759) scale(6.10349 6.37056)"
                >
                    <stop stopColor="#F6F700" />
                    <stop offset="0.27" stopColor="#F4E100" />
                    <stop offset="0.8" stopColor="#F0AB00" />
                    <stop offset="1" stopColor="#EF9600" />
                </radialGradient>
                <radialGradient
                    id="paint6_radial_470_7422"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(27.2483 22.6609) scale(10.7395 11.2094)"
                >
                    <stop stopColor="#F6F700" />
                    <stop offset="0.27" stopColor="#F4E100" />
                    <stop offset="0.8" stopColor="#F0AB00" />
                    <stop offset="1" stopColor="#EF9600" />
                </radialGradient>
                <radialGradient
                    id="paint7_radial_470_7422"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(25.7097 15.3569) scale(10.7713 11.2427)"
                >
                    <stop stopColor="#F6F700" />
                    <stop offset="0.27" stopColor="#F4E100" />
                    <stop offset="0.8" stopColor="#F0AB00" />
                    <stop offset="1" stopColor="#EF9600" />
                </radialGradient>
                <radialGradient
                    id="paint8_radial_470_7422"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(18.0699 20.8774) scale(6.10349 6.37056)"
                >
                    <stop stopColor="#F6F700" />
                    <stop offset="0.27" stopColor="#F4E100" />
                    <stop offset="0.8" stopColor="#F0AB00" />
                    <stop offset="1" stopColor="#EF9600" />
                </radialGradient>
            </defs>
        </svg>
    );
};

import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

export const useContentContextRD = () => {
    /* Hooks */
    const goldifyLinkCategories = useSelector(
        (state: RootState) =>
            state?.contentConstant?.contentConstantData?.data?.GOLDIFY
                ?.categories
    );
    const goldifyLinkType = useSelector(
        (state: RootState) =>
            state?.contentConstant?.contentConstantData?.data?.GOLDIFY?.types
    );
    const contentConstantloading = useSelector(
        (state: RootState) => state?.profile?.profileData?.loading
    );
    const contentConstantError = useSelector(
        (state: RootState) => state?.profile?.profileData?.error
    );

    return {
        goldifyLinkCategories,
        goldifyLinkType,
        contentConstantloading,
        contentConstantError,
    };
};

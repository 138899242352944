import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React from "react";
import { Repeater } from "../Repeater";
import { SB } from "../../constants/AppConstant";
import { AppText } from "../AppText";

type AppRadioButtonType = {
    optionList: { label: string; value: string }[];
    defaultValue?: string;
    value?: string;
    onChange: (type: string) => void;
};

export const AppRadioButton = (props: AppRadioButtonType) => {
    /* Props */
    const { optionList, defaultValue, value, onChange } = props;

    /* Handler */
    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange((event.target as HTMLInputElement).value);
    };

    /* Render */
    return (
        <RadioGroup
            defaultValue={defaultValue}
            row
            value={value}
            onChange={onChangeHandler}
        >
            <Repeater
                count={optionList?.length}
                renderItem={(index) => {
                    const item = optionList?.[index];
                    return (
                        <FormControlLabel
                            value={item?.value}
                            control={
                                <Radio
                                    style={{
                                        color: "#F6CB61",
                                    }}
                                />
                            }
                            label={
                                <AppText children={item?.label} fontSize={16} />
                            }
                        />
                    );
                }}
            />
        </RadioGroup>
    );
};

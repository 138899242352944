import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { profileReducer } from "./slices/profile";
import { contentConstantReducer } from "./slices/content";
import { persistStore, persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session"; // use sessionStorage
import { Persistor, PersistorOptions } from "redux-persist/es/types"; // Import types

export type AppDispatch = typeof store.dispatch;

const persistConfig = {
    key: "root",
    storage: storageSession,
};

const rootReducer = combineReducers({
    profile: profileReducer,
    contentConstant: contentConstantReducer,
});

const persistedReducer = persistReducer<RootState>(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export const persistor: Persistor = persistStore(
    store,
    null as unknown as PersistorOptions
);

export type RootState = ReturnType<typeof rootReducer>;

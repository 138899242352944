import { useEffect, useState } from "react";

export const useScreenDimension = () => {
    /* States */
    const [browserHeight, setBrowserHeight] = useState(window.innerHeight);
    const [browserWidth, setBrowserWidth] = useState(window.innerWidth);

    /* Effects */
    useEffect(() => {
        // Function to update the state with the current browser height
        const updateBrowserDimension = () => {
            setBrowserHeight(window.innerHeight);
            setBrowserWidth(window.innerWidth);
        };

        // Attach the event listener to the window resize event
        window.addEventListener("resize", updateBrowserDimension);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener("resize", updateBrowserDimension);
        };
    }, []); // Empty dependency array to run the effect only once during component mount

    return { browserHeight, browserWidth };
};

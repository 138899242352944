import { Box, Grid } from "@mui/material";
import { ResponsiveLayout } from "../../../../components/ResponsiveLayout";
import { LinkMobilePaymentBackground } from "../../components/LinkMobilePaymentBackground";
import { AppText } from "../../../../components/AppText";
import { Terms } from "../../components/Terms";
import { AppButton } from "../../../../components/AppButton";
import { HandPayIcon } from "../../../../assets/icons/HandPayIcon";
import { LabelInput } from "../../../../components/LabelInput";
import { useAppNavigation } from "../../../../hooks/useAppNavigation";
import { useParams } from "react-router-dom";
import { ContentBackIcon } from "../../components/ContentBackIcon";
import { Leaving } from "../../components/Leaving";
import { useScreenDimension } from "../../../../hooks/useScreenDimension";
import { ContentUserDetail } from "../../components/ContentUserDetail";
import { WebPayBackground } from "../../components/WebPayBackground";
import { LockedContentWeb } from "../../components/LockedContentWeb";
import { PaymentTimer } from "../../components/PaymentTimer";
import { PaymentButton } from "../../components/PaymentButton";
import { useGetProfileRD } from "../../../Profile/hooks/useGetProfileRD";
import { usePaymentRoute } from "../../hooks/usePaymentRoute";
import { usePaymentRD } from "../../hooks/usePaymentRD";
import { useGoldPrice } from "../../../../hooks/useGoldPrice";
import { useState } from "react";
import { postRequest } from "../../../../network/Request";
import { GOLD_PAYMENT } from "../../../../network/Url";
import { PAN_VALUE } from "../../../../constants/AppConstant";
import { PAY, PAYMENT_TITLE, PAY_TO } from "../../../../constants/AppString";
import { useUserPayment } from "../../hooks/useUserPayment";
import { PaymentInput } from "../../components/PaymentInput";
import { AppSnackBar } from "../../../../components/AppSnackBar";
import { OtpVerifySection } from "../../components/OptVerifySection";
import { AppModal } from "../../../../components/AppModal";

export const PaymentDetailPage = () => {
    /* Custom hooks */
    const {
        generatPaymentToken,
        getNewGoldPrice,
        loading,
        panHander,
        name,
        mobileNumber,
        setName,
        setmobileNumber,
        pan,
        paymentAmount,
        getGoldPrice,

        paymentValidate,
        handleToastClose,
        openToast,
        paymentMessage,
        sendOtpHandler,
        panError,
        mobileNumberVerified,
        resetOtpHandler,
        verifyOtpHandler,
        optSentDetail,
        openOtpVerify,
        mobileLoading,
        resendOtpHandler,
        whatsAppHandler,
        whatsAppFlag,
    } = useUserPayment();
    const { goldWeightPrice, gstRate, goldPrice, gstPrice, weight } =
        usePaymentRD();

    /* Render */
    const panCondition = Number(paymentAmount) > PAN_VALUE;

    return (
        <>
            <ResponsiveLayout
                mobile={
                    <LinkMobilePaymentBackground
                        isPayment
                        contentPaddingBottom={2}
                        amount={paymentAmount}
                        getNewGoldPrice={getNewGoldPrice}
                        goldPrice={Number(goldPrice)}
                        supportUserPaymentFinalizer={generatPaymentToken}
                        disable={paymentValidate}
                        gstRate={gstRate}
                        goldWeightPrice={goldWeightPrice}
                        goldWeight={weight}
                        gstPrice={gstPrice}
                        loading={loading}
                        openOtpVerify={openOtpVerify}
                        resetOtpHandler={resetOtpHandler}
                        verifyOtpHandler={verifyOtpHandler}
                        resendOtpHandler={resendOtpHandler}
                        optSentDetail={optSentDetail}
                        mobileNumberVerified={mobileNumberVerified}
                        mobileLoading={mobileLoading}
                        bottomComponent={
                            <Grid
                                container
                                direction="column"
                                bgcolor="#0B0B0C"
                                overflow="scroll"
                                height="100%"
                                justifyContent="space-between"
                            >
                                <Grid item marginX={2}>
                                    <Grid container marginTop={2}>
                                        <Grid item xs={12}>
                                            <AppText
                                                children={PAYMENT_TITLE}
                                                fontWeight="700"
                                                fontSize={16}
                                            />
                                        </Grid>
                                        <Grid item xs={12} paddingTop={2}>
                                            <PaymentInput
                                                panError={panError}
                                                name={name}
                                                nameHandler={setName}
                                                pan={pan}
                                                mobileLoading={mobileLoading}
                                                mobileNumber={mobileNumber}
                                                moblieHandler={setmobileNumber}
                                                panHandler={panHander}
                                                panCondition={panCondition}
                                                mobileNumberVerified={
                                                    mobileNumberVerified
                                                }
                                                sendOtpHandler={sendOtpHandler}
                                                whatsAppHandler={
                                                    whatsAppHandler
                                                }
                                                whatsAppFlag={whatsAppFlag}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    />
                }
                web={
                    <>
                        <WebPayBackground>
                            <LockedContentWeb />
                            <ContentUserDetail
                                goldWeight={weight}
                                gstPrice={gstPrice}
                                mobileNumberVerified={mobileNumberVerified}
                                sendOtpHandler={sendOtpHandler}
                                generatPaymentToken={generatPaymentToken}
                                getGoldPrice={getGoldPrice}
                                getNewGoldPrice={getNewGoldPrice}
                                goldPrice={goldPrice}
                                loading={loading}
                                mobileNumber={mobileNumber}
                                name={name}
                                pan={pan}
                                panCondition={panCondition}
                                panHander={panHander}
                                paymentAmount={paymentAmount}
                                setName={setName}
                                setmobileNumber={setmobileNumber}
                                gstRate={gstRate}
                                goldWeightPrice={goldWeightPrice}
                                panError={panError}
                                paymentValidate={paymentValidate}
                                mobileLoading={mobileLoading}
                                whatsAppHandler={whatsAppHandler}
                                whatsAppFlag={whatsAppFlag}
                            />
                        </WebPayBackground>
                        <AppModal open={openOtpVerify} handleClose={() => {}}>
                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                height="100%"
                            >
                                <Grid item>
                                    <OtpVerifySection
                                        resetOtpHandler={resetOtpHandler}
                                        verifyOtpHandler={verifyOtpHandler}
                                        resendOtpHandler={resendOtpHandler}
                                        optSentDetail={optSentDetail}
                                        mobileNumberVerified={
                                            mobileNumberVerified
                                        }
                                        mobileLoading={mobileLoading}
                                    />
                                </Grid>
                            </Grid>
                        </AppModal>
                    </>
                }
            />

            <AppSnackBar
                open={openToast}
                onClose={handleToastClose}
                message={paymentMessage}
            />
        </>
    );
};

export const useSession = () => {
    /* Handler */
    const setSession = ({ key, value }: { key: string; value: string }) => {
        window.sessionStorage.setItem(key, value);
    };

    const getSession = ({ key }: { key: string }) => {
        try {
            return window.sessionStorage.getItem(key);
        } catch {}
    };

    const removeSession = ({ key }: { key: string }) => {
        if (getSession({ key })) {
            window.sessionStorage.removeItem(key);
        }
    };

    const clearSession = () => {
        window.sessionStorage.clear();
    };

    return { setSession, getSession, removeSession, clearSession };
};

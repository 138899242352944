import React from "react";
import { useAppNavigation } from "../../../../hooks/useAppNavigation";
import { useGetProfileRD } from "../useGetProfileRD";

export const useUserProfileRoute = () => {
    /* Custom Hooks */
    const { navigate } = useAppNavigation();
    const { profileData } = useGetProfileRD();

    /* Handler */
    const profileRouteHandler = ({ username }: { username: string }) => {
        navigate(`/${username}`);
    };

    return { profileRouteHandler };
};

import React from "react";
import { useGetProfileRD } from "../../segments/Profile/hooks/useGetProfileRD";
import { NoUserImage } from "../../utils/Image";

export const UserProfileImage = () => {
    /* Custom hooks */
    const { profileData } = useGetProfileRD();

    return (
        <img
            src={
                profileData?.creatorInfo?.profileImg
                    ? profileData?.creatorInfo?.profileImg
                    : NoUserImage
            }
            height={120}
            width={120}
            style={{
                borderRadius: 62,
                objectFit: "cover",
                boxShadow: "0px 0px 24px 0px #FFDF4340",
                border: "2px solid white",
            }}
        />
    );
};

import { Grid } from "@mui/material";
import { ContentBackIcon } from "../ContentBackIcon";
import { AppText } from "../../../../components/AppText";
import { useAppNavigation } from "../../../../hooks/useAppNavigation";
import { useScreenDimension } from "../../../../hooks/useScreenDimension";
import { ReactNode } from "react";
import { LockedContentWeb } from "../LockedContentWeb";
import { BackgroundImage } from "../../../../assets/images/BackgroundImage";
import { usePaymentRoute } from "../../hooks/usePaymentRoute";

type WebPayBackground = {
    children: ReactNode;
    backHandler?: () => void;
    numberOfCol?: number;
};

export const WebPayBackground = (props: WebPayBackground) => {
    /* Props */
    const { children, backHandler, numberOfCol = 12 } = props;

    /* Custom Hooks */
    const { browserHeight } = useScreenDimension();
    const { goBackHandler } = useAppNavigation();

    /* Render */

    const backButtonHandler = backHandler ? backHandler : goBackHandler;

    return (
        <Grid
            container
            minHeight={browserHeight}
            width="100vw"
            paddingX={8.5}
            paddingY={5.5}
            bgcolor="#0B0B0C"
        >
            <Grid item md={12}>
                <Grid container alignItems="center">
                    <Grid
                        item
                        onClick={backButtonHandler}
                        sx={{ cursor: "pointer" }}
                    >
                        <ContentBackIcon height={44} width={44} />
                    </Grid>
                    <Grid item paddingLeft={3}>
                        <AppText
                            children="Back"
                            fontWeight="700"
                            fontSize={18}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container alignItems="center" justifyContent="center">
                    <Grid item height={"100%"} md={numberOfCol}>
                        <Grid
                            container
                            width="100%"
                            marginTop={3}
                            border={1}
                            borderColor="#DBA436"
                            minHeight={browserHeight - 0.2 * browserHeight}
                            borderRadius={3}
                        >
                            {children}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

import { AppText } from "../../components/AppText";
import { SvgPropType } from "../../types/PropsType";

export const GoldWeight = (props: SvgPropType) => {
    /* Props */
    const { fill } = props;

    /* Render */
    return (
        <svg
            width="48"
            height="35"
            viewBox="0 0 38 30"
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M37 3.47005C37 2.10588 36.1046 1 35 1H3C1.89543 1 1 2.10588 1 3.47005V21.9955C1 23.3596 1.89543 24.4655 3 24.4655H13.1716C13.702 24.4655 14.2107 24.7257 14.5858 25.189L17.0858 28.2765C17.8668 29.2412 19.1332 29.2412 19.9142 28.2765L22.4142 25.189C22.7893 24.7257 23.298 24.4655 23.8284 24.4655H35C36.1046 24.4655 37 23.3596 37 21.9955V3.47005Z"
                stroke="#212121"
            />
        </svg>
    );
};

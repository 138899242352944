import React from "react";

export const HandPayIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_518_12664)">
                <path
                    d="M21.7413 13.1063C20.3968 12.619 18.9052 12.7273 17.644 13.4026L15.5881 14.3274C15.422 13.3806 14.6948 12.669 13.7972 12.6417C13.7919 12.6415 10.7146 12.6068 10.7146 12.6068C8.25154 11.9312 6.65113 12.7993 5.73816 13.6549C5.55144 13.8299 5.38724 14.0101 5.24272 14.1877C4.99636 13.9224 4.56149 13.8515 4.24436 14.0259L1.45098 15.5614C1.08867 15.7606 0.931031 16.1975 1.08298 16.5816L3.81293 23.4828C3.99231 23.9362 4.5436 24.1366 4.97375 23.9002L7.76713 22.3647C8.02898 22.2207 8.18313 21.9525 8.1916 21.6699H12.3607C13.0408 21.6699 13.7122 21.4913 14.3023 21.1533C14.3023 21.1533 22.1433 16.6555 22.1876 16.6153C23.2856 15.6164 23.3195 13.6781 21.7413 13.1063C22.3709 13.3344 20.3968 12.619 21.7413 13.1063ZM4.99872 22.013L2.87749 16.6506L4.21924 15.9131L6.34047 21.2754L4.99872 22.013ZM21.1384 15.3465L13.4843 19.7297C13.1427 19.9254 12.7541 20.0288 12.3606 20.0288H7.61457L6.02103 16.0004C6.15754 15.7222 6.42642 15.2607 6.86308 14.8515C7.75739 14.0134 8.93616 13.799 10.3669 14.2141C10.4384 14.2348 10.5125 14.2457 10.5869 14.2466L13.7502 14.2822C13.831 14.2892 13.9801 14.4687 13.9801 14.7409C13.9801 15.0211 13.8261 15.1998 13.7456 15.1998H10.6375V16.8409H13.7456C14.1306 16.8409 14.4889 16.7107 14.7872 16.4876L18.3462 14.8867C18.365 14.8782 18.3835 14.8691 18.4016 14.8592C19.2554 14.395 20.2683 14.3183 21.1805 14.6489C21.5415 14.7797 21.2807 15.202 21.1384 15.3465Z"
                    fill="black"
                />
                <path
                    d="M16.1991 11.8248C12.9392 11.8248 10.2871 9.17246 10.2871 5.91236C10.2871 2.65231 12.9392 0 16.1991 0C19.459 0 22.111 2.65231 22.111 5.91236C22.111 9.17246 19.4589 11.8248 16.1991 11.8248ZM16.1991 1.64113C13.8456 1.64113 11.9309 3.55718 11.9309 5.91236C11.9309 8.26754 13.8456 10.1836 16.1991 10.1836C18.5526 10.1836 20.4673 8.26749 20.4673 5.91236C20.4673 3.55723 18.5525 1.64113 16.1991 1.64113Z"
                    fill="black"
                />
                <path
                    d="M18.0528 4.7673L17.8787 5.45819H14.6182L14.7923 4.7673H18.0528ZM16.466 9.11963L14.6853 6.68893L14.6803 6.14349H15.5632C15.7838 6.14349 15.9703 6.10526 16.1228 6.0288C16.2754 5.95235 16.3914 5.8414 16.471 5.69595C16.5506 5.54863 16.5904 5.36961 16.5904 5.1589C16.5904 4.84562 16.5075 4.59668 16.3417 4.41206C16.1759 4.22745 15.9164 4.13515 15.5632 4.13515H14.6182L14.7997 3.39111H15.5632C15.9744 3.39111 16.3135 3.46477 16.5805 3.61209C16.8474 3.7594 17.0464 3.96359 17.1773 4.22466C17.3083 4.48572 17.3738 4.78688 17.3738 5.12813C17.3738 5.43208 17.3199 5.70713 17.2122 5.95328C17.1044 6.19943 16.9328 6.39989 16.6973 6.55466C16.4619 6.70944 16.1527 6.79988 15.7697 6.82599L15.7473 6.83158L17.3738 9.07208V9.11963H16.466ZM18.0553 3.39111L17.8787 4.0876L15.2723 4.07082L15.4488 3.39111H18.0553Z"
                    fill="black"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.2454 4.6174L17.9957 5.60829H17.4773C17.4484 5.74977 17.406 5.88495 17.3497 6.01354C17.2297 6.28761 17.0383 6.5102 16.7798 6.6801C16.5706 6.81763 16.3153 6.90598 16.0188 6.95018L17.5239 9.02347V9.26973H16.3901L14.5359 6.7387L14.5291 5.99358H15.5633C15.7671 5.99358 15.9292 5.9582 16.0557 5.8948C16.1817 5.83166 16.2749 5.74209 16.3393 5.62439C16.3422 5.61909 16.345 5.61372 16.3478 5.60829H14.4258L14.6755 4.6174H16.3086C16.2853 4.57956 16.2591 4.54461 16.2302 4.51239C16.1032 4.37101 15.8924 4.28525 15.5633 4.28525H14.4273L14.682 3.24121H18.2482L17.9953 4.23845L17.3478 4.23428C17.4017 4.35533 17.443 4.48312 17.472 4.6174H18.2454ZM17.3183 4.6174C17.3297 4.66632 17.3394 4.71632 17.3473 4.7674H18.0529L17.8788 5.45829H17.3511C17.3438 5.50923 17.3348 5.55923 17.324 5.60829C17.2974 5.72902 17.2601 5.84405 17.2123 5.95338C17.1045 6.19953 16.9329 6.39999 16.6974 6.55476C16.4933 6.68895 16.2337 6.77479 15.9187 6.81226C15.8703 6.818 15.8207 6.82261 15.7698 6.82608L15.7474 6.83168L17.3739 9.07218V9.11973H16.4661L14.6854 6.68902L14.6804 6.14358H15.5633C15.7839 6.14358 15.9704 6.10536 16.1229 6.0289C16.2755 5.95245 16.3915 5.84149 16.4711 5.69604C16.4863 5.66795 16.5 5.63869 16.5123 5.60829C16.5314 5.56107 16.547 5.51107 16.5591 5.45829H14.6183L14.7924 4.7674H16.5378C16.522 4.71462 16.5026 4.66462 16.4796 4.6174C16.4428 4.54189 16.3969 4.47348 16.3418 4.41216C16.271 4.3333 16.183 4.27128 16.078 4.2261C15.9372 4.16553 15.7656 4.13525 15.5633 4.13525H14.6183L14.7998 3.39121H18.0554L17.8788 4.0877L17.0958 4.08265C17.1254 4.12798 17.1527 4.17535 17.1774 4.22475C17.1789 4.22757 17.1803 4.23039 17.1817 4.23321C17.2409 4.3529 17.2864 4.48096 17.3183 4.6174Z"
                    fill="black"
                />
            </g>
            <defs>
                <clipPath id="clip0_518_12664">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

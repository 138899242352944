import { Grid } from "@mui/material";
import { ColumnGrid } from "../../../../components/ColumnGrid";
import { PaymentTimer } from "../PaymentTimer";
import { PaymentBreakDown } from "../PaymentBreakDown";
import { PaymentButton } from "../PaymentButton";
import { GO_TO_CONTENT, PAY } from "../../../../constants/AppString";
import { useState } from "react";
import { useScreenDimension } from "../../../../hooks/useScreenDimension";
import { usePaymentRD } from "../../hooks/usePaymentRD";
import { MobilePaymentBreakDownSectionContainer } from "../MobilePaymentBreakDownSectionContainer";
import { AppButton } from "../../../../components/AppButton";
import { AppText } from "../../../../components/AppText";

type PaymentBreakDownSectionType = {
    goldPrice: string;
    getNewGoldPrice: () => void;
    amount: string;
    supportUserPaymentFinalizer: () => void;
    disable: boolean;
    gstRate: string;
    goldWeightPrice: string;
    loading?: boolean;
    mobile?: boolean;
    goldWeight?: string;
    gstPrice?: string;
    minimumAmount?: string | number;
    freeContentPage?: any;
    isPayment?: boolean;
    termsChecked?: boolean;
};

export const PaymentBreakDownSection = (props: PaymentBreakDownSectionType) => {
    /* Props */
    const {
        amount,
        disable,
        getNewGoldPrice,
        goldPrice,
        goldWeightPrice,
        gstRate,
        supportUserPaymentFinalizer,
        loading,
        mobile = false,
        goldWeight,
        gstPrice,
        minimumAmount,
        freeContentPage,
        isPayment,
        termsChecked,
    } = props;

    /* States */
    const [open, setOpen] = useState(false);

    /* Handler */
    const paymentBrakDownHandler = () => {
        setOpen((prev) => !prev);
    };

    /* Render */
    const SECTION = (
        <Grid container alignItems="flex-end" height="100%">
            <Grid item xs={12}>
                <ColumnGrid
                    rowGap={2}
                    paddingX={2}
                    paddingY={2}
                    borderRadius="16px 16px 0px 0px"
                    background="linear-gradient(#282829, #0E0E0F)"
                    justifyContent="flex-end"
                >
                    <Grid item xs={12}>
                        <PaymentTimer
                            open={open}
                            paymentBrakDownHandler={paymentBrakDownHandler}
                            price={goldPrice?.toString()}
                            getNewGoldPrice={getNewGoldPrice}
                        />
                    </Grid>
                    {open && (
                        <Grid item>
                            <PaymentBreakDown
                                amount={amount}
                                gstRate={gstRate}
                                goldWeightPrice={goldWeightPrice}
                                goldWeight={goldWeight}
                                gstPrice={gstPrice}
                            />
                        </Grid>
                    )}

                    <Grid item>
                        <Grid container columnGap={2}>
                            {!minimumAmount && !isPayment && (
                                <Grid
                                    item
                                    flex={1}
                                    sx={{
                                        cursor: "pointer",
                                        opacity: termsChecked ? 1 : 0.2,
                                    }}
                                >
                                    <AppButton
                                        outlineButton
                                        showShadow={false}
                                        background="#565555"
                                        onClick={
                                            termsChecked
                                                ? freeContentPage
                                                : null
                                        }
                                        children={
                                            <Grid
                                                container
                                                justifyContent="center"
                                            >
                                                <Grid item>
                                                    <AppText
                                                        children={GO_TO_CONTENT}
                                                        fontWeight="600"
                                                        fontSize={16}
                                                    />
                                                </Grid>
                                            </Grid>
                                        }
                                    />
                                </Grid>
                            )}
                            <Grid item flex={1}>
                                <PaymentButton
                                    loading={loading}
                                    disable={disable}
                                    title={`${amount ? amount : 0}`}
                                    supportUserPaymentFinalizer={
                                        supportUserPaymentFinalizer
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </ColumnGrid>
            </Grid>
        </Grid>
    );

    if (mobile) {
        return (
            <MobilePaymentBreakDownSectionContainer open={open}>
                {SECTION}
            </MobilePaymentBreakDownSectionContainer>
        );
    }
    return SECTION;
};

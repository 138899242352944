export const BgImage = require("../assets/testing/BG.png");
export const QR = require("../assets/testing/QRcode.png");
export const NoVideo =
    require("../assets/images/VideoPlaceholder.svg")?.default;
export const NoUserImage = require("../assets/images/UserEmoji.svg")?.default;
export const LOCK_IMAGE = require("../assets/images/LockImage.png");
export const MOBILE_LOCK_IMAGE = require("../assets/images/MobileLockImage.png");
export const SMALL_USER = require("../assets/testing/user.png");
export const CreatorPayHeart =
    require("../assets/images/CreatorPayThanksHeart.svg")?.default;
export const PayCardBackground =
    require("../assets/images/PayCardBackground.svg")?.default;
export const LockThumbnailImage =
    require("../assets/images/LockThumbnail.svg")?.default;
export const SuccessPaymentIcon =
    require("../assets/icons/SuccessPaymentIcon.svg")?.default;
export const PaymentLinkSuccessIcon =
    require("../assets/icons/PaymentLinkSuccessIcon.svg")?.default;
export const BgProfileNav =
    require("../assets/images/BgProfileNav.svg")?.default;
export const TransactionCountUser1 =
    require("../assets/images/TransactionCountUser1.svg")?.default;
export const TransactionCountUser2 =
    require("../assets/images/TransactionCountUser2.svg")?.default;
export const TransactionCountUser3 =
    require("../assets/images/TransactionCountUser3.svg")?.default;
export const Error404 = require("../assets/images/Error404.svg")?.default;
export const ErrorServer = require("../assets/images/ErrorServer.svg")?.default;
export const SupportCreatorBackground =
    require("../assets/images/SupportCreatorBackground.svg")?.default;
export const ExpiredImage =
    require("../assets/images/ExpiredImage.svg")?.default;
export const LinkMoibleBgImage =
    require("../assets/images/LinkMoibleBgImage.svg")?.default;
export const SnapChatIcon =
    require("../assets/icons/SocialIcons/SnapChatIcon.svg")?.default;
export const MojIcon =
    require("../assets/icons/SocialIcons/MojIcon.svg")?.default;
export const TakaTakIcon =
    require("../assets/icons/SocialIcons/TakaTakIcon.svg")?.default;
export const ShareChatIcon =
    require("../assets/icons/SocialIcons/ShareChatIcon.svg")?.default;
export const SpotifyIcon =
    require("../assets/icons/SocialIcons/SpotifyIcon.svg")?.default;
export const LokalIcon =
    require("../assets/icons/SocialIcons/LokalIcon.svg")?.default;
export const RooterIcon =
    require("../assets/icons/SocialIcons/RooterIcon.svg")?.default;
export const UpIcon = require("../assets/icons/UpIcon.svg")?.default;
export const DownIcon = require("../assets/icons/Downlcon.svg")?.default;
export const CloseIcon = require("../assets/icons/CloseIcon.svg")?.default;
export const RedCloseIcon =
    require("../assets/icons/RedCloseIcon.svg")?.default;
export const GoldBrickIcon = require("../assets/icons/GoldBrick.svg")?.default;
export const PayAsYouWantIcon =
    require("../assets/icons/PayAsYouWantIcon.svg")?.default;
export const EyeIcon = require("../assets/icons/EyeIcon.svg")?.default;
export const WhatsAppIcon =
    require("../assets/icons/WhatsAppIcon.svg")?.default;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest } from "../../../../network/Request";
import { GET_PROFILE } from "../../../../network/Url";
import { SOMETHING_WENT_WRONG } from "../../../../constants/StringConstant";
import {
    ERROR_404,
    SOMETHING_WENT_WRONG_TRY_AGAIN,
} from "../../../../constants/AppString";
// import { postRequest } from "../../../../../../service/Network";
// import { VALIDATE_OTP } from "../../../../../../constants/UrlConstant";
// import { SOMETHING_WENT_WRONG } from "../../../../../../constants/AppString";

const THUNK_NAME = `profileSlice/getProfileData`;

export const getProfileAction = createAsyncThunk(
    THUNK_NAME,
    async ({ username }: { username: string }) => {
        return getRequest({ url: GET_PROFILE + "/" + username })
            .then((response) => {
                if (response.data) {
                    return response.data;
                }
                throw ERROR_404;
            })
            .catch((e: any) => {
                throw SOMETHING_WENT_WRONG_TRY_AGAIN;
            });
    }
);

import { Grid } from "@mui/material";
import React from "react";
import { AppText } from "../AppText";

type TextWithLink = {
    title: string;
    subTitle: string;
    titleColor?: string;
    subTitleHandler?: any;
};

export const TextWithLink = (props: TextWithLink) => {
    /* Props */
    const { subTitle, title, titleColor = "#FFFFFF", subTitleHandler } = props;

    /* Render */
    return (
        <Grid container>
            <AppText
                children={title}
                color={titleColor}
                fontWeight={500}
                fontSize={12}
            />
            &nbsp;
            <AppText
                onClick={subTitleHandler}
                children={subTitle}
                color="#FFFFFF"
                fontWeight={500}
                fontSize={12}
                sx={{
                    textDecoration: "underline",
                    cursor: "pointer",
                }}
            />
        </Grid>
    );
};

import { ResponsiveLayout } from "../../../../components/ResponsiveLayout";
import { LinkMobilePaymentBackground } from "../../components/LinkMobilePaymentBackground";
import { UnlockContentDetail } from "../../components/UnlockContentDetail";
import { WebPayBackground } from "../../components/WebPayBackground";
import { PayAmount } from "../../components/PayAmount";
import { usePaymentInitializer } from "../../hooks/usePyamentInitializer";
import { useOtherLink } from "../../../../hooks/useOtherLink";
import { LockedContentWeb } from "../../components/LockedContentWeb";
import { SearchEngineType } from "../../../../components/SearchEngine";
import { usePaymentRD } from "../../hooks/usePaymentRD";

export const UnlockContentPage = () => {
    /* Custom Hooks */
    const {
        input,
        setinput,
        amount,
        setAmount,
        goldWeightPrice,
        setGoldWeightPrice,
        termCondition,
        setTermCondition,
        profileData,
        supportUserPaymentDetailHandler,
        resetPayment,
        getGoldPrice,
        goldLoading,
        goldPrice,
        goldPriceTime,
        gstRate,
        weight,
        transactionCount,
        amountHandler,
        getNewGoldPrice,
        inputAmountHandler,
        payAsYouWantPaymentFinalizer,
        termConditionHandler,
        valueThroughNumberHandler,
        goToContentHandler,
        minWeight,
        gstPrice,
        amountType,
        unlockContentUmountTypeHandler,
    } = usePaymentInitializer();
    const { termOfUseHandler } = useOtherLink();
    const { title, description, thumbnail } = usePaymentRD();

    /* Render */
    const DISABLE_BUTTON =
        !termCondition ||
        !Number(amount) ||
        Number(amount) < 10 ||
        (!!minWeight && Number(minWeight) > Number(input));

    return (
        <>
            <SearchEngineType
                title={title}
                description={description}
                image={thumbnail}
            />
            <ResponsiveLayout
                mobile={
                    <LinkMobilePaymentBackground
                        contentPaddingBottom={10}
                        amount={amount}
                        getNewGoldPrice={getNewGoldPrice}
                        goldPrice={goldPrice}
                        supportUserPaymentFinalizer={
                            payAsYouWantPaymentFinalizer
                        }
                        disable={DISABLE_BUTTON}
                        gstRate={gstRate}
                        goldWeightPrice={goldWeightPrice}
                        minimumAmount={minWeight}
                        goldWeight={input}
                        gstPrice={gstPrice}
                        resetPayment={resetPayment}
                        termCondition={termCondition}
                        bottomComponent={
                            <UnlockContentDetail
                                amountType={amountType}
                                amountTypeHandler={
                                    unlockContentUmountTypeHandler
                                }
                                termOfUseHandler={termOfUseHandler}
                                minimumAmount={minWeight}
                                amount={amount}
                                amountHandler={amountHandler}
                                input={input}
                                inputAmountHandler={inputAmountHandler}
                                setinput={setinput}
                                getNewGoldPrice={getNewGoldPrice}
                                goldPrice={goldPrice}
                                goldPriceTime={goldPriceTime}
                                termCondition={termCondition}
                                termConditionHandler={termConditionHandler}
                                goldWeightPrice={goldWeightPrice}
                            />
                        }
                    />
                }
                web={
                    <WebPayBackground backHandler={resetPayment}>
                        <LockedContentWeb />
                        <PayAmount
                            termOfUseHandler={termOfUseHandler}
                            disable={DISABLE_BUTTON}
                            minimumAmount={minWeight}
                            amount={amount}
                            amountHandler={amountHandler}
                            input={input}
                            inputAmountHandler={inputAmountHandler}
                            setinput={setinput}
                            getNewGoldPrice={getNewGoldPrice}
                            goldPrice={goldPrice}
                            goldPriceTime={goldPriceTime}
                            supportUserPaymentFinalizer={
                                payAsYouWantPaymentFinalizer
                            }
                            gstRate={gstRate}
                            goldWeightPrice={goldWeightPrice}
                            termCondition={termCondition}
                            termConditionHandler={termConditionHandler}
                            goldWeight={input}
                            gstPrice={gstPrice}
                            amountType={amountType}
                            amountTypeHandler={unlockContentUmountTypeHandler}
                        />
                    </WebPayBackground>
                }
            />
        </>
    );
};

import { SvgPropType } from "../../types/PropsType";

export const TrendIcon = (props: SvgPropType) => {
    /* Props */
    const { fill = "none" } = props;

    return (
        <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.928889 11.7188C0.779475 11.7188 0.63592 11.6836 0.504084 11.6162C0.149592 11.4434 -0.0525565 11.0684 0.0118966 10.7051C0.0558419 10.4619 0.16424 10.2334 0.301936 10.0898C1.76385 8.60449 3.23162 7.14258 4.3449 6.03809C4.54412 5.8418 4.78143 5.73633 5.02752 5.73633C5.28533 5.73633 5.53436 5.84766 5.74822 6.06152L8.26776 8.58105L11.6955 5.15039L11.3498 5.14746C10.9103 5.14453 10.3068 5.1416 9.96112 5.13867C9.41619 5.13281 9.03533 4.75488 9.0324 4.21582C9.0324 3.95215 9.12323 3.71484 9.29315 3.54492C9.46307 3.375 9.69451 3.28418 9.95233 3.28418C10.6496 3.28125 11.3439 3.28125 12.0148 3.28125C12.715 3.28125 13.4123 3.28418 14.0861 3.28418C14.6076 3.28711 14.9885 3.65918 14.9914 4.17187C15.0002 5.60156 15.0002 7.01074 14.9914 8.36133C14.9885 8.87402 14.5988 9.24609 14.0656 9.24609H14.0539C13.5236 9.24023 13.1486 8.86816 13.1398 8.33789C13.134 7.92188 13.134 7.5 13.1369 7.09277C13.1369 6.91406 13.1369 6.73535 13.1369 6.55664V6.45117C13.1222 6.46289 13.1105 6.47461 13.0988 6.48633C11.9387 7.64941 10.7814 8.81543 9.62127 9.98145L9.13787 10.4648C8.85076 10.7578 8.5783 10.8984 8.3117 10.8984C8.0451 10.8984 7.78143 10.7607 7.50018 10.4766L5.02752 7.99805L4.81951 8.20312C4.60272 8.41699 4.40057 8.61914 4.20135 8.81836L3.26971 9.75586C2.73358 10.2949 2.17987 10.8545 1.63201 11.4023C1.42987 11.6104 1.1867 11.7188 0.928889 11.7188Z"
                fill={fill}
            />
        </svg>
    );
};

import React from "react";

export const WhatsAppIcon = () => {
    return (
        <svg
            width={56}
            height={56}
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 28c0 15.464 12.536 28 28 28s28 -12.536 28 -28S43.464 0 28 0 0 12.536 0 28Z"
                fill="#25D366"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M37.8 18.026A13.765 13.765 0 0 0 28 14c-7.7 0 -14 6.3 -14 14 0 2.45 0.7 4.9 1.926 7L14 42l7.35 -1.926c2.1 1.053 4.376 1.753 6.65 1.753 7.7 0 14 -6.3 14 -14 0 -3.676 -1.576 -7.176 -4.2 -9.8ZM28 39.55c-2.1 0 -4.2 -0.526 -5.95 -1.576L21.7 37.8l-4.376 1.226 1.226 -4.2 -0.35 -0.526a11.259 11.259 0 0 1 -1.75 -6.126c0 -6.3 5.25 -11.547 11.55 -11.547 3.15 0 5.95 1.224 8.226 3.324 2.274 2.276 3.324 5.076 3.324 8.226 0 6.124 -5.076 11.374 -11.55 11.374ZM34.3 30.8c-0.35 -0.176 -2.1 -1.05 -2.45 -1.05 -0.35 -0.176 -0.526 -0.176 -0.7 0.176 -0.176 0.347 -0.876 1.047 -1.05 1.4 -0.176 0.174 -0.35 0.174 -0.7 0.174 -0.35 -0.176 -1.4 -0.526 -2.8 -1.75a8.506 8.506 0 0 1 -1.926 -2.45c-0.174 -0.35 0 -0.526 0.176 -0.7l0.526 -0.526c0.174 -0.174 0.174 -0.347 0.35 -0.524 0.174 -0.176 0 -0.35 0 -0.526 0 -0.174 -0.7 -1.924 -1.053 -2.624 -0.174 -0.526 -0.524 -0.526 -0.7 -0.526h-0.7c-0.174 0 -0.524 0 -0.874 0.35S21.174 23.453 21.174 25.2s1.226 3.326 1.4 3.676c0.176 0.174 2.45 3.847 5.953 5.247 2.974 1.226 3.5 0.876 4.2 0.876s2.1 -0.876 2.274 -1.576c0.35 -0.874 0.35 -1.574 0.176 -1.574 -0.176 -0.876 -0.526 -0.876 -0.876 -1.05Z"
                fill="white"
            />
        </svg>
    );
};

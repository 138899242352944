import { Grid, Typography } from "@mui/material";
import { useGetProfileRD } from "../../hooks/useGetProfileRD";
import { NoUserImage } from "../../../../utils/Image";
import { UserProfileImage } from "../../../../components/UserProfileImage";
import { AppText } from "../../../../components/AppText";

export const UserBio = () => {
    /* Custom hooks */
    const { profileData } = useGetProfileRD();

    /* Render */

    const userBio = profileData?.creatorInfo?.bio
        ? profileData?.creatorInfo?.bio
        : "";
    return (
        <>
            <Grid item alignSelf={"center"} marginTop={6}>
                <UserProfileImage />
            </Grid>
            <Grid item paddingTop={1}>
                <Grid container justifyContent="center">
                    <Grid
                        item
                        // paddingX={2}
                        // paddingY={1}
                        // borderRadius={40}
                        // bgcolor="#000000"
                    >
                        <AppText
                            variant="h1"
                            fontWeight="700"
                            fontSize={22}
                            textAlign={"center"}
                            color={"#FFFFFF"}
                            fontFamily="Marcellus"
                        >
                            {`${profileData?.creatorInfo?.firstName} ${profileData?.creatorInfo?.lastName}`}
                        </AppText>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item paddingX={3} paddingTop={2}>
                <AppText
                    fontWeight="600"
                    fontSize={14}
                    textAlign={"justify"}
                    color={"#FFFFFF"}
                    sx={{
                        textAlign: {
                            xs:
                                userBio?.split(" ")?.length < 8
                                    ? "center"
                                    : "justify",
                            sm:
                                userBio?.split(" ")?.length < 12
                                    ? "center"
                                    : "justify",
                            md:
                                userBio?.split(" ")?.length < 18
                                    ? "center"
                                    : "justify",
                            lg:
                                userBio?.split(" ")?.length < 22
                                    ? "center"
                                    : "justify",
                            xl:
                                userBio?.split(" ")?.length < 30
                                    ? "center"
                                    : "justify",
                        },
                    }}
                >
                    {profileData?.creatorInfo?.bio}
                </AppText>
            </Grid>
        </>
    );
};

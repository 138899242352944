import { Grid } from "@mui/material";
import { ColumnGrid } from "../../../../components/ColumnGrid";
import { GoldBricks } from "../../../../assets/icons/GoldBricks";
import { AppText } from "../../../../components/AppText";
import { GOLD_VALUE, GST, RUPEE } from "../../../../constants/AppString";
import { HorizontalLine } from "../../../../components/HorizontalLine";
import { useState } from "react";

type PaymentBreakDown = {
    amount?: string;
    gstRate?: string;
    goldWeightPrice?: string;
    goldWeight?: string;
    gstPrice?: string;
};

export const PaymentBreakDown = (props: PaymentBreakDown) => {
    /* Props */
    const {
        amount = "0",
        gstRate = "0",
        goldWeightPrice = "0",
        goldWeight,
        gstPrice,
    } = props;

    /* Render */
    return (
        <Grid container>
            <Grid item xs={12} bgcolor="#FFFFFF10" borderRadius={4} padding={2}>
                <ColumnGrid>
                    <Grid item>
                        <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Grid item>
                                <Grid container alignItems="center">
                                    <Grid item>
                                        <GoldBricks />
                                    </Grid>
                                    <Grid item>
                                        <AppText
                                            children={`${goldWeight} gm`}
                                            fontSize={16}
                                            fontWeight="900"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <AppText
                                    children={`${RUPEE} ${Number(
                                        amount
                                    )?.toFixed(2)}`}
                                    fontSize={16}
                                    fontWeight="700"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item paddingTop={0.5}>
                        <HorizontalLine color="#FFFFFF20" height={1.5} />
                    </Grid>
                    <Grid item paddingTop={2}>
                        <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Grid item>
                                <AppText
                                    children={`${GST} (${gstRate}%)`}
                                    fontSize={14}
                                    fontWeight="500"
                                    color="#FFFFFF40"
                                />
                            </Grid>
                            <Grid item>
                                <AppText
                                    children={`${RUPEE}${gstPrice}`}
                                    fontSize={14}
                                    fontWeight="500"
                                    color="#FFFFFF40"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item paddingTop={2}>
                        <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Grid item>
                                <AppText
                                    children={`${GOLD_VALUE}`}
                                    fontSize={14}
                                    fontWeight="500"
                                    color="#FFFFFF40"
                                />
                            </Grid>
                            <Grid item>
                                <AppText
                                    children={`${RUPEE}${goldWeightPrice}`}
                                    fontSize={14}
                                    fontWeight="500"
                                    color="#FFFFFF40"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </ColumnGrid>
            </Grid>
        </Grid>
    );
};

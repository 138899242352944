import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAuthHeader, getRequest } from "../../../../network/Request";
import { GET_CONSTANTS } from "../../../../network/Url";
import { SOMETHING_WENT_WRONG } from "../../../../constants/StringConstant";
// import { postRequest } from "../../../../../../service/Network";
// import { VALIDATE_OTP } from "../../../../../../constants/UrlConstant";
// import { SOMETHING_WENT_WRONG } from "../../../../../../constants/AppString";

const THUNK_NAME = `contentConstantSlice/getContentConstantData`;

export const getContentConstantAction = createAsyncThunk(
    THUNK_NAME,
    async () => {
        return getRequest({
            url: GET_CONSTANTS,
            headers: {
                ...getAuthHeader(),
            },
        })
            .then((response) => {
                if (response.data) {
                    return response.data;
                }
                throw SOMETHING_WENT_WRONG;
            })
            .catch((e: any) => {
                throw SOMETHING_WENT_WRONG;
            });
    }
);

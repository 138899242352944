import { Grid } from "@mui/material";
import React from "react";
import { useScreenDimension } from "../../../../hooks/useScreenDimension";
import { ChildrenType } from "../../../../types/ObjectType";

type MobilePaymentBreakDownSectionContainer = {
    open: boolean;
    children: ChildrenType;
};

export const MobilePaymentBreakDownSectionContainer = (
    props: MobilePaymentBreakDownSectionContainer
) => {
    /* Props */
    const { open, children } = props;

    /* Custom Hooks */
    const { browserHeight } = useScreenDimension();

    /* Render */
    return (
        <Grid
            item
            position="fixed"
            left={0}
            right={0}
            bottom={0}
            zIndex={40}
            height={open ? browserHeight : undefined}
            sx={{
                backdropFilter: "blur(2px)",
                "-webkit-backdrop-filter": "blur(2px)",
            }}
        >
            {children}
        </Grid>
    );
};

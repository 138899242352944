import { Grid } from "@mui/material";
import { ResponsiveLayout } from "../../../../components/ResponsiveLayout";
import { AppText } from "../../../../components/AppText";
import { LinkMobilePaymentBackground } from "../../components/LinkMobilePaymentBackground";
import { LockedContentDetail } from "../../components/LockedContentDetail";
import { LockedContentWeb } from "../../components/LockedContentWeb";
import { Terms } from "../../components/Terms";
import { WebPayBackground } from "../../components/WebPayBackground";
import {
    ACCEPT_ALL,
    PAY,
    TERM_AND_CONDITIION,
} from "../../../../constants/AppString";
import { LockedContentCard } from "../../components/LockedContentCard";
import { usePaymentInitializer } from "../../hooks/usePyamentInitializer";
import { PaymentBreakDownSection } from "../../components/PaymentBreakDownSection";
import { ColumnGrid } from "../../../../components/ColumnGrid";
import { useOtherLink } from "../../../../hooks/useOtherLink";
import { SearchEngineType } from "../../../../components/SearchEngine";
import { usePaymentRD } from "../../hooks/usePaymentRD";
import { useLockedContentPaymentInitializer } from "../../hooks/useLockedContentPaymentInitializer";

export const LockedContentPage = () => {
    /* Custom Hooks */
    const {
        input,
        setinput,
        amount,
        setAmount,
        goldWeightPrice,
        setGoldWeightPrice,
        termCondition,
        setTermCondition,
        profileData,
        supportUserPaymentDetailHandler,
        resetPayment,
        getGoldPrice,
        goldLoading,
        goldPrice,
        goldPriceTime,
        gstRate,
        weight,
        transactionCount,
        getNewGoldPrice,
        inputAmountHandler,
        lockedContentPaymentFinalizer,
        termConditionHandler,
        gstPrice,
    } = useLockedContentPaymentInitializer();
    const { termOfUseHandler } = useOtherLink();
    const { title, description, thumbnail } = usePaymentRD();

    /* Render */

    const DISABLE_BUTTON =
        !termCondition || !Number(amount) || Number(amount) < 10;

    return (
        <>
            <SearchEngineType
                title={title}
                description={description}
                image={thumbnail}
            />
            <ResponsiveLayout
                mobile={
                    <LinkMobilePaymentBackground
                        showIcon
                        amount={amount}
                        getNewGoldPrice={getNewGoldPrice}
                        goldPrice={goldPrice}
                        supportUserPaymentFinalizer={
                            lockedContentPaymentFinalizer
                        }
                        disable={DISABLE_BUTTON}
                        goldWeightPrice={goldWeightPrice}
                        gstRate={gstRate}
                        goldWeight={input}
                        gstPrice={gstPrice}
                        resetPayment={resetPayment}
                        isPayment
                        bottomComponent={
                            <LockedContentDetail
                                amount={amount}
                                termOfUseHandler={termOfUseHandler}
                                getNewGoldPrice={getNewGoldPrice}
                                goldPrice={goldPrice}
                                goldPriceTime={goldPriceTime}
                                input={input}
                                termCondition={termCondition}
                                termConditionHandler={termConditionHandler}
                            />
                        }
                    />
                }
                web={
                    <WebPayBackground backHandler={resetPayment}>
                        <LockedContentWeb />
                        <Grid md={6} item paddingX={10.5} paddingY={8}>
                            <Grid
                                container
                                height="100%"
                                alignContent="space-between"
                            >
                                <Grid item md={12}>
                                    <Grid container>
                                        <Grid item md={12}>
                                            <AppText
                                                children="Pay to unlock content"
                                                fontWeight="700"
                                                fontSize={20}
                                                color="#FFFFFF"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item md={12} paddingTop={1}>
                                        <LockedContentCard
                                            weight={input}
                                            amount={amount}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item paddingTop={10} xs={12}>
                                    <ColumnGrid>
                                        <Grid item>
                                            <Terms
                                                checked={termCondition}
                                                title={ACCEPT_ALL}
                                                subTitle={TERM_AND_CONDITIION}
                                                checkHandler={
                                                    termConditionHandler
                                                }
                                                linkHandler={termOfUseHandler}
                                            />
                                        </Grid>
                                        <Grid item paddingTop={2}>
                                            <PaymentBreakDownSection
                                                isPayment
                                                goldWeight={input}
                                                gstPrice={gstPrice}
                                                goldPrice={
                                                    goldPrice
                                                        ? goldPrice?.toString()
                                                        : "0"
                                                }
                                                getNewGoldPrice={
                                                    getNewGoldPrice
                                                }
                                                amount={amount}
                                                supportUserPaymentFinalizer={
                                                    lockedContentPaymentFinalizer
                                                }
                                                disable={DISABLE_BUTTON}
                                                gstRate={gstRate}
                                                goldWeightPrice={
                                                    goldWeightPrice
                                                }
                                            />
                                        </Grid>
                                    </ColumnGrid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </WebPayBackground>
                }
            />
        </>
    );
};

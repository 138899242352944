import { Box, CircularProgress, Grid, IconButton } from "@mui/material";
import { LabelInput } from "../../../../components/LabelInput";
import {
    ENTER_FULL_NAME,
    ENTER_PAN,
    ENTER_PHONE_NUMBER,
    FULL_NAME,
    NAME_INFO,
    PAN_ERROR,
    PAN_NUMBER,
    PHONE_NUMBER,
    VERIFIED,
    VERIFY,
    WHATSAPP_MESSAGE,
} from "../../../../constants/AppString";
import { AppText } from "../../../../components/AppText";
import { Terms } from "../Terms";
import { WhatsAppNotification } from "../WhatsAppNotification";

type PaymentInput = {
    panCondition: boolean;
    pan: string;
    mobileNumber: string;
    name: string;
    panHandler: (e: any) => void;
    nameHandler: (e: any) => void;
    moblieHandler: (e: any) => void;
    panError: string;
    mobileLoading?: boolean;
    mobileNumberVerified?: any;
    sendOtpHandler?: any;
    whatsAppHandler: any;
    whatsAppFlag: any;
};

export const PaymentInput = (props: PaymentInput) => {
    /* Props */
    const {
        pan,
        panCondition,
        panHandler,
        moblieHandler,
        nameHandler,
        name,
        mobileNumber,
        panError,
        mobileLoading,
        sendOtpHandler,
        mobileNumberVerified,
        whatsAppHandler,
        whatsAppFlag,
    } = props;

    /* Render */
    return (
        <>
            {panCondition && (
                <Grid item paddingTop={2}>
                    <LabelInput
                        labelText={PAN_NUMBER}
                        msg={{
                            value: panError ? panError : PAN_ERROR,
                            color: panError ? "#fF0000" : "#fff",
                            error: !!panError,
                        }}
                        value={pan}
                        onChange={panHandler}
                        placeholder={ENTER_PAN}
                    />
                </Grid>
            )}
            <Grid item>
                <LabelInput
                    labelText={FULL_NAME}
                    value={name}
                    onChange={(e: any) => nameHandler(e?.target?.value)}
                    placeholder={ENTER_FULL_NAME}
                    msg={
                        panCondition
                            ? {
                                  value: NAME_INFO,
                                  color: "#fff",
                                  error: false,
                              }
                            : undefined
                    }
                />
            </Grid>
            <Grid item>
                <LabelInput
                    type="tel"
                    InputProps={{
                        startAdornment: (
                            <Box color="#fff" paddingLeft={1}>
                                +91
                            </Box>
                        ),
                        endAdornment: (
                            <IconButton
                                onClick={
                                    mobileNumber?.length === 10 &&
                                    !mobileNumberVerified
                                        ? sendOtpHandler
                                        : null
                                }
                            >
                                <Grid container>
                                    <Grid item paddingRight={1}>
                                        {mobileLoading ? (
                                            <CircularProgress size={20} />
                                        ) : (
                                            <AppText
                                                children={
                                                    mobileNumberVerified
                                                        ? VERIFIED?.toUpperCase()
                                                        : VERIFY?.toUpperCase()
                                                }
                                                fontWeight="bold"
                                                sx={{
                                                    opacity:
                                                        mobileNumber?.length ===
                                                            10 &&
                                                        !mobileNumberVerified
                                                            ? 1
                                                            : 0.3,
                                                }}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </IconButton>
                        ),
                    }}
                    value={mobileNumber}
                    onChange={(e: any) => moblieHandler(e?.target?.value)}
                    labelText={PHONE_NUMBER}
                    placeholder={ENTER_PHONE_NUMBER}
                />
            </Grid>
            <Grid item>
                <WhatsAppNotification
                    checked={whatsAppFlag}
                    checkHandler={whatsAppHandler}
                    title={WHATSAPP_MESSAGE}
                />
            </Grid>
        </>
    );
};

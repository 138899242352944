import { Box, Grid, Typography } from "@mui/material";
import { TwitterIcon } from "../../../../assets/icons/TwitterIcon";
import { SB, SOCIAL_MEDIA_CONSTANTS } from "../../../../constants/AppConstant";
import { useGetProfileRD } from "../../hooks/useGetProfileRD";
import { YoutubeIcon } from "../../../../assets/icons/YoutubeIcon";
import { FacebookIcon } from "../../../../assets/icons/SocialIcons/FacebookIcon";
import { useScreenDimension } from "../../../../hooks/useScreenDimension";
import { useScreenType } from "../../../../hooks/useScreenType";
import { InstagramIcon } from "../../../../assets/icons/SocialIcons/InstagramIcon";
import { WhatsAppIcon } from "../../../../assets/icons/SocialIcons/WhatsAppIcon";
import { LinkedIn } from "../../../../assets/icons/SocialIcons/LinkedIn";
import { TelegramIcon } from "../../../../assets/icons/SocialIcons/TelegramIcon";
import { BeIcon } from "../../../../assets/icons/SocialIcons/BeIcon";
import { DiscordIcon } from "../../../../assets/icons/SocialIcons/DiscordIcon";
import { DribbleIcon } from "../../../../assets/icons/SocialIcons/DribbleIcon";
import { PInterest } from "../../../../assets/icons/SocialIcons/PInterest";
import { ReditIcon } from "../../../../assets/icons/SocialIcons/ReditIcon";
import { ShareYoutubeIcon } from "../../../../assets/icons/SocialIcons/ShareYoutubeIcon";
import { AppImage } from "../../../../components/AppImage";
import {
    LokalIcon,
    MojIcon,
    RooterIcon,
    ShareChatIcon,
    SnapChatIcon,
    SpotifyIcon,
    TakaTakIcon,
} from "../../../../utils/Image";
import { AppText } from "../../../../components/AppText";

export const GetInTouch = () => {
    /* Custom hooks */
    const { profileData } = useGetProfileRD();
    const { rs } = useScreenType();

    /* Render */

    return (
        <>
            <Grid
                item
                sx={{
                    marginTop: { xs: 5, [SB]: 2.5 },
                    marginX: { xs: 2, [SB]: 3 },
                }}
            >
                <AppText
                    fontWeight="400"
                    fontSize={14}
                    color={"#FFFFFF60"}
                    fontFamily="Marcellus"
                    sx={{
                        textAlign: {
                            sx: "left",
                            [SB]: "center",
                        },
                    }}
                >
                    GET IN TOUCH
                </AppText>
            </Grid>
            <Grid
                item
                position={"relative"}
                sx={{
                    marginTop: { xs: 2.5, [SB]: 2 },
                    width: {
                        xs: "100vw",
                        [SB]: "100%",
                        lg: "100%",
                    },
                }}
            >
                <Grid
                    container
                    columnSpacing={1}
                    flexWrap={["xs", "sm"]?.includes(rs) ? "nowrap" : "wrap"}
                    sx={{
                        overflowX: "scroll",
                        justifyContent: {
                            sx: "left",
                            [SB]: "center",
                        },
                        paddingX: { xs: 2, [SB]: 3 },
                    }}
                >
                    {!!profileData?.creatorInfo?.socialLinks?.length &&
                        profileData?.creatorInfo?.socialLinks
                            // [
                            //     { title: "Facebook" },
                            //     { title: "Whatsapp" },
                            //     { title: "Instagram" },
                            //     { title: "LinkedIn" },
                            //     { title: "Telegram" },
                            //     { title: "Behance" },
                            //     { title: "Discord" },
                            //     { title: "Dribble" },
                            //     { title: "Lokal" },
                            //     { title: "Moj" },
                            //     { title: "Takatak" },
                            //     { title: "Pinterest" },
                            //     { title: "Reddit" },
                            //     { title: "Rooter" },
                            //     { title: "Sharechat" },
                            //     { title: "Snapchat" },
                            //     { title: "Spotify" },
                            //     { title: "Twitch" },
                            //     { title: "YouTube" },
                            //     { title: "Reddit" },
                            //     { title: "Rooter" },
                            //     { title: "Sharechat" },
                            //     { title: "Whatsapp" },
                            //     { title: "Instagram" },
                            //     { title: "LinkedIn" },
                            //     { title: "Telegram" },
                            //     { title: "Behance" },
                            //     { title: "Discord" },
                            //     { title: "Dribble" },
                            //     { title: "Lokal" },
                            //     { title: "Moj" },
                            //     { title: "Snapchat" },
                            //     { title: "Spotify" },
                            //     { title: "Twitch" },
                            //     { title: "YouTube" },
                            //     { title: "Rooter" },
                            //     { title: "Sharechat" },
                            //     { title: "Snapchat" },
                            //     { title: "Spotify" },
                            //     { title: "Twitch" },
                            // ]
                            ?.map((item, index) => {
                                return (
                                    <Grid
                                        key={index?.toString()}
                                        item
                                        // md={2.8}

                                        onClick={() => window?.open(item?.link)}
                                        sx={{
                                            paddingBottom: {
                                                xs: 0,
                                                [SB]: 2.8,
                                            },
                                        }}
                                    >
                                        <Grid
                                            container
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            {item?.title ===
                                                SOCIAL_MEDIA_CONSTANTS?.FACEBOOK && (
                                                <FacebookIcon />
                                            )}
                                            {item?.title ===
                                                SOCIAL_MEDIA_CONSTANTS?.WHATSAPP && (
                                                <WhatsAppIcon />
                                            )}
                                            {item?.title ===
                                                SOCIAL_MEDIA_CONSTANTS?.INSTAGRAM && (
                                                <InstagramIcon />
                                            )}
                                            {item?.title ===
                                                SOCIAL_MEDIA_CONSTANTS?.LINKEDIN && (
                                                <LinkedIn />
                                            )}
                                            {item?.title ===
                                                SOCIAL_MEDIA_CONSTANTS?.TELEGRAM && (
                                                <TelegramIcon />
                                            )}
                                            {item?.title ===
                                                SOCIAL_MEDIA_CONSTANTS?.BEHANCE && (
                                                <BeIcon />
                                            )}
                                            {item?.title ===
                                                SOCIAL_MEDIA_CONSTANTS?.DISCORD && (
                                                <DiscordIcon />
                                            )}
                                            {item?.title ===
                                                SOCIAL_MEDIA_CONSTANTS?.DRIBBLE && (
                                                <DribbleIcon />
                                            )}
                                            {item?.title ===
                                                SOCIAL_MEDIA_CONSTANTS?.LOKAL && (
                                                <Box height={56} width={56}>
                                                    <AppImage src={LokalIcon} />
                                                </Box>
                                            )}
                                            {item?.title ===
                                                SOCIAL_MEDIA_CONSTANTS?.MOJ && (
                                                <Box height={56} width={56}>
                                                    <AppImage src={MojIcon} />
                                                </Box>
                                            )}
                                            {item?.title ===
                                                SOCIAL_MEDIA_CONSTANTS?.TAKATAK && (
                                                <Box height={56} width={56}>
                                                    <AppImage
                                                        src={TakaTakIcon}
                                                    />
                                                </Box>
                                            )}
                                            {item?.title ===
                                                SOCIAL_MEDIA_CONSTANTS?.PINTEREST && (
                                                <PInterest />
                                            )}
                                            {item?.title ===
                                                SOCIAL_MEDIA_CONSTANTS?.REDDIT && (
                                                <ReditIcon />
                                            )}
                                            {item?.title ===
                                                SOCIAL_MEDIA_CONSTANTS?.ROOTER && (
                                                <Box height={56} width={56}>
                                                    <AppImage
                                                        src={RooterIcon}
                                                    />
                                                </Box>
                                            )}
                                            {item?.title ===
                                                SOCIAL_MEDIA_CONSTANTS?.SHARECHAT && (
                                                <Box height={56} width={56}>
                                                    <AppImage
                                                        src={SnapChatIcon}
                                                    />
                                                </Box>
                                            )}
                                            {item?.title ===
                                                SOCIAL_MEDIA_CONSTANTS?.SNAPCHAT && (
                                                <Box height={56} width={56}>
                                                    <AppImage
                                                        src={ShareChatIcon}
                                                    />
                                                </Box>
                                            )}
                                            {item?.title ===
                                                SOCIAL_MEDIA_CONSTANTS?.SPOTIFY && (
                                                <Box height={56} width={56}>
                                                    <AppImage
                                                        src={SpotifyIcon}
                                                    />
                                                </Box>
                                            )}
                                            {item?.title ===
                                                SOCIAL_MEDIA_CONSTANTS?.TWITCH && (
                                                <TwitterIcon />
                                            )}
                                            {item?.title ==
                                                SOCIAL_MEDIA_CONSTANTS?.YOUTUBE && (
                                                <ShareYoutubeIcon />
                                            )}
                                        </Grid>
                                    </Grid>
                                );
                            })}
                </Grid>
            </Grid>
        </>
    );
};

export const PAY_TO = "Pay to";
export const PAY = "Pay";
export const THANKS_FOR_CREATOR = "Thanks for your support";
export const PAYMENT_FAILED = "Your transaction has been failed!";
export const GO_BACK_PROFILE = "Go back to Profile";
export const DOWNLOAD_INVOICE = "Download Invoice";
export const RETRY = "RETRY";
export const CREATOR_MSG =
    "You’re one of the special followers of this creator!";
export const SUPPORT_CREATOR = " Support the creator";
export const PAY_TO_UNLOCK = "Pay to unlock";
export const MIN_AMOUNT = "Minimum Amount (in grams)";
export const ENTER_AMOUNT = "Enter amount (in grams)";
export const MIN_AMOUNT_GM = "Minimum Amount (in grams)";
export const MIN_AMOUNT_RUPEE = "Minimum Amount (in rupees)";
export const AMOUNT = "Amount (in grams)";
export const SUPPORT_SUBTITLE = "Enter details to get your invoice";
export const PAN_NUMBER = "PAN NUMBER";
export const PAN_ERROR = "*Because your paying value is more than ₹10,000";
export const LIVE_GOLD_PRICE = "Live Gold Price:";
export const VALID_FOR = "Valid for";
export const PAYMENT_SUCESS = "Payment Successfull";
export const LINK_PAYMENT_SUCCESS = "You have successfully unlocked";
export const LINK_PAYMENT_SUCCESS_WEB =
    "Your exclusive content has been sent to your registered mobile number via SMS and/or WhatsApp.";

export const USERLINK_COPIED = "Link copied";
export const GO_TO_CONTENT = "Go to Content";
export const ENTER_PAN = "Eg: AFJKK8968R";
export const ENTER_FULL_NAME = "Full name as per your PAN";
export const FULL_NAME = "Full Name";
export const NAME_INFO = "*In case of name mismatch your transaction may fail";
export const ENTER_PHONE_NUMBER = "Enter your number";
export const PHONE_NUMBER = "Phone Number";

export const ACCEPT_ALL = "Accept";
export const TERM_AND_CONDITIION = "Terms and Conditions";
export const VIEW_USAGE_GUIDELINE = "View Usage Guidelines";
export const BOUGHT_THIS = "more bought this";
export const SUPPORT_THEM = "Supports";
export const GO_BACK = "GO Back";
export const LINK_EXPIRED_MSG = "This Link has been expired";
export const LINK_EXPIRED = "Link Expired";
export const PAYMENT_TITLE = "Enter Details to unlock content";
export const VALID_PAN = "This is not a valid PAN";
export const VERIFY_MOBILE = "Please verify your mobile first";
export const SHOW_MORE = "Show more";
export const SHOW_LESS = "Show less";
export const FIRST_SUPPORT_USER = "Be the first one to support them!";
export const FIRST_LINK_USER = "Be the first one to buy it!";
export const VIEW_BREAKDOWN = "View Breakdown";
export const HIDE_BREAKDOWN = "Hide Breakdown";
export const GST = "GST";
export const GOLD_VALUE = "Gold Value";
export const RUPEE = "₹";
export const DONT_GET_CODE = "Didn’t receive OTP? ";
export const RESEND_OTP = "Resend";
export const CONFIRM_OTP = "Confirm your phone number to get the link";
export const VERIFY_OTP_BUTTON = "Verify otp";
export const VERIFY = "Verify";
export const VERIFIED = "Verified";
export const WHATSAPP_MESSAGE =
    "I authorize YourGold to send me WhatsApp message for my order";
export const WHATSAPP_TITLE = "WhatsApp Notification";
export const SUPPORT_ME_OG = "Support me with 24 Karat gold";
export const SOMETHING_WENT_WRONG_TRY_AGAIN = "Something went wrong";
export const ERROR_404 = "Error 404";
export const ERROR_SERVER = "Servers are down";
export const ERROR_404_SUB =
    "Sorry the page you’re trying to reach is unavailable";
export const ERROR_SERVER_SUB =
    "Sorry there were some techinical issues while processing your request";
export const GOLD_10_CHECK = "*Gold worth needs to be more than ₹10";
export const GOLD_WORTH = "*Gold worth needs to be more than ₹ ";
export const GOLD_WEIGHT_WORTH = "*Gold weight needs to be more than ";
export const IN_GRAM = `In Gram`;
export const IN_RUPEE = `In Rupees`;
export const IN_GRAM_TEXT = `Enter amount (in grams)`;
export const IN_RUPEE_TEXT = `Enter amount (in rupees)`;

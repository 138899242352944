export const FacebookIcon = () => {
    return (
        <svg
            width={56}
            height={56}
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 28c0 15.464 12.536 28 28 28s28 -12.536 28 -28S43.464 0 28 0 0 12.536 0 28Z"
                fill="#1877F2"
            />
            <path
                d="M42 28c0 -7.7 -6.3 -14 -14 -14s-14 6.3 -14 14c0 7 5.076 12.776 11.726 13.826v-9.8h-3.5V28h3.5V24.85c0 -3.5 2.1 -5.426 5.247 -5.426 1.576 0 3.153 0.35 3.153 0.35v3.5h-1.753c-1.747 0 -2.274 1.053 -2.274 2.1V28h3.85l-0.7 4.026h-3.326V42A14.123 14.123 0 0 0 42 28Z"
                fill="white"
            />
        </svg>
    );
};

import { Button, Snackbar, SnackbarOrigin } from "@mui/material";
import { Fragment, ReactNode } from "react";

type AppSnackBarType = {
    open: boolean;
    onClose: (e: any) => void;
    message: ReactNode;
    anchorOrigin?: SnackbarOrigin | undefined;
};

export const AppSnackBar = (props: AppSnackBarType) => {
    /* Props */
    const { onClose, open, message, anchorOrigin } = props;

    const action = (
        <Fragment>
            <Button color="info" size="small" onClick={onClose}>
                Close
            </Button>
        </Fragment>
    );

    return (
        <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={onClose}
            message={message}
            action={action}
            anchorOrigin={anchorOrigin}
        />
    );
};

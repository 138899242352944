export const SB = "md";

export const defaultLinkCatogery = "default";
export const lockedContentCategory = 2;
export const PAN_VALUE = 10000;
export const payKey = ["1", "2", "3", "4", "5", "6", "7", "8", "9", ".", "0"];
export const GOLD_PRICE_TIME = 4;
export const PAYMENT_COMPLETE_ORDER_ID = "order_id";
export const PAYMENT_COMPLETE_SHORT_ID = "shortId";
export const PAYMENT_USERNAME = "creator_username";
export const GOLD_INPUT_PRECISION = 4;
export const PAYMENT_OTP_SCENARIO = "payer-payment";
export const OTP_SENT = "OTP sent";
export const OTP_SENT_LOADING = "OTP sent loading";
export const OTP_SENT_ERROR = "OTP sent error";
export const LOADING = "LOADING";
export const SUCCESS = "SUCCESS";
export const ERROR = "ERROR";
export const SUPPORT = "SUPPORT";
export const LOCKED = "LOCKED";
export const PAY_AS_WANT = "PAY_AS_WANT";

export const MAIN_WEB_URL = "https://yourgold.app/";

export const SOCIAL_MEDIA_CONSTANTS = {
    FACEBOOK: "Facebook",
    WHATSAPP: "Whatsapp",
    INSTAGRAM: "Instagram",
    LINKEDIN: "LinkedIn",
    TELEGRAM: "Telegram",
    BEHANCE: "Behance",
    DISCORD: "Discord",
    DRIBBLE: "Dribble",
    LOKAL: "Lokal",
    MOJ: "Moj",
    TAKATAK: "Takatak",
    PINTEREST: "Pinterest",
    REDDIT: "Reddit",
    ROOTER: "Rooter",
    SHARECHAT: "Sharechat",
    SNAPCHAT: "Snapchat",
    SPOTIFY: "Spotify",
    TWITCH: "Twitch",
    YOUTUBE: "YouTube",
};

export const RUPEE_SLIDER_VALUE = [0, 250, 500, 750, 1000];
export const GRAM_SLIDER_VALUE = [0, 0.25, 0.5, 0.75, 1];

import React from "react";

export const InstagramIcon = () => {
    return (
        <svg
            width={56}
            height={56}
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 28c0 15.464 12.536 28 28 28s28 -12.536 28 -28S43.464 0 28 0 0 12.536 0 28Z"
                fill="#F00073"
            />
            <path
                d="M28 16.1h5.95c1.4 0 2.1 0.35 2.626 0.526 0.7 0.35 1.224 0.524 1.747 1.05 0.526 0.524 0.876 1.05 1.053 1.75 0.174 0.524 0.347 1.224 0.524 2.624v11.9c0 1.4 -0.35 2.1 -0.526 2.626 -0.347 0.7 -0.524 1.224 -1.047 1.747a3.632 3.632 0 0 1 -1.753 1.053c-0.524 0.174 -1.224 0.347 -2.624 0.524h-11.9c-1.4 0 -2.1 -0.35 -2.626 -0.526 -0.7 -0.347 -1.224 -0.524 -1.75 -1.047a3.632 3.632 0 0 1 -1.05 -1.753c-0.174 -0.524 -0.35 -1.224 -0.524 -2.624v-11.9c0 -1.4 0.35 -2.1 0.526 -2.626 0.35 -0.7 0.524 -1.224 1.05 -1.75 0.524 -0.524 1.05 -0.874 1.75 -1.05 0.524 -0.174 1.224 -0.35 2.624 -0.524H28Zm0 -2.626H22.05c-1.576 0 -2.626 0.35 -3.5 0.7a7.641 7.641 0 0 0 -2.626 1.75c-0.874 0.876 -1.224 1.576 -1.75 2.626 -0.35 0.876 -0.524 1.926 -0.7 3.5v11.9c0 1.576 0.35 2.626 0.7 3.5 0.35 0.876 0.876 1.75 1.75 2.626 0.876 0.874 1.576 1.224 2.626 1.747 0.876 0.353 1.926 0.526 3.5 0.7h11.9c1.576 0 2.626 -0.347 3.5 -0.7 0.876 -0.347 1.75 -0.874 2.626 -1.747 0.874 -0.876 1.224 -1.576 1.747 -2.626 0.353 -0.876 0.526 -1.926 0.7 -3.5v-11.9c0 -1.576 -0.347 -2.626 -0.7 -3.5a7.641 7.641 0 0 0 -1.747 -2.626c-0.876 -0.874 -1.576 -1.224 -2.626 -1.75 -0.876 -0.35 -1.926 -0.524 -3.5 -0.7H28Z"
                fill="white"
            />
            <path
                d="M28 20.476A7.47 7.47 0 0 0 20.474 28c0 4.2 3.326 7.526 7.526 7.526 4.2 0 7.526 -3.326 7.526 -7.526 0 -4.2 -3.326 -7.526 -7.526 -7.526Zm0 12.424c-2.626 0 -4.9 -2.1 -4.9 -4.9 0 -2.626 2.1 -4.9 4.9 -4.9 2.626 0 4.9 2.1 4.9 4.9 0 2.626 -2.276 4.9 -4.9 4.9Z"
                fill="white"
            />
            <path
                d="M35.7 22.05a1.75 1.75 0 1 0 0 -3.5 1.75 1.75 0 0 0 0 3.5Z"
                fill="white"
            />
        </svg>
    );
};

import { Grid } from "@mui/material";
import React from "react";
import {
    SMALL_USER,
    TransactionCountUser1,
    TransactionCountUser2,
    TransactionCountUser3,
} from "../../utils/Image";
import { User1 } from "../../assets/testing/User1";
import { AppText } from "../AppText";
import { Repeater } from "../Repeater";
import { SB } from "../../constants/AppConstant";
import { BOUGHT_THIS } from "../../constants/AppString";
import { Trending } from "../Trending";
import { ColumnGrid } from "../ColumnGrid";

type MultiplePayUserType = {
    noOfUser: number | string;
    userText?: string;
    showTrending?: boolean;
    showTextColor?: boolean;
};

export const MultiplePayUser = (props: MultiplePayUserType) => {
    /* Porps */
    const {
        noOfUser = 0,
        userText = BOUGHT_THIS,
        showTrending,
        showTextColor = true,
    } = props;

    /* Render */
    return (
        <Grid container alignItems="center" justifyContent="center">
            <Grid item>
                <Grid
                    container
                    borderRadius={40}
                    alignItems="center"
                    columnGap={1}
                    sx={{
                        bgcolor: {
                            // xs: "#F1CF6313",
                            [SB]: "transparent",
                        },
                    }}
                >
                    {!!noOfUser && (
                        <Grid
                            item
                            sx={{
                                height: {
                                    xs: 20,
                                    [SB]: 44,
                                },
                                width: {
                                    xs:
                                        (Number(noOfUser) === 1
                                            ? 1
                                            : Number(noOfUser) === 2
                                            ? 2
                                            : 3) * 20,
                                    [SB]:
                                        (Number(noOfUser) === 1
                                            ? 2
                                            : Number(noOfUser) === 2
                                            ? 3
                                            : 4) * 22,
                                },
                            }}
                        >
                            <Grid container position="relative">
                                <Repeater
                                    count={
                                        Number(noOfUser) > 3
                                            ? 3
                                            : Number(noOfUser)
                                    }
                                    renderItem={(index) => {
                                        let image;
                                        if (index === 0) {
                                            image = TransactionCountUser1;
                                        }
                                        if (index === 1) {
                                            image = TransactionCountUser2;
                                        }
                                        if (index === 2) {
                                            image = TransactionCountUser3;
                                        }

                                        return (
                                            <Grid
                                                item
                                                position="absolute"
                                                sx={{
                                                    height: {
                                                        xs: 20,
                                                        [SB]: 44,
                                                    },
                                                    width: {
                                                        xs: 20,
                                                        [SB]: 44,
                                                    },
                                                    right: {
                                                        xs: index * 10,
                                                        [SB]: index * 22,
                                                    },
                                                }}
                                            >
                                                <img
                                                    src={image}
                                                    width="100%"
                                                    height="100%"
                                                />
                                            </Grid>
                                        );
                                    }}
                                />
                            </Grid>
                        </Grid>
                    )}
                    <Grid item>
                        <ColumnGrid rowGap={0.5}>
                            {showTrending && (
                                <Grid item>
                                    <Grid container>
                                        <Grid item>
                                            <Trending />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid
                                item
                                position="relative"
                                sx={{
                                    bgcolor: showTextColor
                                        ? "#F1CF6313"
                                        : undefined,
                                    paddingY: {
                                        xs: 0.5,
                                        [SB]: 1,
                                    },
                                    paddingX: {
                                        xs: 1,
                                        [SB]: 1,
                                    },
                                    borderRadius: {
                                        xs: 30,
                                        [SB]: 40,
                                    },
                                }}
                            >
                                <AppText
                                    children={userText}
                                    color="#ffffff"
                                    fontWeight="500"
                                    fontSize={12}
                                />
                            </Grid>
                        </ColumnGrid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

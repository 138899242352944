import { Grid } from "@mui/material";
import { BackIcon } from "../../../../assets/icons/BackIcon";
import { SvgPropType } from "../../../../types/PropsType";

export const ContentBackIcon = (props: SvgPropType) => {
    /* Props */
    const { height = 40, width = 40 } = props;

    /* Render */
    return (
        <Grid
            container
            height={height}
            width={width}
            borderRadius={20}
            justifyContent="center"
            alignItems="center"
            border={1}
            borderColor="#FFFFFF24"
        >
            <BackIcon />
        </Grid>
    );
};

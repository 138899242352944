export const BackIcon = () => {
    /* Render */
    return (
        <svg
            width="8"
            height="12"
            viewBox="0 0 8 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.82981 11.0002C7.01606 10.8128 7.12061 10.5594 7.12061 10.2952C7.12061 10.031 7.01606 9.77756 6.82981 9.59019L3.28981 6.00019L6.82981 2.46019C7.01606 2.27283 7.12061 2.01938 7.12061 1.75519C7.12061 1.49101 7.01606 1.23756 6.82981 1.05019C6.73685 0.956464 6.62625 0.88207 6.50439 0.831301C6.38253 0.780533 6.25182 0.754394 6.11981 0.754394C5.9878 0.754394 5.8571 0.780532 5.73524 0.831301C5.61338 0.88207 5.50278 0.956464 5.40981 1.05019L1.16981 5.29019C1.07608 5.38316 1.00169 5.49376 0.950922 5.61562C0.900153 5.73747 0.874015 5.86818 0.874015 6.00019C0.874015 6.1322 0.900153 6.26291 0.950922 6.38477C1.00169 6.50663 1.07608 6.61723 1.16981 6.71019L5.40981 11.0002C5.50278 11.0939 5.61338 11.1683 5.73524 11.2191C5.8571 11.2699 5.9878 11.296 6.11981 11.296C6.25182 11.296 6.38253 11.2699 6.50439 11.2191C6.62625 11.1683 6.73685 11.0939 6.82981 11.0002Z"
                fill="#F2F2F2"
            />
        </svg>
    );
};

import { ResponsiveLayout } from "../../../../components/ResponsiveLayout";
import { Grid } from "@mui/material";
import { useScreenDimension } from "../../../../hooks/useScreenDimension";
import { PayHeader } from "../../components/PayHeader";
import {
    ACCEPT_ALL,
    ENTER_AMOUNT,
    FIRST_SUPPORT_USER,
    IN_GRAM,
    IN_GRAM_TEXT,
    IN_RUPEE,
    IN_RUPEE_TEXT,
    PAY,
    PAY_TO,
    SUPPORT_ME_OG,
    SUPPORT_THEM,
    TERM_AND_CONDITIION,
} from "../../../../constants/AppString";
import { UserProfileImage } from "../../../../components/UserProfileImage";
import { AppText } from "../../../../components/AppText";
import { MultiplePayUser } from "../../../../components/MultiplePayUser";
import { PayCard } from "../../../../components/PayCard";
import { PaySlider } from "../../../../components/PaySlider";
import { Terms } from "../../components/Terms";
import { WebPayBackground } from "../../components/WebPayBackground";
import { SupportCreatorKeyboard } from "../../components/SupportCreatorKeyboard";
import { SupportCreatorMobilePaymentBackground } from "../../components/SupportCreatorMobilePaymentBackground";
import { ColumnGrid } from "../../../../components/ColumnGrid";
import { usePaymentInitializer } from "../../hooks/usePyamentInitializer";
import { PaymentBreakDownSection } from "../../components/PaymentBreakDownSection";
import { useOtherLink } from "../../../../hooks/useOtherLink";
import { useUserProfileRoute } from "../../../Profile/hooks/useUserProfileRoute";
import { SearchEngineType } from "../../../../components/SearchEngine";
import { useGetProfileRD } from "../../../Profile/hooks/useGetProfileRD";
import {
    GRAM_SLIDER_VALUE,
    RUPEE_SLIDER_VALUE,
} from "../../../../constants/AppConstant";

export const SupportCreatorPage = () => {
    /* Custom Hooks */
    const {
        input,
        setinput,
        amount,
        setAmount,
        goldWeightPrice,
        setGoldWeightPrice,
        termCondition,
        setTermCondition,
        profileData,
        supportUserPaymentDetailHandler,
        resetPayment,
        getGoldPrice,
        goldLoading,
        goldPrice,
        goldPriceTime,
        gstRate,
        weight,
        transactionCount,
        amountHandler,
        getNewGoldPrice,
        inputAmountHandler,
        supportUserPaymentFinalizer,
        termConditionHandler,
        valueThroughNumberHandler,
        gstPrice,
        amountType,
        amountTypeHandler,
    } = usePaymentInitializer();
    const { termOfUseHandler } = useOtherLink();

    /* Render */
    const USER_TEXT = `${PAY_TO} ${profileData?.creatorInfo?.firstName}`;

    const DISABLE_BUTTON =
        !termCondition || !Number(amount) || Number(amount) < 10;

    const PaySliderCommon = (
        <PaySlider
            amountType={amountType}
            scaleValue={
                amountType === IN_RUPEE ? RUPEE_SLIDER_VALUE : GRAM_SLIDER_VALUE
            }
            goldWeight={input}
            goldWeightHandler={amountHandler}
            goldWeightPrice={goldWeightPrice}
        />
    );

    return (
        <>
            <SearchEngineType
                title={`${profileData?.creatorInfo?.firstName} ${profileData?.creatorInfo?.lastName}`}
                description={SUPPORT_ME_OG}
                image={
                    profileData?.creatorInfo?.profileImg
                        ? profileData?.creatorInfo?.profileImg
                        : ""
                }
            />
            <ResponsiveLayout
                mobile={
                    <SupportCreatorMobilePaymentBackground
                        backHandler={resetPayment}
                        amount={amount}
                        getNewGoldPrice={getNewGoldPrice}
                        goldPrice={goldPrice}
                        supportUserPaymentFinalizer={
                            supportUserPaymentFinalizer
                        }
                        disable={DISABLE_BUTTON}
                        gstRate={gstRate}
                        goldWeightPrice={goldWeightPrice}
                        goldWeight={input}
                        gstPrice={gstPrice}
                        bottomComponent={
                            <ColumnGrid>
                                <Grid item paddingTop={7} position="relative">
                                    <ColumnGrid
                                        borderRadius="9px 9px 0px 0px"
                                        bgColor="rgba(11, 11, 12, 1)"
                                        paddingBottom={20}
                                    >
                                        <Grid
                                            item
                                            paddingX={2}
                                            position="absolute"
                                            top={0}
                                            right={0}
                                            left={0}
                                        >
                                            <PayCard
                                                amountText={
                                                    amountType === IN_GRAM
                                                        ? IN_GRAM_TEXT
                                                        : IN_RUPEE_TEXT
                                                }
                                                value={input}
                                                setValue={setinput}
                                                amountHandler={
                                                    inputAmountHandler
                                                }
                                                amount={amount}
                                                readOnly
                                                amountType={amountType}
                                                amountTypeHandler={
                                                    amountTypeHandler
                                                }
                                                goldWeightPrice={
                                                    goldWeightPrice
                                                }
                                            />
                                        </Grid>
                                        <Grid item paddingTop={20} paddingX={2}>
                                            {PaySliderCommon}
                                        </Grid>
                                        <Grid item paddingY={2} height={300}>
                                            <SupportCreatorKeyboard
                                                valueThroughNumberHandler={
                                                    valueThroughNumberHandler
                                                }
                                            />
                                        </Grid>
                                        <Terms
                                            checked={termCondition}
                                            title={ACCEPT_ALL}
                                            subTitle={TERM_AND_CONDITIION}
                                            checkHandler={termConditionHandler}
                                            linkHandler={termOfUseHandler}
                                        />
                                    </ColumnGrid>
                                </Grid>
                            </ColumnGrid>
                        }
                    />
                }
                web={
                    <WebPayBackground
                        backHandler={resetPayment}
                        numberOfCol={0}
                    >
                        <Grid container paddingX={3} width={600}>
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    direction="column"
                                    height="100%"
                                    justifyContent="space-between"
                                >
                                    <Grid item>
                                        <Grid
                                            item
                                            xs={12}
                                            paddingTop={2}
                                            alignSelf="center"
                                        >
                                            <Grid
                                                container
                                                justifyContent="center"
                                            >
                                                <Grid item>
                                                    <UserProfileImage />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <AppText
                                                children={USER_TEXT}
                                                fontWeight="700"
                                                fontSize={20}
                                                textAlign={"center"}
                                                color="#FFFFFF"
                                                fontFamily="Marcellus"
                                            />
                                        </Grid>
                                        <Grid item xs={12} paddingTop={2}>
                                            <MultiplePayUser
                                                noOfUser={transactionCount}
                                                userText={
                                                    transactionCount
                                                        ? `+${transactionCount} ${SUPPORT_THEM}`
                                                        : FIRST_SUPPORT_USER
                                                }
                                            />
                                        </Grid>

                                        <Grid item marginTop={4}>
                                            <PayCard
                                                amountText={
                                                    amountType === IN_GRAM
                                                        ? IN_GRAM_TEXT
                                                        : IN_RUPEE_TEXT
                                                }
                                                value={input}
                                                setValue={setinput}
                                                amountHandler={
                                                    inputAmountHandler
                                                }
                                                amount={amount}
                                                amountType={amountType}
                                                amountTypeHandler={
                                                    amountTypeHandler
                                                }
                                                goldWeightPrice={
                                                    goldWeightPrice
                                                }
                                            />
                                        </Grid>
                                        <Grid item marginTop={4}>
                                            {PaySliderCommon}
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        item
                                        paddingTop={10}
                                        paddingBottom={2}
                                    >
                                        <ColumnGrid>
                                            <Grid item>
                                                <Terms
                                                    checked={termCondition}
                                                    title={ACCEPT_ALL}
                                                    subTitle={
                                                        TERM_AND_CONDITIION
                                                    }
                                                    checkHandler={
                                                        termConditionHandler
                                                    }
                                                    linkHandler={
                                                        termOfUseHandler
                                                    }
                                                />
                                            </Grid>
                                            <Grid item paddingTop={2}>
                                                <PaymentBreakDownSection
                                                    isPayment
                                                    goldPrice={
                                                        goldPrice
                                                            ? goldPrice?.toString()
                                                            : "0"
                                                    }
                                                    getNewGoldPrice={
                                                        getNewGoldPrice
                                                    }
                                                    amount={amount}
                                                    supportUserPaymentFinalizer={
                                                        supportUserPaymentFinalizer
                                                    }
                                                    disable={DISABLE_BUTTON}
                                                    gstRate={gstRate}
                                                    goldWeightPrice={
                                                        goldWeightPrice
                                                    }
                                                    goldWeight={input}
                                                    gstPrice={gstPrice}
                                                />
                                            </Grid>
                                        </ColumnGrid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </WebPayBackground>
                }
            />
        </>
    );
};

import { useEffect, useState } from "react";
import { getRequest } from "../../network/Request";
import { GET_GOLD_PRICE } from "../../network/Url";

export const useGoldPrice = () => {
    /* States */
    const [goldPrice, setGoldPrice] = useState<null | number>(null);
    const [goldLoading, setGoldLoading] = useState(false);
    const [goldPriceTime, setGoldPriceTime] = useState("");
    const [gstRate, setGstRate] = useState("");

    /* Handler */
    const getGoldPrice = async () => {
        try {
            setGoldLoading(true);
            const res = (await getRequest({ url: GET_GOLD_PRICE })) as any;
            if (res?.data?.Response) {
                setGoldLoading(false);
                setGoldPrice(res?.data?.Response?.current_price);
                setGoldPriceTime(res?.data?.Response?.rate_validity);
                setGstRate(res?.data?.Response?.applicable_tax);
                return;
            }
        } catch (error: any) {
            setGoldLoading(false);
        }
    };

    return { getGoldPrice, goldPrice, goldLoading, goldPriceTime, gstRate };
};

import { Grid } from "@mui/material";
import { AppText } from "../AppText";
import { GoldBricks } from "../../assets/icons/GoldBricks";
import { AppInput } from "../AppInput";
import {
    GOLD_10_CHECK,
    GOLD_WEIGHT_WORTH,
    GOLD_WORTH,
    IN_GRAM,
    IN_RUPEE,
    RUPEE,
} from "../../constants/AppString";
import { AppRadioButton } from "../AppRadioButton";

type PayCardType = {
    amountText: string;
    minimumAmount?: number;
    value: string | undefined;
    setValue: any;
    amountHandler: any;
    amount: string | number;
    readOnly?: boolean;
    inputType?: "number" | "text" | "tel";
    amountType: string;
    amountTypeHandler: (text: string) => void;
    goldWeightPrice: string;
    isMinimumAmount?: boolean;
};

export const PayCard = (props: PayCardType) => {
    /* Props */
    const {
        minimumAmount,
        amountText,
        value,
        amount,
        amountHandler,
        readOnly = false,
        inputType = "text",
        amountType,
        amountTypeHandler,
        goldWeightPrice,
        isMinimumAmount = false,
    } = props;

    /* Render */
    return (
        <Grid
            container
            direction="column"
            borderRadius={4}
            paddingX={2}
            paddingTop={2}
            bgcolor="#B18D2437"
            sx={{
                backdropFilter: "blur(8px)",
                WebkitBackdropFilter: "blur(8px)",
            }}
            position="relative"
        >
            <Grid item>
                <Grid container justifyContent="space-between">
                    <AppRadioButton
                        onChange={amountTypeHandler}
                        value={amountType}
                        optionList={[
                            { label: IN_RUPEE, value: IN_RUPEE },
                            { label: IN_GRAM, value: IN_GRAM },
                        ]}
                    />
                </Grid>
            </Grid>
            <Grid container justifyContent="space-between" marginTop={2}>
                <Grid item>
                    <Grid container direction="column">
                        <Grid item>
                            <AppText
                                children={amountText}
                                fontWeight="500"
                                fontSize={10}
                                color={
                                    minimumAmount && isMinimumAmount
                                        ? "rgba(255, 49, 49, 1)"
                                        : "white"
                                }
                            />
                        </Grid>
                        {!!minimumAmount && (
                            <Grid item>
                                <AppText
                                    children={minimumAmount}
                                    fontWeight="700"
                                    fontSize={16}
                                    color={
                                        isMinimumAmount
                                            ? "rgba(255, 49, 49, 1)"
                                            : "white"
                                    }
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item>
                    <AppText
                        paddingX={3}
                        paddingY={0.8}
                        borderRadius={3}
                        bgcolor="#FFFFFF15"
                        children={
                            amountType === IN_GRAM
                                ? `₹ ${
                                      goldWeightPrice ? goldWeightPrice : 0
                                  } Value`
                                : `${value ? value : 0} grams`
                        }
                        fontWeight="500"
                        fontSize={12}
                        color="white"
                    />
                </Grid>
            </Grid>
            <Grid container marginTop={2} alignItems="center">
                <Grid item>
                    <GoldBricks />
                </Grid>
                <Grid item flex={1} marginBottom={1}>
                    <AppInput
                        type={inputType}
                        value={amountType === IN_GRAM ? value : goldWeightPrice}
                        onChange={amountHandler}
                        sx={{
                            "& .MuiInput-underline:before": {
                                borderBottomColor: "#FFFFFF19",
                            },
                            "& .MuiInput-underline:after": {
                                borderBottomColor: "#FFFFFF19",
                            },

                            "& .MuiInput-underline:hover:not(.Mui-disabled):before":
                                {
                                    borderBottomColor: "transparent", // Change color on hover
                                },

                            "& input": {
                                color: "#fff",
                                fontWeight: "900",
                                fontSize: 20,
                            },
                            "& input[type=number]": {
                                MozAppearance: "textfield",
                            },
                            "& input[type=number]::-webkit-outer-spin-button": {
                                WebkitAppearance: "none",
                                margin: 0,
                            },
                            "& input[type=number]::-webkit-inner-spin-button": {
                                WebkitAppearance: "none",
                                margin: 0,
                            },
                        }}
                        InputProps={
                            amountType === IN_GRAM
                                ? {
                                      endAdornment: (
                                          <AppText
                                              children="gm"
                                              color="#FFFFFF"
                                              fontWeight={400}
                                              fontSize={20}
                                              paddingBottom={1}
                                          />
                                      ),
                                      readOnly: readOnly,
                                  }
                                : {
                                      startAdornment: (
                                          <AppText
                                              children={`${RUPEE}`}
                                              color="#FFFFFF"
                                              fontWeight={400}
                                              fontSize={20}
                                              paddingRight={1}
                                          />
                                      ),
                                      readOnly: readOnly,
                                  }
                        }
                    />
                </Grid>
            </Grid>
            {!!minimumAmount && isMinimumAmount ? (
                <Grid item position="relative" top={-10} left={50}>
                    <AppText
                        children={
                            amountType === IN_GRAM
                                ? GOLD_WEIGHT_WORTH + minimumAmount + "gm"
                                : GOLD_WORTH + minimumAmount
                        }
                        fontSize={10}
                        color={"rgba(255, 49, 49, 1)"}
                    />
                </Grid>
            ) : Number(amount) < 10 ? (
                <Grid item position="relative" top={-10} left={50}>
                    <AppText
                        children={GOLD_10_CHECK}
                        fontSize={10}
                        color={"rgba(255, 49, 49, 1)"}
                    />
                </Grid>
            ) : null}
        </Grid>
    );
};

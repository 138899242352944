import * as React from "react";
export const ShareIcon = () => (
    <svg width={12} height={12} viewBox="0 0 12 12" fill="none">
        <g clipPath="url(#clip0_518_12526)">
            <path
                d="M9.51562 7.78125C8.82091 7.78125 8.20873 8.12299 7.82421 8.6427L4.49977 6.94041C4.55496 6.75227 4.59375 6.5573 4.59375 6.35156C4.59375 6.07252 4.53654 5.80723 4.4377 5.56322L7.91688 3.46959C8.30407 3.924 8.87318 4.21875 9.51562 4.21875C10.6788 4.21875 11.625 3.27255 11.625 2.10938C11.625 0.946195 10.6788 0 9.51562 0C8.35245 0 7.40625 0.946195 7.40625 2.10938C7.40625 2.37743 7.46145 2.6317 7.55302 2.86777L4.06348 4.96753C3.67662 4.52663 3.11571 4.24219 2.48438 4.24219C1.3212 4.24219 0.375 5.18838 0.375 6.35156C0.375 7.51474 1.3212 8.46094 2.48438 8.46094C3.19055 8.46094 3.81307 8.10916 4.19613 7.57486L7.50963 9.27162C7.4486 9.46863 7.40625 9.67383 7.40625 9.89062C7.40625 11.0538 8.35245 12 9.51562 12C10.6788 12 11.625 11.0538 11.625 9.89062C11.625 8.72745 10.6788 7.78125 9.51562 7.78125Z"
                fill="#D4D2D7"
            />
        </g>
        <defs>
            <clipPath id="clip0_518_12526">
                <rect width={12} height={12} fill="white" />
            </clipPath>
        </defs>
    </svg>
);

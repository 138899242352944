import { useEffect, useState } from "react";
import { usePaymentRD } from "../usePaymentRD";
import { useGoldPrice } from "../../../../hooks/useGoldPrice";
import { usePaymentRoute } from "../usePaymentRoute";
import { useGetProfileRD } from "../../../Profile/hooks/useGetProfileRD";
import {
    validateDecimalHandler,
    validateNumericHandler,
    validateNumericWithDecimalHandler,
} from "../../../../utils/StringHelper";
import { updatePaymentDetail } from "../../../../redux/slices/content";
import { AppDispatch } from "../../../../redux/store";
import { useDispatch } from "react-redux";
import { getRequest, putRequest } from "../../../../network/Request";
import { VIEW_COUNT } from "../../../../network/Url";
import { updateGoldifyLinksCount } from "../../../../redux/slices/profile";
import { LOCKED } from "../../../../constants/AppConstant";
import { useAppNavigation } from "../../../../hooks/useAppNavigation";
import { useParams } from "react-router-dom";
import { IN_GRAM, IN_RUPEE } from "../../../../constants/AppString";

export const usePaymentInitializer = () => {
    /* Hooks */
    const dispatch = useDispatch<AppDispatch>();

    /* Custom Hooks */
    const { profileData } = useGetProfileRD();
    const {
        supportUserPaymentDetailHandler,
        resetPayment,
        supportUserPaymentThanksHandler,
        paymentDetailHandler,
    } = usePaymentRoute();
    const { getGoldPrice, goldLoading, goldPrice, goldPriceTime, gstRate } =
        useGoldPrice();
    const {
        weight,
        transactionCount,
        shortId,
        minWeight,
        viewCount,
        amountType: reduxAmountType,
        goldWeightPrice: reduxGoldWeightPrice,
    } = usePaymentRD();
    const { username, shortId: userShortId } = useParams();

    /* States */
    const [input, setinput] = useState<any>("0");
    const [amount, setAmount] = useState("");
    const [goldWeightPrice, setGoldWeightPrice] = useState("");
    const [termCondition, setTermCondition] = useState(false);
    const [gstPrice, setGstPrice] = useState("");
    const [amountType, setAmountType] = useState("");

    /* Handler */
    const amountTypeHandler = (type: string) => {
        setAmountType(type);
    };

    const unlockContentUmountTypeHandler = (type: string) => {
        setAmountType(type);
        setinput("");
        setAmount("");
        setGoldWeightPrice("");
    };

    const viewCountHandler = async ({
        shortId,
        viewCount,
    }: {
        shortId: string;
        viewCount: number;
    }) => {
        try {
            const res: any = await putRequest({
                url: VIEW_COUNT + "/" + shortId,
            });
            if (res?.data?.message === "View updated successfully") {
                dispatch(
                    updateGoldifyLinksCount({
                        shortId,
                        viewCount: viewCount + 1,
                    })
                );
                return;
            }
        } catch {}
    };

    const paymentUpdate = (pay?: any) => {
        dispatch(
            updatePaymentDetail({
                amount: pay?.isFreeContentFlag === "YES" ? 0 : amount,
                weight: pay?.isFreeContentFlag === "YES" ? 0 : input,
                gstRate,
                goldWeightPrice,
                goldPrice,
                gstPrice,
                isFreeContent: pay?.isFreeContentFlag === "YES",
                amountType: amountType,
            })
        );
    };

    const supportUserPaymentFinalizer = () => {
        paymentUpdate();
        supportUserPaymentDetailHandler();
    };

    const lockedContentPaymentFinalizer = () => {
        paymentUpdate();
        paymentDetailHandler();
    };

    const payAsYouWantPaymentFinalizer = (pay?: {
        isFreeContentFlag: string;
    }) => {
        paymentUpdate(pay);
        paymentDetailHandler();
    };

    const goToContentHandler = () =>
        supportUserPaymentThanksHandler({
            shortId,
            orderId: "",
            username: profileData?.creatorInfo?.username
                ? profileData?.creatorInfo?.username
                : "",
        });

    const getNewGoldPrice = () => {
        getGoldPrice();
    };

    const termConditionHandler = () => {
        setTermCondition((prev) => !prev);
    };

    const setAllValue = ({
        newValue,
        currentAmountType = amountType,
    }: {
        newValue: string;
        currentAmountType?: string;
    }) => {
        if (currentAmountType === IN_GRAM) {
            setinput(newValue ? newValue : undefined);
            const goldWeightPrice = (
                Number(newValue) * Number(goldPrice)
            )?.toFixed(2);
            const gstPrice = (
                (Number(gstRate) / 100) *
                Number(goldWeightPrice)
            )?.toFixed(2);
            setGstPrice(gstPrice);
            setGoldWeightPrice(goldWeightPrice);
            setAmount((Number(goldWeightPrice) + Number(gstPrice))?.toFixed(2));
            return;
        }
        const rupeeAmount = newValue ? newValue : 0;
        const goldWeight = Number(
            Number(rupeeAmount) / Number(Number(goldPrice)?.toFixed(2))
        )?.toFixed(5);
        setinput(goldWeight);
        const gstPrice = (
            (Number(gstRate) / 100) *
            Number(rupeeAmount)
        )?.toFixed(2);
        setGstPrice(gstPrice);
        setGoldWeightPrice(rupeeAmount?.toString());
        setAmount((Number(rupeeAmount) + Number(gstPrice))?.toFixed(2));
    };

    const valueThroughNumberHandler = (value?: string) => {
        if (amountType === IN_GRAM) {
            if (!value) {
                const newValue = input?.toString()?.slice(0, -1);
                setAllValue({ newValue: Number(newValue)?.toString() });
                return;
            }
            inputAmountHandler({
                target: {
                    value: input ? input + value : value,
                },
            });
            return;
        }
        if (!value) {
            const newValue = goldWeightPrice?.toString()?.slice(0, -1);
            setAllValue({ newValue: Number(newValue)?.toString() });
            return;
        }
        inputAmountHandler({
            target: {
                value: goldWeightPrice ? goldWeightPrice + value : value,
            },
        });
        return;
    };

    const amountHandler = (value: any) => {
        if (!value) {
            setAllValue({ newValue: value });
            return;
        }
        if (validateNumericWithDecimalHandler(value)) {
            setAllValue({ newValue: value });
            return;
        }
    };

    const inputAmountHandler = (e: any) => {
        const value = e?.target?.value;
        const newValue = Number(value)?.toString();
        if (!newValue && newValue !== "NaN" && Number(newValue) !== 0) {
            setAllValue({ newValue });
            return;
        }
        const splitValue = value?.split(".");
        if (
            splitValue?.length === 2 &&
            splitValue[1] &&
            validateNumericHandler(splitValue[1]) &&
            splitValue[1]?.toString()?.length > 4
        ) {
            // setAllValue({ newValue: value });
            return;
        }
        if (
            splitValue?.length === 2 &&
            splitValue[1] &&
            validateNumericHandler(splitValue[1])
        ) {
            setAllValue({ newValue: value });
            return;
        }
        if (splitValue?.length === 2 && !splitValue[1]) {
            setAllValue({ newValue: value });
            return;
        }
        if (validateNumericHandler(newValue)) {
            setAllValue({ newValue });
            return;
        }
    };

    /* Effects */
    useEffect(() => {
        if (true) {
            setAmountType(IN_RUPEE);
        }
    }, []);

    useEffect(() => {
        if (shortId) {
            viewCountHandler({ shortId, viewCount });
        }
    }, [shortId, viewCount]);

    useEffect(() => {
        getGoldPrice();
    }, []);

    useEffect(() => {
        if (
            goldPrice &&
            weight &&
            gstRate &&
            reduxAmountType &&
            reduxGoldWeightPrice
        ) {
            setAmountType(reduxAmountType);
            setAllValue({
                newValue:
                    reduxAmountType === IN_RUPEE
                        ? reduxGoldWeightPrice
                        : weight,
                currentAmountType: reduxAmountType,
            });
        }
    }, [goldPrice, weight, gstRate, reduxAmountType, reduxGoldWeightPrice]);

    useEffect(() => {
        if (minWeight) {
            setinput(minWeight ? minWeight : undefined);
        }
    }, [minWeight]);

    useEffect(() => {
        if (!profileData && username && userShortId) {
            window.location.replace(`/${username}/${userShortId}`);
            return;
        }
    }, [profileData, username, userShortId]);

    return {
        gstPrice,
        termConditionHandler,
        getNewGoldPrice,
        supportUserPaymentFinalizer,
        valueThroughNumberHandler,
        amountHandler,
        input,
        inputAmountHandler,
        setinput,
        amount,
        setAmount,
        goldWeightPrice,
        setGoldWeightPrice,
        termCondition,
        setTermCondition,
        profileData,
        supportUserPaymentDetailHandler,
        resetPayment,
        getGoldPrice,
        goldLoading,
        goldPrice,
        goldPriceTime,
        gstRate,
        weight,
        transactionCount,
        goToContentHandler,
        minWeight,
        payAsYouWantPaymentFinalizer,
        lockedContentPaymentFinalizer,
        amountType,
        amountTypeHandler,
        unlockContentUmountTypeHandler,
    };
};

import { Grid } from "@mui/material";
import React from "react";
import { payKey } from "../../../../constants/AppConstant";
import { AppText } from "../../../../components/AppText";
import { DeleteIcon } from "../../../../assets/icons/DeleteIcon";

type SupportCreatorKeyboardType = {
    valueThroughNumberHandler: (text?: string) => void;
};

export const SupportCreatorKeyboard = (props: SupportCreatorKeyboardType) => {
    /* Props */
    const { valueThroughNumberHandler } = props;

    /* Render */
    return (
        <Grid container height="100%">
            {payKey?.map((item, index) => {
                return (
                    <Grid
                        item
                        xs={4}
                        key={index?.toString()}
                        onClick={() =>
                            valueThroughNumberHandler(item?.toString())
                        }
                    >
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            height="100%"
                        >
                            <Grid item>
                                <AppText children={item} fontSize={24} />
                            </Grid>
                        </Grid>
                    </Grid>
                );
            })}
            <Grid item xs={4} onClick={() => valueThroughNumberHandler()}>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                >
                    <Grid item>
                        <DeleteIcon />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

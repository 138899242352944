import moment from "moment";

export const isDateExpired = (date: Date | string) => {
    if (!date) {
        return;
    }
    const parsedDate = moment(date);
    return parsedDate.isBefore(moment());
};

export const getNumberOfDays = (date: Date | string) => {
    if (!date) {
        return;
    }
    const today = moment();
    const dateToCompare = moment(date);
    const numberOfDays = dateToCompare.diff(today, "days");

    return numberOfDays;
};

export const showLinkExpireDate = (date: Date | string) => {
    if (!date) {
        return false;
    }
    const expired = isDateExpired(date);
    if (expired) {
        return true;
    }
    const numberOfDays = getDaysOrHour(date);
    if (numberOfDays && numberOfDays <= 10) {
        return true;
    }
    return false;
};

export const getDaysOrHour = (date: Date | string) => {
    if (!date) {
        return;
    }
    return getNumberOfDays(date);
};

export const linkExpireDate = (date: Date | string) => {
    if (!date) {
        return "";
    }
    const expired = isDateExpired(date);
    if (expired) {
        const currentDate = moment();
        const startDate = moment(date);
        const monthsPassed = currentDate.diff(startDate, "months");
        if (monthsPassed) {
            return `Expired  ${moment(date).fromNow()}`;
        }
        const weeksPassed = currentDate.diff(startDate, "weeks");
        if (weeksPassed) {
            return `Expired ${weeksPassed} week${
                weeksPassed === 1 ? "" : "s"
            } ago`;
        }
        return `Expired ${moment(date).fromNow()}`;
    }
    const numberOfDays = getDaysOrHour(date);
    if (numberOfDays === 0) {
        return `Expires ${moment(date).fromNow()}`;
    }
    if (numberOfDays === 1) {
        return `Expires in ${numberOfDays} day`;
    }
    return `Expires in ${numberOfDays} days`;
};

import { createSlice } from "@reduxjs/toolkit";
import { getContentConstantAction } from "./action/getContentConstantAction";
import { contentConstantSliceInitialState } from "./ContentSliceState";

const SLICE_NAME = "contentConstant";

export const profileSliceName = SLICE_NAME;

const initialState = contentConstantSliceInitialState;

export const contentConstant = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        updatePaymentDetail: (state, action) => {
            state.payment = {
                ...state?.payment,
                ...action?.payload,
            };
        },
        resetPaymentDetail: (state) => {
            state.payment = initialState?.payment;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getContentConstantAction.pending, (state) => {
            state.contentConstantData.loading = true;
            state.contentConstantData.error = null;
        });
        builder.addCase(getContentConstantAction.fulfilled, (state, action) => {
            state.contentConstantData.loading = false;
            state.contentConstantData.error = null;
            state.contentConstantData.data = action?.payload;
        });
        builder.addCase(getContentConstantAction.rejected, (state, action) => {
            state.contentConstantData.loading = false;
            state.contentConstantData.error = action?.error?.message as any;
            state.contentConstantData.data =
                initialState.contentConstantData.data;
        });
    },
});

export const { updatePaymentDetail, resetPaymentDetail } =
    contentConstant.actions;
export const contentConstantReducer = contentConstant.reducer;

import { GOLD_PRICE_TIME } from "../../../constants/AppConstant";

type ContentConstantResponseDataType = {
    SELL: {
        maximum_without_kyc: null | number;
    };
    GOLDIFY: {
        minimum_gift_amount: null | number;
        maximum_gift_amount: null | number;
        categories: {
            default: null | number;
            locked_content: null | number;
            pay_as_you_want: null | number;
        };
        types: {
            lable: null | string;
            value: null | number;
        }[];
    };
    BUY: {
        weight_choices: number[];
    };
    // goldifyLinkCategories: {
    //     default: null | number;
    //     lockedContent: null | number;
    //     payAsYouWant: null | number;
    // };
    // goldifyTransactionStatus: {
    //     Inprocess: null | number;
    //     processed: null | number;
    //     PAYMENT_SUCCESS_WEBHOOK: null | number;
    //     PAYMENT_FAILED_WEBHOOK: null | number;
    // };
    // goldifyLinkStatus: {
    //     active: null | number;
    //     inactive: null | number;
    //     deleted: null | number;
    // };
    // goldifyLinkType: {
    //     music: null | number;
    //     meetup: null | number;
    //     video: null | number;
    //     podcaster: null | number;
    // };
    // assemblyTranscriptionStatus: {
    //     queued: null | number;
    //     processing: null | number;
    //     completed: null | number;
    // };
    // cashfreeTransactionType: {
    //     payout: null | number;
    //     paymentGateway: null | number;
    // };
    // cashfreeVerificationStatus: {
    //     inProcess: null | number;
    //     completed: null | number;
    //     failed: null | number;
    // };
    // notificationStatus: {
    //     success: null | number;
    //     failed: null | number;
    //     InProgress: null | number;
    // };
    // notificationType: {
    //     buy: null | number;
    //     sell: null | number;
    //     kyc: null | number;
    //     earnings: null | number;
    //     contentEngagement: null | number;
    //     payer: null | number;
    // };
    // otpStatus: {
    //     pending: null | number;
    //     failed: null | number;
    //     declined: null | number;
    //     approved: null | number;
    // };
    // genders: {
    //     m: null | string;
    //     f: null | string;
    //     o: null | string;
    // };
    // userType: {
    //     podcaster: null | number;
    //     admin: null | number;
    //     agencyAdmin: null | number;
    //     agencyPodcaster: null | number;
    //     brand: null | number;
    // };
    registrationFailedReason: {
        invalidCountryOrPincode: null | number;
    };
};

type ContentConstantDataType = {
    loading: boolean;
    error: null | string;
    data: null | ContentConstantResponseDataType;
};

type InitialStateType = {
    contentConstantData: ContentConstantDataType;
    payment: {
        name: string;
        amount: string;
        pancard: string;
        mobile: string;
        priceSecond: string;
        priceMinute: string;
        shortId: string;
        title: string;
        weight: string;
        description: string;
        createdAt: string;
        expiredAt: string;
        thumbnail: string;
        minWeight: string;
        transactionCount: string;
        image: string;
        gstRate: string;
        goldWeightPrice: string;
        goldPrice: string;
        gstPrice: string;
        viewCount: number;
        isFreeContent: boolean;
        paymentType: "SUPPORT" | "LOCKED" | "PAY_AS_WANT" | "";
        category: string;
        amountType: string;
    };
};

export const contentConstantSliceInitialState: InitialStateType = {
    contentConstantData: {
        data: null,
        error: null,
        loading: false,
    },
    payment: {
        viewCount: 0,
        gstRate: "",
        thumbnail: "",
        shortId: "",
        name: "",
        amount: "",
        weight: "",
        mobile: "",
        pancard: "",
        priceSecond: "60",
        priceMinute: GOLD_PRICE_TIME?.toString(),
        description: "",
        title: "",
        createdAt: "",
        expiredAt: "",
        minWeight: "",
        transactionCount: "",
        image: "",
        goldWeightPrice: "",
        goldPrice: "",
        gstPrice: "",
        isFreeContent: false,
        paymentType: "",
        category: "",
        amountType: "",
    },
};

export const CheckedIcon = () => {
    /* Render */
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="20" height="20" rx="4" fill="#ECC458" />
            <path
                d="M4 9.73333L8.17391 14L16 6"
                stroke="black"
                strokeWidth="2"
            />
        </svg>
    );
};

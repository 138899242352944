import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getRequest } from "../../../../network/Request";
import { GET_PAYMENT_DETAIL } from "../../../../network/Url";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import { updatePaymentDetail } from "../../../../redux/slices/content";
import { usePaymentRoute } from "../usePaymentRoute";
import { useSession } from "../../../../hooks/useSession";
import { useGetProfileRD } from "../../../Profile/hooks/useGetProfileRD";
import { getContentConstantAction } from "../../../../redux/slices/content/action/getContentConstantAction";
import { getProfileAction } from "../../../../redux/slices/profile/action/getProfileAction";
import { useContentContextRD } from "../useContentContextRD";
import { ERROR } from "../../../../constants/AppConstant";
import { ERROR_404, ERROR_SERVER } from "../../../../constants/AppString";

export const useShortIdPayment = () => {
    /* Hooks */
    const { shortId, username } = useParams();
    const dispatch = useDispatch<AppDispatch>();

    /* States */
    const [linkDetail, setLinkDetail] = useState<any>();

    /* Custom hooks */
    const { paymentLinkHandler } = usePaymentRoute();
    const { profileData } = useGetProfileRD();
    const { goldifyLinkType } = useContentContextRD();

    /* Effects */
    useEffect(() => {
        if (linkDetail && goldifyLinkType && profileData) {
            paymentLinkHandler({
                amount: linkDetail?.amount,
                categoryId: linkDetail?.category,
                createdAt: linkDetail?.createdAt,
                description: linkDetail?.description,
                expiredAt: linkDetail?.expiryAt,
                image: linkDetail?.image,
                shortId: linkDetail?.shortId,
                thumbnail: linkDetail?.image,
                title: linkDetail?.title,
                transactionCount: linkDetail?.transactionCount,
                viewCount: linkDetail?.viewCount,
            });
        }
    }, [linkDetail, goldifyLinkType, profileData]);

    const getPaymentDetail = async () => {
        try {
            const res: any = await getRequest({
                url: GET_PAYMENT_DETAIL + "/" + shortId,
            });
            const data = res?.data;
            if (data) {
                if (!profileData?.creatorInfo?.username && username) {
                    dispatch(getProfileAction({ username: username }));
                }
                if (!goldifyLinkType) {
                    dispatch(getContentConstantAction());
                }
                setLinkDetail(data);
                return;
            }
            setLinkDetail(ERROR_404);
        } catch {
            setLinkDetail(ERROR_SERVER);
        }
    };

    return { getPaymentDetail, linkDetail };
};

import { ResponsiveLayout } from "../../../../components/ResponsiveLayout";
import { useScreenDimension } from "../../../../hooks/useScreenDimension";
import { CircularProgress, Grid } from "@mui/material";
import { PayBackIcon } from "../../components/PayBackIcon";
import { useAppNavigation } from "../../../../hooks/useAppNavigation";
import {
    CreatorPayHeart,
    ExpiredImage,
    LinkMoibleBgImage,
    NoVideo,
    PaymentLinkSuccessIcon,
    SuccessPaymentIcon,
} from "../../../../utils/Image";
import { AppText } from "../../../../components/AppText";
import { AppButton } from "../../../../components/AppButton";
import {
    CREATOR_MSG,
    DOWNLOAD_INVOICE,
    GO_BACK,
    GO_BACK_PROFILE,
    LINK_EXPIRED_MSG,
    VIEW_USAGE_GUIDELINE,
} from "../../../../constants/AppString";
import { UnlockedContent } from "../../components/UnloackedContent";
import { ContentBackIcon } from "../../components/ContentBackIcon";
import { HorizontalLine } from "../../../../components/HorizontalLine";
import { SuccessPayment } from "../../../../assets/testing/SuccessPayment";
import { WebPayBackground } from "../../components/WebPayBackground";
import { LockedContentWeb } from "../../components/LockedContentWeb";
import { TextWithLink } from "../../../../components/TextWithLink";
import { usePaymentComplete } from "../../hooks/usePaymentComplete";
import { LockIcon } from "../../../../assets/icons/LockIcon";
import { UnLockIcon } from "../../../../assets/icons/UnLockIcon";
import { useGetProfileRD } from "../../../Profile/hooks/useGetProfileRD";
import { useUserProfileRoute } from "../../../Profile/hooks/useUserProfileRoute";
import { SupportTheCreatorThanks } from "../../components/SupportTheCreatorThanks";
import { LockImage } from "../../../../assets/images/LockImage";
import { usePaymentRD } from "../../hooks/usePaymentRD";
import { ColumnGrid } from "../../../../components/ColumnGrid";
import { AppImage } from "../../../../components/AppImage";
import { usePaymentRoute } from "../../hooks/usePaymentRoute";
import { LockedContentSymbol } from "../../../../assets/images/LockedContentSymbol";
import { SearchEngineType } from "../../../../components/SearchEngine";

export const ExpiredLinkPage = () => {
    /* Custom Hooks */
    const { browserHeight, browserWidth } = useScreenDimension();
    const { resetPayment, usageGuideLineHandler } = usePaymentRoute();
    const { title, description, image, category, thumbnail } = usePaymentRD();

    /* Render */

    return (
        <>
            <SearchEngineType
                title={title}
                description={description}
                image={thumbnail}
            />
            <ResponsiveLayout
                mobile={
                    <Grid
                        container
                        height={browserHeight}
                        width={browserWidth}
                        bgcolor="#0B0B0C"
                        sx={{
                            backgroundImage: `url(${LinkMoibleBgImage})`,
                            backgroundSize: "cover",
                        }}
                    >
                        <Grid item position="relative" paddingX={2} flex={1}>
                            <Grid
                                container
                                paddingTop={2}
                                height="100%"
                                direction="column"
                            >
                                <Grid item onClick={resetPayment}>
                                    <Grid container>
                                        <Grid item>
                                            <PayBackIcon />
                                        </Grid>
                                        <Grid item></Grid>
                                    </Grid>
                                </Grid>

                                <Grid item flex={1}>
                                    <Grid
                                        container
                                        height="100%"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Grid item>
                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                <Grid item>
                                                    <Grid
                                                        container
                                                        columnGap={2}
                                                        wrap="nowrap"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        width={200}
                                                    >
                                                        <Grid item>
                                                            <img
                                                                src={
                                                                    ExpiredImage
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <AppText
                                                                children={
                                                                    LINK_EXPIRED_MSG
                                                                }
                                                                fontSize={18}
                                                                fontWeight="700"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    item
                                                    width={150}
                                                    paddingY={4}
                                                >
                                                    <HorizontalLine />
                                                </Grid>
                                                <Grid
                                                    item
                                                    width={300}
                                                    height={150}
                                                    borderRadius={2}
                                                    position="relative"
                                                >
                                                    <AppImage
                                                        src={
                                                            image
                                                                ? image
                                                                : NoVideo
                                                        }
                                                        objectFit="contain"
                                                    />
                                                    {category === "2" &&
                                                        image && (
                                                            <Grid
                                                                item
                                                                position="absolute"
                                                                bottom={0}
                                                                top={0}
                                                                left={0}
                                                                right={0}
                                                                style={{
                                                                    backdropFilter:
                                                                        "blur(2px)",
                                                                    "-webkit-backdrop-filter":
                                                                        "blur(2px)",
                                                                }}
                                                            >
                                                                <Grid
                                                                    container
                                                                    height="100%"
                                                                    alignItems="center"
                                                                    justifyContent="center"
                                                                    paddingLeft={
                                                                        1
                                                                    }
                                                                >
                                                                    <Grid item>
                                                                        <LockedContentSymbol />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        )}
                                                </Grid>
                                                <Grid item paddingTop={2}>
                                                    <AppText
                                                        children={title}
                                                        fontWeight="700"
                                                        fontSize={14}
                                                        textAlign="center"
                                                    />
                                                </Grid>

                                                <Grid item paddingTop={1}>
                                                    <AppText
                                                        children={description}
                                                        color="#ffffff60"
                                                        fontSize={12}
                                                        textAlign="center"
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                container
                                                justifyContent="center"
                                                alignItems="center"
                                                paddingTop={8}
                                                direction="column"
                                            >
                                                <Grid
                                                    item
                                                    width={250}
                                                    paddingTop={2}
                                                >
                                                    <AppButton
                                                        outlineButton
                                                        onClick={resetPayment}
                                                    >
                                                        <Grid
                                                            container
                                                            height="100%"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                        >
                                                            <Grid item>
                                                                <AppText
                                                                    children={
                                                                        GO_BACK
                                                                    }
                                                                    fontWeight="600"
                                                                    sx={{
                                                                        background:
                                                                            "-webkit-linear-gradient(#F8DC71, #CC8718);",
                                                                        "-webkit-background-clip":
                                                                            "text",
                                                                        "-webkit-text-fill-color":
                                                                            "transparent",
                                                                    }}
                                                                    fontSize={
                                                                        16
                                                                    }
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </AppButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                web={
                    <WebPayBackground backHandler={resetPayment}>
                        <>
                            <LockedContentWeb />
                            <Grid item md={6}>
                                <Grid
                                    container
                                    height="100%"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    direction="column"
                                    paddingX={10.5}
                                    paddingY={8}
                                >
                                    <Grid item flex={1}>
                                        <Grid
                                            container
                                            height="100%"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Grid item>
                                                <ColumnGrid alignItems="center">
                                                    <Grid
                                                        item
                                                        height={100}
                                                        width={100}
                                                    >
                                                        <img
                                                            src={ExpiredImage}
                                                            height="100%"
                                                        />
                                                    </Grid>
                                                    <Grid item paddingTop={2}>
                                                        <AppText
                                                            children={
                                                                LINK_EXPIRED_MSG
                                                            }
                                                            textAlign="center"
                                                            fontSize={24}
                                                            fontWeight="700"
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        width={250}
                                                        paddingTop={10}
                                                    >
                                                        <AppButton
                                                            outlineButton
                                                            showShadow={false}
                                                            onClick={
                                                                resetPayment
                                                            }
                                                        >
                                                            <Grid
                                                                container
                                                                height="100%"
                                                                justifyContent="center"
                                                                alignItems="center"
                                                                sx={{
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                <Grid item>
                                                                    <AppText
                                                                        children={
                                                                            GO_BACK
                                                                        }
                                                                        fontWeight="600"
                                                                        sx={{
                                                                            background:
                                                                                "-webkit-linear-gradient(#F8DC71, #CC8718);",
                                                                            "-webkit-background-clip":
                                                                                "text",
                                                                            "-webkit-text-fill-color":
                                                                                "transparent",
                                                                        }}
                                                                        fontSize={
                                                                            16
                                                                        }
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </AppButton>
                                                    </Grid>
                                                </ColumnGrid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <TextWithLink
                                            title=""
                                            subTitle={VIEW_USAGE_GUIDELINE}
                                            subTitleHandler={
                                                usageGuideLineHandler
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    </WebPayBackground>
                }
            />
        </>
    );
};

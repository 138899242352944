import React from "react";

export const ReditIcon = () => {
    return (
        <svg
            width={56}
            height={56}
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M28 56c15.464 0 28 -12.536 28 -28S43.464 0 28 0 0 12.536 0 28s12.536 28 28 28Z"
                fill="#FF4500"
            />
            <path
                d="M42.736 28.196a3.234 3.234 0 0 0 -5.466 -2.209 15.73 15.73 0 0 0 -8.534 -2.73l1.436 -6.902 4.746 0.997a2.212 2.212 0 1 0 0.291 -1.355l-5.424 -1.084a0.686 0.686 0 0 0 -0.818 0.524l-1.632 7.689a15.87 15.87 0 0 0 -8.632 2.722 3.231 3.231 0 0 0 -4.421 4.71c0.255 0.244 0.549 0.44 0.868 0.582a7.196 7.196 0 0 0 0 0.974c0 4.956 5.776 8.988 12.897 8.988 7.123 0 12.894 -4.026 12.894 -8.988a7.188 7.188 0 0 0 0 -0.974 3.228 3.228 0 0 0 1.795 -2.94Zm-22.126 2.212a2.212 2.212 0 1 1 0 0.006v-0.006Zm12.852 6.09c-1.568 1.184 -3.5 1.786 -5.46 1.708a8.501 8.501 0 0 1 -5.471 -1.708 0.596 0.596 0 0 1 0.84 -0.843 7.235 7.235 0 0 0 4.606 1.392 7.277 7.277 0 0 0 4.62 -1.347 0.63 0.63 0 0 1 0.876 0 0.518 0.518 0 0 1 -0.011 0.798Zm-2.612 -6.009a2.212 2.212 0 1 1 4.424 0c0.056 1.224 -0.644 2.299 -2.117 2.299a2.209 2.209 0 0 1 -2.307 -2.296Z"
                fill="white"
            />
        </svg>
    );
};

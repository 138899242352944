import { createSlice } from "@reduxjs/toolkit";
import { getProfileAction } from "./action/getProfileAction";
import { profileSliceInitialState } from "./ProfileSliceState";

const SLICE_NAME = "profile";

export const profileSliceName = SLICE_NAME;

const initialState = profileSliceInitialState;

export const profileSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        updateGoldifyLinksCount: (state, action) => {
            const { shortId, viewCount } = action?.payload;
            state.profileData.data!.goldifyLinks?.find((item) => {
                if (shortId === item?.shortId) {
                    item.views = viewCount;
                    return true;
                }
                return false;
            });
            state.profileData.data!.trendingLinks?.find((item) => {
                if (shortId === item?.shortId) {
                    item.views = viewCount;
                    return true;
                }
                return false;
            });
        },
    },
    extraReducers: (builder) => {
        // Profile
        builder.addCase(getProfileAction.pending, (state) => {
            state.profileData.loading = true;
            state.profileData.error = null;
        });
        builder.addCase(getProfileAction.fulfilled, (state, action) => {
            state.profileData.loading = false;
            state.profileData.error = null;
            state.profileData.data = action?.payload;
        });
        builder.addCase(getProfileAction.rejected, (state, action) => {
            state.profileData.loading = false;
            state.profileData.error = action?.error?.message as any;
            state.profileData.data = initialState.profileData.data;
        });
    },
});

export const profileReducer = profileSlice.reducer;
export const { updateGoldifyLinksCount } = profileSlice.actions;

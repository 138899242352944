import { Grid } from "@mui/material";
import { AppText } from "../../../../components/AppText";
import {
    CreatorPayHeart,
    LockThumbnailImage,
    NoVideo,
    PayAsYouWantIcon,
    PaymentLinkSuccessIcon,
    RedCloseIcon,
    SuccessPaymentIcon,
} from "../../../../utils/Image";
import {
    CREATOR_MSG,
    DOWNLOAD_INVOICE,
    LINK_PAYMENT_SUCCESS,
    LINK_PAYMENT_SUCCESS_WEB,
    PAYMENT_FAILED,
    PAYMENT_SUCESS,
    RETRY,
    THANKS_FOR_CREATOR,
    VIEW_USAGE_GUIDELINE,
} from "../../../../constants/AppString";
import { HorizontalLine } from "../../../../components/HorizontalLine";
import { TextWithLink } from "../../../../components/TextWithLink";
import { AppButton } from "../../../../components/AppButton";
import { usePaymentRoute } from "../../hooks/usePaymentRoute";

type SupportTheCreatorThanksType = {
    profileRouteHandler?: any;
    paymentDetail?: any;
    openInvoice?: any;
    failedPayment?: any;
    paymentType?: any;
    linkCategory: string;
    isFreeContent: boolean;
    usageGuideLineHandler?: any;
    username?: any;
};

export const SupportTheCreatorThanks = (props: SupportTheCreatorThanksType) => {
    /* Props */
    const {
        profileRouteHandler,
        paymentDetail,
        openInvoice,
        failedPayment = true,
        paymentType,
        linkCategory,
        isFreeContent,
        usageGuideLineHandler,
        username,
    } = props;

    /* Custom hooks */
    const { paymentRetryHandler } = usePaymentRoute();

    /* Render */

    const isSupporter = linkCategory === "1";

    return (
        <>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item paddingTop={2}>
                    <img
                        src={
                            failedPayment
                                ? RedCloseIcon
                                : isSupporter
                                ? SuccessPaymentIcon
                                : PaymentLinkSuccessIcon
                        }
                        height={80}
                    />
                </Grid>
                <Grid item paddingTop={2}>
                    <Grid
                        container
                        paddingX={4}
                        columnGap={2}
                        wrap="nowrap"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item>
                            <AppText
                                children={
                                    failedPayment
                                        ? PAYMENT_FAILED
                                        : isSupporter
                                        ? PAYMENT_SUCESS
                                        : LINK_PAYMENT_SUCCESS
                                }
                                fontSize={24}
                                fontWeight="700"
                                textAlign="center"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {!isSupporter && !failedPayment && (
                    <Grid item xs={12}>
                        <AppText
                            color="#ffffff60"
                            children={LINK_PAYMENT_SUCCESS_WEB}
                            fontWeight="700"
                            fontSize={14}
                            textAlign="center"
                        />
                    </Grid>
                )}
                {!failedPayment && (
                    <Grid item width={200} paddingY={2}>
                        <HorizontalLine />
                    </Grid>
                )}
                <Grid item padding={2}>
                    {isSupporter ? (
                        failedPayment ? null : (
                            <img src={CreatorPayHeart} />
                        )
                    ) : (
                        <img
                            src={
                                paymentDetail?.image
                                    ? paymentDetail?.image
                                    : linkCategory === "3"
                                    ? PayAsYouWantIcon
                                    : LockThumbnailImage
                            }
                            alt=""
                            width={300}
                            height={150}
                            style={{
                                objectFit: "contain",
                                borderRadius: 10,
                                resize: "inline",
                            }}
                        />
                    )}
                </Grid>
                <Grid item>
                    {isSupporter ? (
                        failedPayment ? null : (
                            <AppText
                                children={THANKS_FOR_CREATOR}
                                fontWeight="700"
                                fontSize={22}
                                textAlign="center"
                            />
                        )
                    ) : (
                        <AppText
                            children={paymentDetail?.title}
                            fontWeight="700"
                            fontSize={18}
                            textAlign="center"
                        />
                    )}
                </Grid>

                <Grid item paddingTop={1} paddingX={2}>
                    {isSupporter ? (
                        failedPayment ? null : (
                            <AppText
                                children={CREATOR_MSG}
                                color="#ffffff60"
                                fontSize={20}
                                textAlign="center"
                            />
                        )
                    ) : (
                        <AppText
                            children={paymentDetail?.description}
                            fontWeight="700"
                            fontSize={12}
                            color="#ffffff60"
                            textAlign="center"
                        />
                    )}
                </Grid>
            </Grid>
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                paddingTop={4}
                direction="column"
            >
                <Grid item xs={12}>
                    <TextWithLink
                        title=""
                        subTitle={VIEW_USAGE_GUIDELINE}
                        subTitleHandler={usageGuideLineHandler}
                    />
                </Grid>
                {isFreeContent ? null : failedPayment ? (
                    <Grid
                        item
                        width={250}
                        paddingTop={4}
                        sx={{
                            cursor: "pointer",
                        }}
                    >
                        <AppButton
                            outlineButton
                            onClick={() =>
                                paymentRetryHandler({
                                    shortId: paymentDetail?.shortId,
                                    username,
                                })
                            }
                            showShadow={false}
                        >
                            <Grid
                                container
                                height="100%"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Grid item>
                                    <AppText
                                        children={RETRY}
                                        fontWeight="600"
                                        sx={{
                                            background:
                                                "-webkit-linear-gradient(#F8DC71, #CC8718);",
                                            WebkitBackgroundClip: "text",
                                            WebkitTextFillColor: "transparent",
                                        }}
                                        fontSize={16}
                                    />
                                </Grid>
                            </Grid>
                        </AppButton>
                    </Grid>
                ) : (
                    <Grid
                        item
                        width={250}
                        paddingTop={4}
                        paddingBottom={8}
                        sx={{
                            cursor: "pointer",
                        }}
                    >
                        <AppButton outlineButton onClick={openInvoice}>
                            <Grid
                                container
                                height="100%"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Grid item>
                                    <AppText
                                        children={DOWNLOAD_INVOICE}
                                        fontWeight="600"
                                        sx={{
                                            background:
                                                "-webkit-linear-gradient(#F8DC71, #CC8718);",
                                            "-webkit-background-clip": "text",
                                            "-webkit-text-fill-color":
                                                "transparent",
                                        }}
                                        fontSize={16}
                                    />
                                </Grid>
                            </Grid>
                        </AppButton>
                    </Grid>
                )}
            </Grid>
        </>
    );
};

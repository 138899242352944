import React, { useEffect } from "react";
import { PageContainer } from "../../../components/PageContainer";
import { FullSpaceGrid } from "../../../components/FullSpaceGrid";
import { CircularProgress, Grid } from "@mui/material";
import { ResponsiveLayout } from "../../../components/ResponsiveLayout";
import { MAIN_WEB_URL } from "../../../constants/AppConstant";

export const NoUserNamePage = () => {
    /* Effects */
    useEffect(() => {
        window.location.replace(MAIN_WEB_URL);
    }, []);

    /* Render */
    const LOADER = (
        <PageContainer>
            <FullSpaceGrid
                justifyContent="center"
                alignItems="center"
                height="100%"
                bgColor={"#0B0B0C"}
            >
                <Grid item>
                    <CircularProgress size={24} />
                </Grid>
            </FullSpaceGrid>
        </PageContainer>
    );

    return <ResponsiveLayout mobile={LOADER} web={LOADER} />;
};

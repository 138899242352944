import * as React from "react";

export const FireIcon = () => {
    return (
        <svg
            width={40}
            height={44}
            viewBox="0 0 40 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_d_518_12533)">
                <path
                    d="M19.2031 10.416C20.7944 11.4176 22.1577 12.7423 23.2046 14.3043C24.2514 15.8663 24.9586 17.6307 25.2802 19.4833C25.2802 19.4833 25.9703 19.143 26.4341 17.5572C26.4518 17.4973 26.4862 17.4437 26.5332 17.4025C26.5802 17.3614 26.6379 17.3345 26.6996 17.3248C26.7613 17.3152 26.8245 17.3232 26.8818 17.3481C26.9391 17.3729 26.9882 17.4135 27.0233 17.4652C28.1612 19.1985 28.7778 21.2219 28.8002 23.2952C28.8002 27.9001 24.8604 31.6332 20.0002 31.6332C15.14 31.6332 11.2002 27.9001 11.2002 23.2952C11.2156 21.5422 11.5744 19.8093 12.2566 18.1945C12.2814 18.1355 12.3239 18.0858 12.3784 18.0522C12.4328 18.0186 12.4963 18.0028 12.5601 18.0071C12.6239 18.0113 12.6848 18.0353 12.7343 18.0758C12.7838 18.1163 12.8195 18.1713 12.8363 18.233C13.0189 18.8695 13.3903 19.6523 14.1335 19.8998C14.1335 19.8973 14.1376 17.6958 16.3335 15.4998C17.8002 14.0332 18.5588 13.3787 18.6685 10.7137C18.6703 10.6521 18.6882 10.592 18.7202 10.5393C18.7523 10.4867 18.7974 10.4432 18.8513 10.4132C18.9052 10.3832 18.9659 10.3677 19.0275 10.3682C19.0892 10.3687 19.1497 10.3851 19.2031 10.416Z"
                    fill="#FFDF43"
                />
                <path
                    d="M24.6065 26.7177C23.4606 28.1372 21.9302 29.1967 20.1981 29.7694C20.0692 29.8101 19.9309 29.8101 19.8021 29.7694C18.07 29.1967 16.5395 28.1372 15.3936 26.7177C14.3028 25.2818 14.1055 22.7771 15.3936 21.5337C17.0895 19.8918 19.3261 20.6842 20.0001 22.3961C20.674 20.6842 22.9107 19.8918 24.6065 21.5337C25.8946 22.7771 25.6973 25.2818 24.6065 26.7177Z"
                    fill="#F3A13B"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_518_12533"
                    x={-2}
                    y={0}
                    width={44}
                    height={44}
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy={1} />
                    <feGaussianBlur stdDeviation={5.5} />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 1 0 0 0 0 0.87451 0 0 0 0 0.262745 0 0 0 0.59 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_518_12533"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_518_12533"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
};

import { Grid } from "@mui/material";
import { ChildrenType, DefaultLayoutType } from "../../types/ObjectType";

export type ColumnGridType = {
    children: ChildrenType;
    justifyContent?: "space-between" | "flex-end" | "center";
    alignItems?: "center" | "flex-end";
    rowGap?: number;
    columnGap?: number;
    position?: any;
} & DefaultLayoutType;

export const ColumnGrid = (props: ColumnGridType) => {
    /* Props */
    const {
        children,
        paddingX,
        paddingY,
        border,
        height,
        justifyContent,
        rowGap,
        columnGap,
        width,
        minWidth,
        minHeight,
        bgColor,
        position,
        borderColor,
        borderRadius,
        paddingBottom,
        alignItems,
        background,
        padding,
    } = props;

    /* Render */
    return (
        <Grid
            container
            bgcolor={bgColor}
            direction="column"
            paddingX={paddingX}
            borderRadius={borderRadius}
            paddingY={paddingY}
            border={border}
            height={height}
            width={width}
            minHeight={minHeight}
            minWidth={minWidth}
            rowGap={rowGap}
            columnGap={columnGap}
            position={position}
            borderColor={borderColor}
            justifyContent={justifyContent}
            paddingBottom={paddingBottom}
            alignItems={alignItems}
            padding={padding}
            sx={{
                background,
            }}
        >
            {children}
        </Grid>
    );
};

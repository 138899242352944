import {
    EXPIRED_RN,
    LOCKED_CONTENT_FIXED_ROUTE_NAME,
    LOCKED_CONTENT_ROUTE_NAME,
    PAYMENT_DETAIL_ROUTE_NAME,
    SUPPORT_CREATOR_ROUTE_NAME,
    SUPPORT_CREATOR_THANKS_ROUTE_NAME,
    SUPPORT_CREATOR_USER_DETAIL_ROUTE_NAME,
} from "../../../../constants/RouteConstant";
import { useAppNavigation } from "../../../../hooks/useAppNavigation";
import { AppDispatch } from "../../../../redux/store";
import { useDispatch } from "react-redux";
import { HOST_URL } from "../../../../network/Url";
import { useGetProfileRD } from "../../../Profile/hooks/useGetProfileRD";
import { usePaymentRD } from "../usePaymentRD";
import { isDateExpired } from "../../../../utils/DateHelper";
import {
    resetPaymentDetail,
    updatePaymentDetail,
} from "../../../../redux/slices/content";
import { LOCKED } from "../../../../constants/AppConstant";
import { useUserProfileRoute } from "../../../Profile/hooks/useUserProfileRoute";

export const usePaymentRoute = () => {
    /* Hooks */
    const dispatch = useDispatch<AppDispatch>();

    /* Custom Hooks */
    const { navigate } = useAppNavigation();
    const { profileData } = useGetProfileRD();
    const { shortId } = usePaymentRD();
    const { goBackHandler } = useAppNavigation();
    const { profileRouteHandler } = useUserProfileRoute();

    /* Handler */
    const paymentDetailHandler = () => {
        navigate(
            `/${profileData?.creatorInfo?.username}/${shortId}${PAYMENT_DETAIL_ROUTE_NAME}`,
            {
                state: {
                    routeName: SUPPORT_CREATOR_THANKS_ROUTE_NAME,
                },
            }
        );
    };

    const supportUserPaymentDetailHandler = () => {
        navigate(
            `/${profileData?.creatorInfo?.username}/${shortId}${SUPPORT_CREATOR_USER_DETAIL_ROUTE_NAME}`,
            {
                state: {
                    routeName: SUPPORT_CREATOR_THANKS_ROUTE_NAME,
                },
            }
        );
    };

    const usageGuideLineHandler = () => {
        window?.open("https://yourgold.app/usage-guidelines", "_self");
    };

    const paymentLinkHandler = async ({
        index,
        categoryId,
        shortId,
        title,
        description,
        amount,
        createdAt,
        expiredAt,
        thumbnail,
        transactionCount,
        image,
        viewCount,
    }: {
        index?: number;
        categoryId: string;
        shortId: string;
        description: string;
        title: string;
        amount: number;
        createdAt: string;
        expiredAt: string;
        thumbnail: string;
        transactionCount: string;
        image: string;
        viewCount?: number;
    }) => {
        if (isDateExpired(expiredAt)) {
            dispatch(
                updatePaymentDetail({
                    shortId,
                    title,
                    description,
                    weight: amount,
                    createdAt,
                    expiredAt,
                    thumbnail,
                    transactionCount,
                    image,
                    viewCount,
                    category: categoryId?.toString(),
                })
            );
            window.location.replace(EXPIRED_RN);
            return;
        }
        if (categoryId?.toString() === "1") {
            dispatch(
                updatePaymentDetail({
                    shortId,
                    title,
                    description,
                    weight: amount,
                    createdAt,
                    expiredAt,
                    thumbnail,
                    transactionCount,
                    image,
                    viewCount,
                    category: categoryId?.toString(),
                })
            );
            window.location.replace(
                `/${profileData?.creatorInfo?.username}/${shortId}${SUPPORT_CREATOR_ROUTE_NAME}`
            );
            return;
        }
        if (categoryId?.toString() === "2") {
            dispatch(
                updatePaymentDetail({
                    shortId,
                    title,
                    description,
                    weight: amount,
                    createdAt,
                    expiredAt,
                    thumbnail,
                    transactionCount,
                    image,
                    viewCount,
                    paymentType: LOCKED,
                    category: categoryId?.toString(),
                })
            );
            window.location.replace(
                `/${profileData?.creatorInfo?.username}/${shortId}${LOCKED_CONTENT_FIXED_ROUTE_NAME}`
            );
            return;
        }
        if (categoryId?.toString() === "3") {
            dispatch(
                updatePaymentDetail({
                    shortId,
                    title,
                    description,
                    weight: amount,
                    minWeight: amount ? amount : "",
                    createdAt,
                    expiredAt,
                    thumbnail,
                    transactionCount,
                    image,
                    viewCount,
                    category: categoryId?.toString(),
                })
            );
            window.location.replace(
                `/${profileData?.creatorInfo?.username}/${shortId}${LOCKED_CONTENT_ROUTE_NAME}`
            );
            return;
        }
    };

    const resetPayment = () => {
        if (profileData?.creatorInfo?.username) {
            dispatch(resetPaymentDetail());
            profileRouteHandler({
                username: profileData?.creatorInfo?.username,
            });
        }
    };

    const supportUserPaymentThanksHandler = ({
        shortId,
        orderId,
        username,
        isFreeConent,
    }: {
        orderId: string;
        shortId: string;
        username: string;
        isFreeConent?: boolean;
    }) => {
        const url = `${HOST_URL}${SUPPORT_CREATOR_THANKS_ROUTE_NAME}/?order_id=${orderId}&shortId=${shortId}&creator_username=${username}&isFreeConent=${isFreeConent}`;
        window.open(url, "_self");
    };

    const paymentRetryHandler = ({
        shortId,
        username,
    }: {
        shortId: string;
        username: string;
    }) => {
        const url = `${HOST_URL}/${username}/${shortId}`;
        window.open(url, "_self");
    };

    const paymentGeneralLinkHandler = ({ shortId }: { shortId: string }) => {
        navigate(`/payment/${shortId}`);
    };

    return {
        paymentGeneralLinkHandler,
        paymentDetailHandler,
        supportUserPaymentDetailHandler,
        supportUserPaymentThanksHandler,
        paymentLinkHandler,
        resetPayment,
        paymentRetryHandler,
        usageGuideLineHandler,
    };
};

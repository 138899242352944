import { Grid } from "@mui/material";
import { UnCheckedIcon } from "../../../../assets/icons/UnCheckedIcon";
import { AppText } from "../../../../components/AppText";
import { CheckedIcon } from "../../../../assets/icons/CheckedIcon";
import {
    WHATSAPP_MESSAGE,
    WHATSAPP_TITLE,
} from "../../../../constants/AppString";
import { AppImage } from "../../../../components/AppImage";
import { WhatsAppIcon } from "../../../../utils/Image";

type WhatsAppNotificationType = {
    checked: boolean;
    title: string;
    subTitle?: string;
    titleColor?: string;
    checkHandler?: () => void;
    linkHandler?: () => void;
};

export const WhatsAppNotification = (props: WhatsAppNotificationType) => {
    /* Props */
    const {
        checked,
        subTitle,
        title,
        titleColor = "#FFFFFF",
        checkHandler,
        linkHandler,
    } = props;

    /* Render */
    return (
        <Grid container>
            <Grid
                item
                onClick={checkHandler}
                sx={{ cursor: "pointer" }}
                position="relative"
                bottom={-5}
            >
                {checked ? <CheckedIcon /> : <UnCheckedIcon />}
            </Grid>
            <Grid item marginLeft={1} flex={1}>
                <Grid container alignItems="center">
                    <Grid item sm={12}>
                        <Grid container>
                            <Grid item>
                                <AppText
                                    children={WHATSAPP_TITLE}
                                    color={titleColor}
                                    fontWeight={500}
                                    fontSize={16}
                                />
                            </Grid>
                            <Grid item width={24} marginLeft={0.5}>
                                <AppImage
                                    src={WhatsAppIcon}
                                    objectFit="contain"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <AppText
                            children={WHATSAPP_MESSAGE}
                            color="#FFFFFF"
                            fontWeight={500}
                            fontSize={12}
                            onClick={linkHandler}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

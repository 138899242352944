import React from "react";

export const PInterest = () => {
    return (
        <svg
            width={56}
            height={56}
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 28c0 15.464 12.536 28 28 28s28 -12.536 28 -28S43.464 0 28 0 0 12.536 0 28Z"
                fill="#E60023"
            />
            <path
                d="M28 14c-7.7 0 -14 6.3 -14 14a13.888 13.888 0 0 0 8.4 12.776c0 -1.053 0 -2.1 0.176 -3.153 0.347 -1.224 1.747 -7.7 1.747 -7.7s-0.524 -0.874 -0.524 -2.274c0 -2.1 1.226 -3.676 2.626 -3.676 1.224 0 1.924 0.876 1.924 2.1 0 1.226 -0.876 3.153 -1.226 4.9 -0.347 1.4 0.7 2.626 2.276 2.626 2.626 0 4.376 -3.326 4.376 -7.526 0 -3.147 -2.1 -5.424 -5.776 -5.424 -4.2 0 -6.826 3.15 -6.826 6.65 0 1.226 0.35 2.1 0.876 2.8 0.176 0.35 0.35 0.35 0.176 0.7 0 0.176 -0.176 0.876 -0.35 1.05 -0.176 0.35 -0.35 0.526 -0.7 0.35 -1.926 -0.876 -2.8 -2.976 -2.8 -5.426 0 -4.024 3.324 -8.747 9.974 -8.747 5.426 0 8.926 3.85 8.926 8.05 0 5.424 -2.976 9.624 -7.526 9.624 -1.576 0 -2.976 -0.876 -3.5 -1.75 0 0 -0.876 3.15 -1.05 3.85a15.512 15.512 0 0 1 -1.4 2.976c1.226 0.347 2.626 0.524 4.026 0.524 7.7 0 14 -6.3 14 -14C42 20.3 35.7 14 28 14Z"
                fill="white"
            />
        </svg>
    );
};

type ProfileResponseDataType = {
    creatorInfo: {
        firstName: "";
        lastName: "";
        bio: "";
        username: "";
        profileImg: "";
        _id: "";
        defaultLinkData: {
            shortId: "";
            transactionCount: "";
            _id: "";
        };
        socialLinks: {
            title: "";
            link: "";
        }[];
    };
    trendingLinks: {
        _id: "";
        category: "";
        status: null;
        amount: "";
        views: null;
        shortId: "";
        expiryAt: "";

        description: "";
        createdAt: "";
        title: "";
        transactionCount: "";
        image: "";
    }[];
    goldifyLinks: {
        _id: "";
        category: null;
        status: null;
        amount: null;
        views: null;
        shortId: "";
        expiryAt: "";
        description: "";
        createdAt: "";
        title: "";
        transactionCount: "";
        type: number[];
        image: "";
    }[];
};

type ProfileDataType = {
    loading: boolean;
    error: null | string;
    data: null | ProfileResponseDataType;
};

type InitialStateType = {
    profileData: ProfileDataType;
};

export const profileSliceInitialState: InitialStateType = {
    profileData: {
        data: null,
        error: null,
        loading: false,
    },
};

import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

export const useGetProfileRD = () => {
    /* Hooks */
    const profileData = useSelector(
        (state: RootState) => state?.profile?.profileData?.data
    );
    const profileloading = useSelector(
        (state: RootState) => state?.profile?.profileData?.loading
    );
    const profileError: any = useSelector(
        (state: RootState) => state?.profile?.profileData?.error
    );

    return { profileData, profileloading, profileError };
};

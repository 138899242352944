import * as React from "react";
export const LinkIcon = () => (
    <svg width={12} height={12} viewBox="0 0 12 12" fill="none">
        <g clipPath="url(#clip0_518_12518)">
            <path
                d="M6.45898 8.29488L4.62293 10.131C4.62293 10.131 4.62293 10.131 4.62288 10.131C4.62288 10.131 4.62288 10.1311 4.62284 10.1311C3.86357 10.8904 2.62808 10.8904 1.86873 10.1311C1.50087 9.76321 1.29833 9.27413 1.29833 8.75397C1.29833 8.23386 1.50087 7.74487 1.8686 7.37701C1.86865 7.37696 1.86869 7.37692 1.86873 7.37688L3.70479 5.54078C3.95827 5.28725 3.95827 4.8762 3.70475 4.62273C3.45127 4.36925 3.04022 4.36925 2.7867 4.62273L0.950638 6.45883C0.950508 6.45896 0.950379 6.45913 0.950249 6.45926C0.33748 7.07224 0 7.88721 0 8.75397C0 9.62095 0.337609 10.436 0.950682 11.0491C1.58349 11.6819 2.41464 11.9983 3.24583 11.9983C4.07702 11.9983 4.90821 11.6819 5.54093 11.0491C5.54098 11.0491 5.54098 11.049 5.54098 11.049L7.37703 9.21293C7.63051 8.95946 7.63051 8.5484 7.37699 8.29488C7.12356 8.04141 6.71255 8.04141 6.45898 8.29488Z"
                fill="white"
            />
            <path
                d="M11.9999 3.24574C11.9999 2.37876 11.6623 1.56367 11.0492 0.950594C9.78362 -0.314933 7.72443 -0.31489 6.45894 0.950594C6.4589 0.95068 6.45881 0.950724 6.45877 0.95081L4.62276 2.78678C4.36924 3.04026 4.36924 3.45135 4.62276 3.70483C4.74956 3.83163 4.91566 3.89499 5.0818 3.89499C5.2479 3.89499 5.41409 3.83159 5.54081 3.70483L7.37682 1.86886C7.37686 1.86877 7.37695 1.86873 7.37704 1.86864C8.1363 1.10938 9.37179 1.10934 10.1311 1.86864C10.499 2.2365 10.7016 2.72559 10.7016 3.24574C10.7016 3.76585 10.499 4.25485 10.1313 4.62271L10.1311 4.62284L8.29509 6.45894C8.04161 6.71242 8.04161 7.12347 8.29513 7.37699C8.42189 7.50375 8.58803 7.56715 8.75413 7.56715C8.92028 7.56715 9.08642 7.50375 9.21318 7.37699L11.0492 5.54089C11.0494 5.54076 11.0495 5.54058 11.0496 5.54045C11.6624 4.92747 11.9999 4.11251 11.9999 3.24574Z"
                fill="white"
            />
            <path
                d="M3.70479 8.29501C3.83155 8.42177 3.99769 8.48517 4.16379 8.48517C4.32994 8.48517 4.49608 8.42177 4.62284 8.29501L8.29504 4.62281C8.54856 4.36933 8.54856 3.95828 8.29504 3.70476C8.04156 3.45128 7.63051 3.45128 7.37699 3.70476L3.70479 7.37691C3.45127 7.63048 3.45127 8.04153 3.70479 8.29501Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_518_12518">
                <rect width={12} height={12} fill="white" />
            </clipPath>
        </defs>
    </svg>
);

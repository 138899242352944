export const LockIcon = () => {
    return (
        <svg
            width="10"
            height="14"
            viewBox="0 0 10 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.16667 5.49339V4.375C9.16667 1.96278 7.29736 0 5 0C2.70264 0 0.833329 1.96278 0.833329 4.375V5.49339C0.337578 5.7969 0 6.35505 0 7.00001V12.25C0 13.2152 0.747467 14 1.66666 14H8.33334C9.25253 14 10 13.2152 10 12.25V7.00001C10 6.35502 9.66242 5.79688 9.16667 5.49339ZM5 1.74999C6.37858 1.74999 7.50001 2.92747 7.50001 4.375V5.25H2.49999V4.375C2.49999 2.92749 3.62142 1.74999 5 1.74999ZM5.83333 10.1594V11.375H4.16667V10.1594C3.91265 9.91917 3.75001 9.57471 3.75001 9.1875C3.75001 8.46262 4.30964 7.87501 5 7.87501C5.69036 7.87501 6.24999 8.46262 6.24999 9.1875C6.24999 9.57471 6.08735 9.91917 5.83333 10.1594Z"
                fill="#FFDF43"
            />
        </svg>
    );
};

import { useEffect, useState } from "react";
import { usePaymentRD } from "../usePaymentRD";
import { useGoldPrice } from "../../../../hooks/useGoldPrice";
import { usePaymentRoute } from "../usePaymentRoute";
import { useGetProfileRD } from "../../../Profile/hooks/useGetProfileRD";
import {
    validateDecimalHandler,
    validateNumericHandler,
    validateNumericWithDecimalHandler,
} from "../../../../utils/StringHelper";
import { updatePaymentDetail } from "../../../../redux/slices/content";
import { AppDispatch } from "../../../../redux/store";
import { useDispatch } from "react-redux";
import { getRequest, putRequest } from "../../../../network/Request";
import { VIEW_COUNT } from "../../../../network/Url";
import { updateGoldifyLinksCount } from "../../../../redux/slices/profile";
import { LOCKED } from "../../../../constants/AppConstant";
import { useAppNavigation } from "../../../../hooks/useAppNavigation";
import { useParams } from "react-router-dom";

export const useLockedContentPaymentInitializer = () => {
    /* Hooks */
    const dispatch = useDispatch<AppDispatch>();

    /* Custom Hooks */
    const { profileData } = useGetProfileRD();
    const { navigate } = useAppNavigation();
    const {
        supportUserPaymentDetailHandler,
        resetPayment,
        supportUserPaymentThanksHandler,
        paymentDetailHandler,
    } = usePaymentRoute();
    const { getGoldPrice, goldLoading, goldPrice, goldPriceTime, gstRate } =
        useGoldPrice();
    const { weight, transactionCount, shortId, minWeight, viewCount } =
        usePaymentRD();
    const { username, shortId: userShortId } = useParams();

    /* States */
    const [input, setinput] = useState<any>("0");
    const [amount, setAmount] = useState("");
    const [goldWeightPrice, setGoldWeightPrice] = useState("");
    const [termCondition, setTermCondition] = useState(false);
    const [gstPrice, setGstPrice] = useState("");

    /* Handler */
    const viewCountHandler = async ({
        shortId,
        viewCount,
    }: {
        shortId: string;
        viewCount: number;
    }) => {
        try {
            const res: any = await putRequest({
                url: VIEW_COUNT + "/" + shortId,
            });
            if (res?.data?.message === "View updated successfully") {
                dispatch(
                    updateGoldifyLinksCount({
                        shortId,
                        viewCount: viewCount + 1,
                    })
                );
                return;
            }
        } catch {}
    };

    const paymentUpdate = (pay?: any) => {
        dispatch(
            updatePaymentDetail({
                amount: pay?.isFreeContentFlag === "YES" ? 0 : amount,
                weight: pay?.isFreeContentFlag === "YES" ? 0 : input,
                gstRate,
                goldWeightPrice,
                goldPrice,
                gstPrice,
                isFreeContent: pay?.isFreeContentFlag === "YES",
                // isFreeContent:
                //     pay?.isFreeContentFlag !== "YES"
                //         ? false
                //         : paymentType === LOCKED
                //         ? false
                //         : !(createdAt && minWeight),
            })
        );
    };

    const supportUserPaymentFinalizer = () => {
        paymentUpdate();
        supportUserPaymentDetailHandler();
    };

    const lockedContentPaymentFinalizer = () => {
        paymentUpdate();
        paymentDetailHandler();
    };

    const payAsYouWantPaymentFinalizer = (pay?: {
        isFreeContentFlag: string;
    }) => {
        paymentUpdate(pay);
        paymentDetailHandler();
    };

    const goToContentHandler = () =>
        supportUserPaymentThanksHandler({
            shortId,
            orderId: "",
            username: profileData?.creatorInfo?.username
                ? profileData?.creatorInfo?.username
                : "",
        });

    const getNewGoldPrice = () => {
        getGoldPrice();
    };

    const termConditionHandler = () => {
        setTermCondition((prev) => !prev);
    };

    const setAllValue = ({ newValue }: { newValue: string }) => {
        setinput(newValue ? newValue : undefined);
        const goldWeightPrice = (Number(newValue) * Number(goldPrice))?.toFixed(
            2
        );
        const gstPrice = (
            (Number(gstRate) / 100) *
            Number(goldWeightPrice)
        )?.toFixed(2);
        setGstPrice(gstPrice);
        setGoldWeightPrice(goldWeightPrice);
        setAmount((Number(goldWeightPrice) + Number(gstPrice))?.toFixed(2));
    };

    const valueThroughNumberHandler = (value?: string) => {
        if (!value) {
            const newValue = input?.toString()?.slice(0, -1);
            setAllValue({ newValue: Number(newValue)?.toString() });
            return;
        }
        inputAmountHandler({
            target: {
                value: input + value,
            },
        });
        // if (!!input && validateDecimalHandler(input) && value === ".") {
        //     return;
        // }
        // if (value === "0") {
        //     const newValue = input + value;
        //     setAllValue({ newValue: Number(newValue)?.toString() });
        //     return;
        // }
        // if (!value) {
        //     const newValue = input?.toString()?.slice(0, -1);
        //     setAllValue({ newValue: Number(newValue)?.toString() });
        //     return;
        // }
        // const newValue = input + value;
        // console.log("new Value", input, Number(newValue)?.toString());
        // setAllValue({ newValue: Number(newValue)?.toString() });
    };

    const amountHandler = (value: any) => {
        if (!value) {
            setAllValue({ newValue: value });
            return;
        }
        if (validateNumericWithDecimalHandler(value)) {
            setAllValue({ newValue: value });
            return;
        }
    };

    const inputAmountHandler = (e: any) => {
        const value = e?.target?.value;
        const newValue = Number(value)?.toString();
        if (!newValue && newValue !== "NaN" && Number(newValue) !== 0) {
            setAllValue({ newValue });
            return;
        }
        const splitValue = value?.split(".");
        if (
            splitValue?.length === 2 &&
            splitValue[1] &&
            validateNumericHandler(splitValue[1]) &&
            splitValue[1]?.toString()?.length > 4
        ) {
            // setAllValue({ newValue: value });
            return;
        }
        if (
            splitValue?.length === 2 &&
            (!splitValue[1] || validateNumericHandler(splitValue[1]))
        ) {
            setAllValue({ newValue: value });
            return;
        }
        if (validateNumericHandler(newValue)) {
            setAllValue({ newValue });
            return;
        }
    };

    /* Effects */
    useEffect(() => {
        if (shortId) {
            viewCountHandler({ shortId, viewCount });
        }
    }, [shortId, viewCount]);

    useEffect(() => {
        getGoldPrice();
    }, []);

    useEffect(() => {
        if (goldPrice && weight && gstRate) {
            setAllValue({ newValue: weight });
        }
    }, [goldPrice, weight, gstRate]);

    useEffect(() => {
        if (minWeight) {
            setinput(minWeight ? minWeight : undefined);
        }
    }, [minWeight]);

    useEffect(() => {
        if (!profileData && username && userShortId) {
            window.location.replace(`/${username}/${userShortId}`);
            return;
        }
    }, [profileData, username, userShortId]);

    return {
        gstPrice,
        termConditionHandler,
        getNewGoldPrice,
        supportUserPaymentFinalizer,
        valueThroughNumberHandler,
        amountHandler,
        input,
        inputAmountHandler,
        setinput,
        amount,
        setAmount,
        goldWeightPrice,
        setGoldWeightPrice,
        termCondition,
        setTermCondition,
        profileData,
        supportUserPaymentDetailHandler,
        resetPayment,
        getGoldPrice,
        goldLoading,
        goldPrice,
        goldPriceTime,
        gstRate,
        weight,
        transactionCount,
        goToContentHandler,
        minWeight,
        payAsYouWantPaymentFinalizer,
        lockedContentPaymentFinalizer,
    };
};

import { Typography, TypographyTypeMap } from "@mui/material";
import { DefaultComponentProps } from "@mui/material/OverridableComponent";

type AppTextType = DefaultComponentProps<TypographyTypeMap>;

export const AppText = (props: AppTextType) => {
    /* Return */
    return (
        <Typography color="#ffffff" fontFamily="Hanken Grotesk" {...props} />
    );
};

import React, { useEffect } from "react";
import { useAppNavigation } from "../../../../hooks/useAppNavigation";
import { useInvoice } from "../../hooks/useInvoice";
import { PageContainer } from "../../../../components/PageContainer";
import { FullSpaceGrid } from "../../../../components/FullSpaceGrid";
import { CircularProgress, Grid } from "@mui/material";
import { useUrlQuery } from "../../../../hooks/useUrlQuery";
import { PAYMENT_COMPLETE_ORDER_ID } from "../../../../constants/AppConstant";
import { useParams } from "react-router-dom";

export const InvoicePage = () => {
    /* Custom hooks */
    const { navigationRoute } = useAppNavigation();
    const { getInvoice, invoice, loading } = useInvoice();
    const { order_id } = useParams();

    /* Effects */
    useEffect(() => {
        const orderId = order_id as string;
        getInvoice({
            orderId,
        });
    }, []);

    /* Render */

    if (loading) {
        return (
            <PageContainer>
                <FullSpaceGrid
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                    bgColor={"#0B0B0C"}
                >
                    <Grid item>
                        <CircularProgress size={24} />
                    </Grid>
                </FullSpaceGrid>
            </PageContainer>
        );
    }

    return <div dangerouslySetInnerHTML={{ __html: invoice }} />;
};

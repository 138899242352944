import { Box, Divider } from "@mui/material";

type HorizontalLineType = {
    height?: number;
    color?: any;
};

export const HorizontalLine = (props: HorizontalLineType) => {
    /* Props */
    const { height = 2, color = "rgba(255, 255, 255, 0.2)" } = props;

    return <Box height={height} bgcolor={color} />;
};

import { CircularProgress, Grid, TextField } from "@mui/material";
import { ColumnGrid } from "../../../../components/ColumnGrid";
import { PaymentTimer } from "../PaymentTimer";
import { PaymentBreakDown } from "../PaymentBreakDown";
import { PaymentButton } from "../PaymentButton";
import {
    CONFIRM_OTP,
    DONT_GET_CODE,
    PAY,
    RESEND_OTP,
    VERIFY_OTP_BUTTON,
} from "../../../../constants/AppString";
import { useEffect, useRef, useState } from "react";
import { useScreenDimension } from "../../../../hooks/useScreenDimension";
import { usePaymentRD } from "../../hooks/usePaymentRD";
import { MobilePaymentBreakDownSectionContainer } from "../MobilePaymentBreakDownSectionContainer";
import { FullSpaceGrid } from "../../../../components/FullSpaceGrid";
import { AppText } from "../../../../components/AppText";
import { AppImage } from "../../../../components/AppImage";
import { CloseIcon } from "../../../../utils/Image";
import { Repeater } from "../../../../components/Repeater";
import { AppInput } from "../../../../components/AppInput";
import {
    addZeroBefore,
    validateNumericHandler,
} from "../../../../utils/StringHelper";
import { useResendOtp } from "../../../../hooks/useResendOtp/useResendOtp";
import {
    OTP_SENT,
    OTP_SENT_ERROR,
    SB,
} from "../../../../constants/AppConstant";
import { AppModal } from "../../../../components/AppModal";
import { AppButton } from "../../../../components/AppButton";
import { VERIFY_OTP } from "../../../../network/Url";

type OtpVerifySectionType = {
    mobile?: boolean;
    resetOtpHandler?: () => void;
    verifyOtpHandler?: any;
    resendOtpHandler?: any;
    optSentDetail?: any;
    mobileNumberVerified?: any;
    mobileLoading?: any;
};

export const OtpVerifySection = (props: OtpVerifySectionType) => {
    /* Props */
    const {
        mobile = false,
        resetOtpHandler,
        verifyOtpHandler,
        resendOtpHandler,
        optSentDetail,
        mobileNumberVerified,
        mobileLoading,
    } = props;

    /* Refs */
    const otpRef1 = useRef();
    const otpRef2 = useRef();
    const otpRef3 = useRef();
    const otpRef4 = useRef();
    const otpRef5 = useRef();
    const otpRef6 = useRef();

    /* Custom hooks */
    const { running, second, setRunning } = useResendOtp();

    /* States */
    const [otp1, setOtp1] = useState("");
    const [otp2, setOtp2] = useState("");
    const [otp3, setOtp3] = useState("");
    const [otp4, setOtp4] = useState("");
    const [otp5, setOtp5] = useState("");
    const [otp6, setOtp6] = useState("");
    const [error, setError] = useState("");
    const [currentOTP, setCurrentOTP] = useState(1); // Value should be 1,2,3,4,5,6

    /* Effects */
    useEffect(() => {
        if (optSentDetail?.success) {
            setRunning(true);
        }
        if (!optSentDetail?.success) {
            setError(optSentDetail?.message);
        }
    }, [optSentDetail]);

    useEffect(() => {
        document.addEventListener("keydown", handleBackButton);
        return () => {
            document.removeEventListener("keydown", handleBackButton);
        };
    }, [currentOTP, otp2, otp3, otp4, otp5, otp6]);

    /* Handler */
    const handleBackButton = (event: any) => {
        if (event.key === "Backspace") {
            if (currentOTP === 2 && !otp2) {
                otpRef2?.current?.blur();
                otpRef1?.current?.focus();
                return;
            }
            if (currentOTP === 3 && !otp3) {
                otpRef3?.current?.blur();
                otpRef2?.current?.focus();
                return;
            }
            if (currentOTP === 4 && !otp4) {
                otpRef4?.current?.blur();
                otpRef3?.current?.focus();
                return;
            }
            if (currentOTP === 5 && !otp5) {
                otpRef5?.current?.blur();
                otpRef4?.current?.focus();
                return;
            }
            if (currentOTP === 6 && !otp6) {
                otpRef6?.current?.blur();
                otpRef5?.current?.focus();
                return;
            }
        }
    };

    const otp1InputHandler = (e: any, index: number) => {
        const value: string = e?.target?.value;

        if (!value) {
            setOtp1(value);
            setCurrentOTP(1);
            return;
        }
        if (value && validateNumericHandler(value) && value?.length == 1) {
            setOtp1(value);
            setCurrentOTP(2);
            otpRef1?.current?.blur();
            otpRef2?.current?.focus();
            return;
        }
    };

    const otp2InputHandler = (e: any, index: number) => {
        const value: string = e?.target?.value;

        if (!value) {
            setOtp2(value);
            setCurrentOTP(2);
            return;
        }
        if (value && validateNumericHandler(value) && value?.length == 1) {
            setOtp2(value);
            setCurrentOTP(3);
            otpRef2?.current?.blur();
            otpRef3?.current?.focus();
            return;
        }
    };

    const otp3InputHandler = (e: any, index: number) => {
        const value: string = e?.target?.value;

        if (!value) {
            setOtp3(value);
            setCurrentOTP(3);
            return;
        }
        if (value && validateNumericHandler(value) && value?.length == 1) {
            setOtp3(value);
            setCurrentOTP(4);
            otpRef3?.current?.blur();
            otpRef4?.current?.focus();
            return;
        }
    };

    const otp4InputHandler = (e: any, index: number) => {
        const value: string = e?.target?.value;

        if (!value) {
            setOtp4(value);
            setCurrentOTP(4);
            return;
        }
        if (value && validateNumericHandler(value) && value?.length == 1) {
            setOtp4(value);
            setCurrentOTP(5);
            otpRef4?.current?.blur();
            otpRef5?.current?.focus();
            return;
        }
    };

    const otp5InputHandler = (e: any, index: number) => {
        const value: string = e?.target?.value;
        if (!value) {
            setOtp5(value);
            setCurrentOTP(5);
            return;
        }
        if (value && validateNumericHandler(value) && value?.length == 1) {
            setOtp5(value);
            setCurrentOTP(6);
            otpRef5?.current?.blur();
            otpRef6?.current?.focus();
            return;
        }
    };

    const otp6InputHandler = (e: any, index: number) => {
        const value: string = e?.target?.value;
        if (!value) {
            setOtp6(value);
            setCurrentOTP(6);
            return;
        }
        if (value && validateNumericHandler(value) && value?.length == 1) {
            setOtp6(value);
            setCurrentOTP(6);
            return;
        }
    };

    /* Render */
    const INPUT_STYLE = {
        borderRadius: 3,
        bgcolor: "#FFFFFF10",
        width: 44,
        "& .MuiInput-underline:before": {
            borderBottomWidth: 0,
        },
        "& .MuiInput-underline:after": {
            borderBottomWidth: 0,
        },
        "& input": {
            color: "#fff",
            paddingY: 2,
            paddingX: 1.5,
            fontWeight: "600",
            fontSize: 16,
            textAlign: "center",
            fontFamily: "Hanken Grotesk",
        },
    };

    const SECTION = (
        <Grid container alignItems="flex-end" height="100%" borderRadius={3}>
            <Grid item xs={12}>
                <FullSpaceGrid
                    padding={2}
                    rowGap={3}
                    bgColor="#190E31"
                    borderRadius={3}
                >
                    <Grid item>
                        <Grid container>
                            <Grid item flex={1}>
                                <AppText
                                    children={CONFIRM_OTP}
                                    fontSize={16}
                                    fontWeight="700"
                                />
                            </Grid>
                            <Grid item paddingLeft={4}>
                                <Grid container onClick={resetOtpHandler}>
                                    <Grid item>
                                        <AppImage src={CloseIcon} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Grid container justifyContent="center">
                                            <Grid item>
                                                <Grid
                                                    container
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    bgcolor="#190E31"
                                                    maxWidth={400}
                                                    columnGap={1}
                                                >
                                                    <Grid item>
                                                        <AppInput
                                                            ref={otpRef1}
                                                            value={otp1}
                                                            onChange={
                                                                otp1InputHandler
                                                            }
                                                            type="tel"
                                                            autoFocus
                                                            sx={{
                                                                ...INPUT_STYLE,
                                                                border: `1px solid ${
                                                                    error &&
                                                                    !mobileNumberVerified
                                                                        ? "red"
                                                                        : "#F1F1F120"
                                                                }`,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <AppInput
                                                            ref={otpRef2}
                                                            value={otp2}
                                                            onChange={
                                                                otp2InputHandler
                                                            }
                                                            type="tel"
                                                            sx={{
                                                                ...INPUT_STYLE,
                                                                border: `1px solid ${
                                                                    error &&
                                                                    !mobileNumberVerified
                                                                        ? "red"
                                                                        : "#F1F1F120"
                                                                }`,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <AppInput
                                                            ref={otpRef3}
                                                            value={otp3}
                                                            onChange={
                                                                otp3InputHandler
                                                            }
                                                            type="tel"
                                                            sx={{
                                                                ...INPUT_STYLE,
                                                                border: `1px solid ${
                                                                    error &&
                                                                    !mobileNumberVerified
                                                                        ? "red"
                                                                        : "#F1F1F120"
                                                                }`,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <AppInput
                                                            ref={otpRef4}
                                                            value={otp4}
                                                            onChange={
                                                                otp4InputHandler
                                                            }
                                                            type="tel"
                                                            sx={{
                                                                ...INPUT_STYLE,
                                                                border: `1px solid ${
                                                                    error &&
                                                                    !mobileNumberVerified
                                                                        ? "red"
                                                                        : "#F1F1F120"
                                                                }`,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <AppInput
                                                            ref={otpRef5}
                                                            value={otp5}
                                                            onChange={
                                                                otp5InputHandler
                                                            }
                                                            type="tel"
                                                            sx={{
                                                                ...INPUT_STYLE,
                                                                border: `1px solid ${
                                                                    error &&
                                                                    !mobileNumberVerified
                                                                        ? "red"
                                                                        : "#F1F1F120"
                                                                }`,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <AppInput
                                                            ref={otpRef6}
                                                            value={otp6}
                                                            onChange={
                                                                otp6InputHandler
                                                            }
                                                            type="tel"
                                                            sx={{
                                                                ...INPUT_STYLE,
                                                                border: `1px solid ${
                                                                    error &&
                                                                    !mobileNumberVerified
                                                                        ? "red"
                                                                        : "#F1F1F120"
                                                                }`,
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sx={{ cursor: "pointer" }}>
                        <AppButton
                            disabled={
                                !otp1 ||
                                !otp2 ||
                                !otp3 ||
                                !otp4 ||
                                !otp5 ||
                                !otp6
                            }
                            onClick={() =>
                                verifyOtpHandler({
                                    otp:
                                        otp1 + otp2 + otp3 + otp4 + otp5 + otp6,
                                })
                            }
                            loading={mobileLoading}
                            children={
                                <AppText
                                    children={VERIFY_OTP_BUTTON?.toUpperCase()}
                                    textAlign="center"
                                    fontWeight="bold"
                                    color="#000"
                                />
                            }
                        />
                    </Grid>
                    <Grid item>
                        <Grid container justifyContent="space-between">
                            <Grid item xs={8}>
                                {error ? (
                                    <AppText
                                        children={error}
                                        fontSize={14}
                                        color="red"
                                        fontWeight="400"
                                    />
                                ) : (
                                    <AppText
                                        children={DONT_GET_CODE}
                                        fontSize={14}
                                        fontWeight="400"
                                    />
                                )}
                            </Grid>
                            {running ? (
                                <Grid item xs={4}>
                                    <Grid
                                        container
                                        justifyContent="flex-end"
                                        justifyItems="center"
                                    >
                                        <Grid item>
                                            <AppText
                                                children={`${RESEND_OTP} 00:${addZeroBefore(
                                                    second
                                                        ? second?.toString()
                                                        : ""
                                                )}`}
                                                fontSize={14}
                                                fontWeight="400"
                                                color="#FBD43D"
                                            />{" "}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid
                                    item
                                    onClick={resendOtpHandler}
                                    sx={{ cursor: "pointer" }}
                                    xs={4}
                                >
                                    <Grid
                                        container
                                        justifyContent="flex-end"
                                        justifyItems="center"
                                    >
                                        <Grid item>
                                            <AppText
                                                children={RESEND_OTP}
                                                fontSize={14}
                                                fontWeight="400"
                                                color="#FBD43D"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </FullSpaceGrid>
            </Grid>
        </Grid>
    );

    if (mobile) {
        return (
            <MobilePaymentBreakDownSectionContainer open>
                {SECTION}
            </MobilePaymentBreakDownSectionContainer>
        );
    }
    return SECTION;
};

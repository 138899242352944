import { ResponsiveLayout } from "../../../../components/ResponsiveLayout";
import { useScreenDimension } from "../../../../hooks/useScreenDimension";
import { CircularProgress, Grid } from "@mui/material";
import { PayBackIcon } from "../../components/PayBackIcon";
import { useAppNavigation } from "../../../../hooks/useAppNavigation";
import {
    CreatorPayHeart,
    LinkMoibleBgImage,
    NoVideo,
    PaymentLinkSuccessIcon,
    RedCloseIcon,
    SuccessPaymentIcon,
} from "../../../../utils/Image";
import { AppText } from "../../../../components/AppText";
import { AppButton } from "../../../../components/AppButton";
import {
    CREATOR_MSG,
    DOWNLOAD_INVOICE,
    ERROR_404,
    ERROR_SERVER,
    GO_BACK_PROFILE,
    LINK_PAYMENT_SUCCESS,
    LINK_PAYMENT_SUCCESS_WEB,
    PAYMENT_FAILED,
    PAYMENT_SUCESS,
    RETRY,
    THANKS_FOR_CREATOR,
    VIEW_USAGE_GUIDELINE,
} from "../../../../constants/AppString";
import { UnlockedContent } from "../../components/UnloackedContent";
import { ContentBackIcon } from "../../components/ContentBackIcon";
import { HorizontalLine } from "../../../../components/HorizontalLine";
import { SuccessPayment } from "../../../../assets/testing/SuccessPayment";
import { WebPayBackground } from "../../components/WebPayBackground";
import { LockedContentWeb } from "../../components/LockedContentWeb";
import { TextWithLink } from "../../../../components/TextWithLink";
import { usePaymentComplete } from "../../hooks/usePaymentComplete";
import { LockIcon } from "../../../../assets/icons/LockIcon";
import { UnLockIcon } from "../../../../assets/icons/UnLockIcon";
import { useGetProfileRD } from "../../../Profile/hooks/useGetProfileRD";
import { useUserProfileRoute } from "../../../Profile/hooks/useUserProfileRoute";
import { SupportTheCreatorThanks } from "../../components/SupportTheCreatorThanks";
import { LockImage } from "../../../../assets/images/LockImage";
import { AppImage } from "../../../../components/AppImage";
import {
    ShimmerThumbnail,
    ShimmerCircularImage,
    ShimmerSectionHeader,
    ShimmerCategoryItem,
    ShimmerPostItem,
    ShimmerTitle,
    ShimmerTable,
    ShimmerPostDetails,
} from "react-shimmer-effects";

import {
    ERROR,
    LOADING,
    PAYMENT_COMPLETE_ORDER_ID,
    SUCCESS,
} from "../../../../constants/AppConstant";
import { useUrlQuery } from "../../../../hooks/useUrlQuery";
import { FREE_CONTENT_URL } from "../../../../network/Url";
import { usePaymentRoute } from "../../hooks/usePaymentRoute";
import { ColumnGrid } from "../../../../components/ColumnGrid";
import { ErrorBoundary } from "../../../../components/ErrorBoundary";
import { useInvoice } from "../../hooks/useInvoice";

export const CreatorPaymentThanksPage = () => {
    /* Custom Hooks */
    const { browserHeight, browserWidth } = useScreenDimension();
    const { profileRouteHandler } = useUserProfileRoute();
    const {
        paymentDetail,
        username,
        paymentStatus,
        isFreeContent,
        openInvoice,
    } = usePaymentComplete();
    const { getUrlParam } = useUrlQuery();
    const { paymentRetryHandler, usageGuideLineHandler } = usePaymentRoute();

    /* Render */
    if ([ERROR_404, ERROR_SERVER]?.includes(paymentStatus)) {
        return <ErrorBoundary errorType={paymentStatus} />;
    }

    if ((!isFreeContent && paymentStatus === LOADING) || !paymentDetail) {
        return (
            <ResponsiveLayout
                web={
                    <Grid
                        container
                        paddingX={8.5}
                        paddingY={5.5}
                        height={browserHeight}
                        width={browserWidth}
                    >
                        <Grid item md={6} border={1} borderColor="#f1f1f1">
                            <ColumnGrid justifyContent="space-between">
                                <Grid item paddingX={6} paddingTop={4}>
                                    <ShimmerPostItem card title cta />
                                </Grid>
                                <Grid item paddingX={6} paddingTop={4}>
                                    <ShimmerSectionHeader />
                                </Grid>
                            </ColumnGrid>
                        </Grid>

                        <Grid item md={6} border={1} borderColor="#f1f1f1">
                            <ColumnGrid>
                                <Grid item paddingTop={16}>
                                    <ShimmerSectionHeader center />
                                </Grid>
                                <Grid item paddingTop={16}>
                                    <Grid container justifyContent="center">
                                        <Grid item>
                                            <ShimmerThumbnail
                                                width={280}
                                                height={50}
                                                rounded
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </ColumnGrid>
                        </Grid>
                    </Grid>
                }
                mobile={
                    <Grid
                        container
                        height={browserHeight}
                        justifyContent="center"
                        alignItems="center"
                        width={browserWidth}
                    >
                        {/* <Grid item> */}
                        <ColumnGrid>
                            <Grid item>
                                <Grid
                                    container
                                    justifyContent="center"
                                    position="relative"
                                    bottom={-20}
                                >
                                    <Grid item>
                                        <ShimmerThumbnail
                                            width={200}
                                            height={20}
                                            rounded
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container justifyContent="center">
                                    <Grid item paddingTop={1}>
                                        <ShimmerThumbnail
                                            width={280}
                                            height={10}
                                            rounded
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    justifyContent="center"
                                    position="relative"
                                    top={-25}
                                >
                                    <Grid item paddingTop={1}>
                                        <ShimmerThumbnail
                                            width={260}
                                            height={10}
                                            rounded
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={8} padding={2} position="relative">
                                <Grid container justifyContent="center">
                                    <Grid item>
                                        <ShimmerThumbnail
                                            width={300}
                                            height={150}
                                            rounded
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container justifyContent="center">
                                    <Grid item>
                                        <ShimmerCategoryItem
                                            width={300}
                                            height={150}
                                        />
                                    </Grid>
                                    <Grid
                                        container
                                        justifyContent="center"
                                        position="absolute"
                                    >
                                        <Grid item paddingTop={1}>
                                            <ShimmerThumbnail
                                                width={280}
                                                height={10}
                                                rounded
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        justifyContent="center"
                                        position="relative"
                                        top={-22}
                                    >
                                        <Grid item paddingTop={1}>
                                            <ShimmerThumbnail
                                                width={260}
                                                height={14}
                                                rounded
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid container justifyContent="center">
                                    <Grid item>
                                        <ShimmerThumbnail
                                            width={200}
                                            height={14}
                                            rounded
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    justifyContent="center"
                                    paddingTop={6}
                                >
                                    <Grid item>
                                        <ShimmerThumbnail
                                            width={300}
                                            height={50}
                                            rounded
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </ColumnGrid>

                        {/* </Grid> */}
                    </Grid>
                }
            />
        );
    }

    return (
        <ResponsiveLayout
            mobile={
                <Grid
                    container
                    minHeight={browserHeight}
                    width={browserWidth}
                    bgcolor="#0B0B0C"
                    sx={{
                        backgroundImage: `url(${LinkMoibleBgImage})`,
                        backgroundSize: "cover",
                    }}
                >
                    <Grid item position="relative" paddingX={2} flex={1}>
                        <Grid
                            container
                            paddingTop={2}
                            height="100%"
                            direction="column"
                        >
                            <Grid
                                item
                                onClick={() =>
                                    profileRouteHandler({
                                        username: username ? username : "",
                                    })
                                }
                            >
                                <PayBackIcon />
                            </Grid>
                            <Grid item flex={1}>
                                <Grid
                                    container
                                    height="100%"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Grid item>
                                        <SupportTheCreatorThanks
                                            profileRouteHandler={
                                                profileRouteHandler
                                            }
                                            failedPayment={
                                                paymentStatus !== SUCCESS
                                            }
                                            paymentDetail={paymentDetail}
                                            linkCategory={paymentDetail?.category?.toString()}
                                            openInvoice={openInvoice}
                                            isFreeContent={isFreeContent}
                                            usageGuideLineHandler={
                                                usageGuideLineHandler
                                            }
                                            username={username}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
            web={
                <WebPayBackground
                    numberOfCol={0}
                    backHandler={() =>
                        profileRouteHandler({
                            username: username ? username : "",
                        })
                    }
                >
                    {paymentDetail?.title || getUrlParam("isFreeConent") ? (
                        <>
                            <LockedContentWeb
                                linkCategory={paymentDetail?.category?.toString()}
                                isLocked={false}
                            />
                            <Grid item md={6}>
                                <Grid
                                    container
                                    height="100%"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    direction="column"
                                    paddingX={10.5}
                                    paddingY={8}
                                >
                                    <Grid item flex={1}>
                                        <Grid
                                            container
                                            height="100%"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Grid item height={100} width={100}>
                                                <img
                                                    src={
                                                        paymentStatus !==
                                                        SUCCESS
                                                            ? RedCloseIcon
                                                            : PaymentLinkSuccessIcon
                                                    }
                                                    height="100%"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <AppText
                                                    children={
                                                        paymentStatus !==
                                                        SUCCESS
                                                            ? PAYMENT_FAILED
                                                            : LINK_PAYMENT_SUCCESS_WEB
                                                    }
                                                    textAlign="center"
                                                    fontSize={24}
                                                    fontWeight="700"
                                                />
                                            </Grid>

                                            <Grid item>
                                                <Grid
                                                    item
                                                    width={250}
                                                    paddingTop={2}
                                                >
                                                    {paymentStatus ===
                                                        SUCCESS &&
                                                        paymentDetail?.shortId &&
                                                        getUrlParam(
                                                            PAYMENT_COMPLETE_ORDER_ID
                                                        ) && (
                                                            <AppButton
                                                                outlineButton
                                                                showShadow={
                                                                    false
                                                                }
                                                                onClick={
                                                                    openInvoice
                                                                }
                                                            >
                                                                <Grid
                                                                    container
                                                                    height="100%"
                                                                    justifyContent="center"
                                                                    alignItems="center"
                                                                    sx={{
                                                                        cursor: "pointer",
                                                                    }}
                                                                >
                                                                    <Grid item>
                                                                        <AppText
                                                                            children={
                                                                                DOWNLOAD_INVOICE
                                                                            }
                                                                            fontWeight="600"
                                                                            sx={{
                                                                                background:
                                                                                    "-webkit-linear-gradient(#F8DC71, #CC8718);",
                                                                                WebkitBackgroundClip:
                                                                                    "text",
                                                                                WebkitTextFillColor:
                                                                                    "transparent",
                                                                            }}
                                                                            fontSize={
                                                                                16
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </AppButton>
                                                        )}
                                                    {paymentStatus !==
                                                        SUCCESS &&
                                                        paymentDetail?.shortId &&
                                                        getUrlParam(
                                                            PAYMENT_COMPLETE_ORDER_ID
                                                        ) && (
                                                            <AppButton
                                                                outlineButton
                                                                showShadow={
                                                                    false
                                                                }
                                                                onClick={() =>
                                                                    paymentRetryHandler(
                                                                        {
                                                                            shortId:
                                                                                paymentDetail?.shortId,
                                                                            username:
                                                                                username
                                                                                    ? username
                                                                                    : "",
                                                                        }
                                                                    )
                                                                }
                                                            >
                                                                <Grid
                                                                    container
                                                                    height="100%"
                                                                    justifyContent="center"
                                                                    alignItems="center"
                                                                    sx={{
                                                                        cursor: "pointer",
                                                                    }}
                                                                >
                                                                    <Grid item>
                                                                        <AppText
                                                                            children={
                                                                                RETRY
                                                                            }
                                                                            fontWeight="600"
                                                                            sx={{
                                                                                background:
                                                                                    "-webkit-linear-gradient(#F8DC71, #CC8718);",
                                                                                WebkitBackgroundClip:
                                                                                    "text",
                                                                                WebkitTextFillColor:
                                                                                    "transparent",
                                                                            }}
                                                                            fontSize={
                                                                                16
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </AppButton>
                                                        )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <TextWithLink
                                            title=""
                                            subTitle={VIEW_USAGE_GUIDELINE}
                                            subTitleHandler={
                                                usageGuideLineHandler
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        <Grid item flex={1} alignSelf="center">
                            <SupportTheCreatorThanks
                                linkCategory={paymentDetail?.category?.toString()}
                                failedPayment={paymentStatus !== SUCCESS}
                                profileRouteHandler={profileRouteHandler}
                                paymentDetail={paymentDetail}
                                openInvoice={openInvoice}
                                isFreeContent={isFreeContent}
                                usageGuideLineHandler={usageGuideLineHandler}
                                username={username}
                            />
                        </Grid>
                    )}
                </WebPayBackground>
            }
        />
    );
};

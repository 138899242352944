import { Grid } from "@mui/material";
import { UnCheckedIcon } from "../../../../assets/icons/UnCheckedIcon";
import { AppText } from "../../../../components/AppText";
import { CheckedIcon } from "../../../../assets/icons/CheckedIcon";

type TermsType = {
    checked: boolean;
    title: string;
    subTitle?: string;
    titleColor?: string;
    checkHandler?: () => void;
    linkHandler?: () => void;
    justifyContent?: string;
};

export const Terms = (props: TermsType) => {
    /* Props */
    const {
        checked,
        subTitle,
        title,
        titleColor = "#FFFFFF",
        checkHandler,
        linkHandler,
        justifyContent = "center",
    } = props;

    /* Render */
    return (
        <Grid item xs={12}>
            <Grid container justifyContent={justifyContent} alignItems="center">
                <Grid
                    item
                    onClick={checkHandler}
                    sx={{ cursor: "pointer" }}
                    height="100%"
                >
                    {/* TODO: No checked item found in figma */}
                    {checked ? <CheckedIcon /> : <UnCheckedIcon />}
                </Grid>
                <Grid item marginLeft={1}>
                    <Grid container alignItems="center" flex={1}>
                        <AppText
                            children={title}
                            color={titleColor}
                            fontWeight={500}
                            fontSize={12}
                        />
                        &nbsp;
                        <AppText
                            children={subTitle}
                            color="#FFFFFF"
                            fontWeight={500}
                            fontSize={12}
                            onClick={linkHandler}
                            sx={{
                                textDecoration: "underline",
                                cursor: "pointer",
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

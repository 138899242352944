import { Box, Grid } from "@mui/material";
import React from "react";
import {
    LOCK_IMAGE,
    LockThumbnailImage,
    PayAsYouWantIcon,
    TransactionCountUser1,
    TransactionCountUser2,
    TransactionCountUser3,
} from "../../../../utils/Image";
import { AppText } from "../../../../components/AppText";
import { PendingIcon } from "../../../../assets/icons/PendingIcon";
import { TrendIcon } from "../../../../assets/icons/TrendIcon";
import { usePaymentRD } from "../../hooks/usePaymentRD";
import moment from "moment";
import { LockedContentSymbol } from "../../../../assets/images/LockedContentSymbol";
import {
    isDateExpired,
    showLinkExpireDate,
    linkExpireDate,
} from "../../../../utils/DateHelper";
import { MultiplePayUser } from "../../../../components/MultiplePayUser";
import { BOUGHT_THIS, FIRST_LINK_USER } from "../../../../constants/AppString";
import { FullSpaceGrid } from "../../../../components/FullSpaceGrid";
import { AppImage } from "../../../../components/AppImage";

type LockedContentWebType = {
    linkCategory?: string;
    isLocked?: boolean;
};

export const LockedContentWeb = (props: LockedContentWebType) => {
    /* Props */
    const { linkCategory, isLocked = true } = props;

    const {
        title,
        description,
        createdAt,
        expiredAt,
        thumbnail,
        transactionCount,
        category,
    } = usePaymentRD();

    const categoryId = linkCategory ? linkCategory : category;

    return (
        <Grid
            item
            paddingX={10.5}
            paddingY={8}
            md={6}
            sx={{
                borderRight: "1px solid #1E1E1E;",
            }}
        >
            <Grid container height="100%">
                <Grid item md={12}>
                    <Grid container>
                        <Grid
                            item
                            md={10}
                            lg={7}
                            height={200}
                            position="relative"
                        >
                            {categoryId === "3" ? (
                                <Grid
                                    container
                                    justifyContent="center"
                                    alignItems="center"
                                    height="100%"
                                >
                                    <Grid
                                        item
                                        height={!thumbnail ? "50%" : "100%"}
                                    >
                                        <AppImage
                                            borderRadius={10}
                                            src={
                                                thumbnail
                                                    ? thumbnail
                                                    : PayAsYouWantIcon
                                            }
                                            objectFit="contain"
                                        />
                                    </Grid>
                                </Grid>
                            ) : (
                                <>
                                    <AppImage
                                        borderRadius={10}
                                        src={
                                            thumbnail
                                                ? thumbnail
                                                : LockThumbnailImage
                                        }
                                        objectFit="contain"
                                    />
                                    {categoryId === "2" &&
                                        thumbnail &&
                                        isLocked && (
                                            <Grid
                                                item
                                                position="absolute"
                                                bottom={0}
                                                top={0}
                                                left={0}
                                                right={0}
                                                style={{
                                                    backdropFilter: "blur(2px)",
                                                    "-webkit-backdrop-filter":
                                                        "blur(2px)",
                                                }}
                                            >
                                                <Grid
                                                    container
                                                    height="100%"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    paddingLeft={1}
                                                >
                                                    <Grid item>
                                                        <LockedContentSymbol />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                </>
                            )}
                        </Grid>

                        <Grid item md={12} paddingTop={1}>
                            <AppText
                                children={title}
                                fontWeight="700"
                                fontSize={20}
                            />
                        </Grid>
                        <Grid item md={12} paddingTop={1}>
                            <AppText
                                children={description}
                                fontWeight="400"
                                fontSize={10}
                                color="#FFFFFF60"
                            />
                        </Grid>
                        <Grid item md={12} paddingTop={1.5}>
                            <Box width={36} bgcolor="#FFFFFF32" height={2} />
                        </Grid>
                        <Grid item md={12} paddingTop={1.5}>
                            <Grid container alignItems="center">
                                <Grid item>
                                    <AppText
                                        children={`Created on ${moment(
                                            createdAt
                                        ).format("ll")}`}
                                        fontSize={10}
                                        color="#FFFFFF40"
                                    />
                                </Grid>
                                {showLinkExpireDate(expiredAt) && (
                                    <Grid item paddingLeft={2}>
                                        <Grid
                                            container
                                            alignItems="center"
                                            bgcolor={"#FF717113"}
                                            paddingX={1}
                                            borderRadius={20}
                                        >
                                            <Grid item>
                                                <PendingIcon
                                                    height={12}
                                                    width={12}
                                                    fill={"#FF7171"}
                                                />
                                            </Grid>
                                            <Grid item paddingLeft={0.5}>
                                                <AppText
                                                    children={linkExpireDate(
                                                        expiredAt
                                                    )}
                                                    fontSize={8}
                                                    color={"#FF7171"}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <FullSpaceGrid
                        justifyContent="flex-end"
                        alignItems="flex-end"
                    >
                        <MultiplePayUser
                            showTrending
                            noOfUser={transactionCount}
                            userText={
                                transactionCount
                                    ? `+${transactionCount} ${BOUGHT_THIS}`
                                    : FIRST_LINK_USER
                            }
                        />
                    </FullSpaceGrid>
                </Grid>
            </Grid>
        </Grid>
    );
};

import { Fragment, ReactNode } from "react";

type RepeaterType = {
    count: number;
    renderItem: (index: number) => any;
    empty?: ReactNode;
};

export const Repeater = (props: RepeaterType) => {
    /* Props */
    const { count, renderItem, empty } = props;

    /* Render */
    if (empty && !count) {
        return <>{empty}</>;
    }

    return (
        <>
            {[...Array(count).keys()].map((_, index) => {
                return (
                    <Fragment key={index?.toString()}>
                        {renderItem(index)}
                    </Fragment>
                );
            })}
        </>
    );
};

import { Box, Modal, Typography } from "@mui/material";
import { ChildrenType } from "../../types/ObjectType";

type AppModalType = {
    open: boolean;
    handleClose: () => void;
    children: any;
};

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",

    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export const AppModal = (props: AppModalType) => {
    /* Props */
    const { handleClose, open, children } = props;

    /* Render */
    return (
        <Modal open={open} onClose={handleClose}>
            {children}
        </Modal>
    );
};

import { Grid, Slider as MuiSlider } from "@mui/material";
import Slider from "rc-slider";
import { AppText } from "../AppText";
import "rc-slider/assets/index.css";
import { GoldWeight } from "../../assets/images/GoldWeight";
import { IN_GRAM, IN_RUPEE, RUPEE } from "../../constants/AppString";

type PaySliderType = {
    goldWeight?: string | null;
    goldWeightHandler?: (text: string) => void;
    scaleValue?: number[];
    amountType: string;
    goldWeightPrice: string;
};

export const PaySlider = (props: PaySliderType) => {
    /* Props */
    const {
        goldWeight,
        goldWeightHandler = () => {},
        scaleValue,
        amountType,
        goldWeightPrice,
    } = props;

    /* Render */
    const isRupee = amountType === IN_RUPEE;
    const currentValue = isRupee ? goldWeightPrice : goldWeight;

    return (
        <Grid container>
            <Grid item bgcolor="#0B0B0C" xs={12}>
                <Grid container justifyContent="space-between">
                    {scaleValue?.map((item, index) => {
                        return (
                            <Grid
                                item
                                key={index?.toString()}
                                onClick={() =>
                                    goldWeightHandler(item?.toString())
                                }
                                sx={{
                                    cursor: {
                                        xs: "default",
                                        md: "pointer",
                                    },
                                }}
                            >
                                <Grid container position="relative">
                                    <Grid
                                        item
                                        position="absolute"
                                        width="100%"
                                        height="70%"
                                        alignSelf="center"
                                    >
                                        <AppText
                                            children={`${
                                                isRupee ? RUPEE : ""
                                            }${item}${isRupee ? "" : "gm"}`}
                                            fontSize={10}
                                            textAlign="center"
                                            fontWeight={
                                                currentValue ===
                                                item?.toString()
                                                    ? "700"
                                                    : "400"
                                            }
                                            color={
                                                currentValue ===
                                                item?.toString()
                                                    ? "#F6CB61"
                                                    : "#FFFFFF80"
                                            }
                                        />
                                    </Grid>
                                    <Grid item>
                                        <GoldWeight
                                            fill={
                                                goldWeight === item?.toString()
                                                    ? "#212121"
                                                    : ""
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>

            <Grid item marginX={3} xs={12} marginTop={1}>
                <Slider
                    handleStyle={{
                        borderColor: "white",
                        height: 20,
                        width: 20,
                        marginTop: -5,
                        backgroundColor: "white",
                    }}
                    step={1 / 2}
                    value={
                        amountType === IN_GRAM
                            ? Number(goldWeight) * 100
                            : Number(Number(goldWeightPrice) / 10)
                    }
                    onChange={
                        amountType === IN_GRAM
                            ? (weight: any) => {
                                  goldWeightHandler(
                                      (weight * 0.01)?.toString()
                                  );
                              }
                            : (weight: any) => {
                                  goldWeightHandler((weight * 10)?.toString());
                              }
                    }
                    trackStyle={{
                        height: 8,
                        background: "linear-gradient(#F8DC71, #F2B148)",
                    }}
                    railStyle={{
                        backgroundColor: "#212121",
                        height: 8,
                    }}
                />
            </Grid>
        </Grid>
    );
};

import { Box, Grid } from "@mui/material";
import { LOCK_IMAGE } from "../../../../utils/Image";
import { AppText } from "../../../../components/AppText";
import { PendingIcon } from "../../../../assets/icons/PendingIcon";
import { TrendIcon } from "../../../../assets/icons/TrendIcon";
import { ContentValueCard } from "../ContentValueCard";
import { Terms } from "../Terms";
import { AppButton } from "../../../../components/AppButton";
import { HandPayIcon } from "../../../../assets/icons/HandPayIcon";
import { LabelInput } from "../../../../components/LabelInput";
import { WebPayBackground } from "../WebPayBackground";
import { PaymentTimer } from "../PaymentTimer";
import { useGoldPrice } from "../../../../hooks/useGoldPrice";
import { useUserPayment } from "../../hooks/useUserPayment";
import { PAY, PAY_TO } from "../../../../constants/AppString";
import { PAN_VALUE } from "../../../../constants/AppConstant";
import { useGetProfileRD } from "../../../Profile/hooks/useGetProfileRD";
import { PaymentButton } from "../PaymentButton";
import { PaymentInput } from "../PaymentInput";
import { PaymentBreakDownSection } from "../PaymentBreakDownSection";
import { ColumnGrid } from "../../../../components/ColumnGrid";

type ContentUserDetailType = {
    generatPaymentToken?: any;
    getNewGoldPrice?: any;
    loading?: any;
    panHander?: any;
    name?: any;
    mobileNumber?: any;
    setName?: any;
    setmobileNumber?: any;
    pan?: any;
    paymentAmount?: any;
    getGoldPrice?: any;
    goldPrice?: any;
    panCondition?: any;
    goldWeightPrice?: any;
    gstRate?: any;
    paymentValidate?: any;
    panError?: any;
    goldWeight?: any;
    gstPrice?: any;
    mobileNumberVerified?: any;
    sendOtpHandler?: any;
    mobileLoading?: any;
    minimumAmount?: number | string;
    whatsAppHandler: any;
    whatsAppFlag: any;
};

export const ContentUserDetail = (props: ContentUserDetailType) => {
    /* Props */
    const {
        generatPaymentToken,
        getGoldPrice,
        getNewGoldPrice,
        goldPrice,
        loading,
        mobileNumber,
        name,
        pan,
        panHander,
        paymentAmount,
        setName,
        setmobileNumber,
        panCondition,
        goldWeightPrice,
        gstRate,
        paymentValidate,
        panError,
        goldWeight,
        gstPrice,
        sendOtpHandler,
        mobileNumberVerified,
        mobileLoading,
        minimumAmount,
        whatsAppHandler,
        whatsAppFlag,
    } = props;

    /* Render */

    return (
        <Grid md={6} item paddingX={10.5} paddingY={8}>
            <Grid container height="100%" alignContent="space-between">
                <Grid item md={12}>
                    <Grid container>
                        <Grid item md={12}>
                            <AppText
                                children="Enter Details to unlock content"
                                fontWeight="700"
                                fontSize={20}
                                color="#FFFFFF"
                            />
                        </Grid>
                    </Grid>
                    <PaymentInput
                        panError={panError}
                        name={name}
                        nameHandler={setName}
                        pan={pan}
                        mobileNumber={mobileNumber}
                        moblieHandler={setmobileNumber}
                        panHandler={panHander}
                        panCondition={panCondition}
                        mobileNumberVerified={mobileNumberVerified}
                        sendOtpHandler={sendOtpHandler}
                        mobileLoading={mobileLoading}
                        whatsAppHandler={whatsAppHandler}
                        whatsAppFlag={whatsAppFlag}
                    />
                </Grid>
                <Grid item paddingTop={10} md={12}>
                    <ColumnGrid>
                        <Grid item paddingTop={2}>
                            <PaymentBreakDownSection
                                goldWeight={goldWeight}
                                gstPrice={gstPrice}
                                goldPrice={
                                    goldPrice ? goldPrice?.toString() : "0"
                                }
                                getNewGoldPrice={getNewGoldPrice}
                                amount={paymentAmount}
                                supportUserPaymentFinalizer={
                                    generatPaymentToken
                                }
                                isPayment
                                loading={loading}
                                disable={paymentValidate}
                                gstRate={gstRate}
                                goldWeightPrice={goldWeightPrice}
                                minimumAmount={minimumAmount}
                            />
                        </Grid>
                    </ColumnGrid>
                </Grid>
            </Grid>
        </Grid>
    );
};

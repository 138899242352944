import { Button, Grid, Typography } from "@mui/material";
import { useGetProfileRD } from "../../hooks/useGetProfileRD";
import {
    SB,
    defaultLinkCatogery,
    lockedContentCategory,
} from "../../../../constants/AppConstant";
import { useScreenDimension } from "../../../../hooks/useScreenDimension";
import { log } from "console";
import { LockThumbnailImage, NoVideo } from "../../../../utils/Image";
import { LockIcon } from "../../../../assets/icons/LockIcon";
import {
    camelCaseToSpace,
    snackCaseToSpace,
    toTitleCase,
} from "../../../../utils/StringHelper";
import { useContentContextRD } from "../../../PayWall/hooks/useContentContextRD";
import { reverseObjectKeyValue } from "../../../../utils/ObjectHelper";
import { useEffect, useState } from "react";
import { useAppNavigation } from "../../../../hooks/useAppNavigation";
import {
    EXPIRED_RN,
    LOCKED_CONTENT_FIXED_ROUTE_NAME,
    LOCKED_CONTENT_ROUTE_NAME,
} from "../../../../constants/RouteConstant";
import moment from "moment";
import { PendingIcon } from "../../../../assets/icons/PendingIcon";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import { updatePaymentDetail } from "../../../../redux/slices/content";
import {
    isDateExpired,
    showLinkExpireDate,
    linkExpireDate,
} from "../../../../utils/DateHelper";
import { usePaymentRoute } from "../../../PayWall/hooks/usePaymentRoute";
import { AppText } from "../../../../components/AppText";
import { LockedContentSymbol } from "../../../../assets/images/LockedContentSymbol";
import { AppImage } from "../../../../components/AppImage";

const TrendingImage = require("../../../../assets/testing/Rectangle5.png");

export const CreatorOtherContent = () => {
    /* Custom hooks */
    const { profileData } = useGetProfileRD();
    const { goldifyLinkCategories, goldifyLinkType } = useContentContextRD();
    const { paymentLinkHandler, paymentGeneralLinkHandler } = usePaymentRoute();

    /* States */
    const [categoryId, setCategoryId] = useState<any | string>(null);
    const [filteredItem, setFilteredItem] = useState<null | any[]>(null);
    const [categoryList, setcategoryList] = useState<
        null | { id: number; value: string }[]
    >(null);

    /* Handler */
    const tabHandler = (id: string) => {
        setCategoryId(id);
    };

    /* Effects */

    useEffect(() => {
        if (goldifyLinkType?.length && profileData?.goldifyLinks?.length) {
            const tempLinkType: any = {}; // Object of ids of goldify_link_type
            goldifyLinkType?.forEach((item) => {
                const id = item?.value;
                if (id) {
                    tempLinkType[id?.toString()] = item;
                }
            });
            const tempCategoryData: any = {
                "0": { value: "All", id: "0" },
            };
            profileData?.goldifyLinks?.forEach((item) => {
                const tempCategory = tempLinkType[item?.type?.[0]?.toString()];

                if (tempCategory) {
                    tempCategoryData[tempCategory?.value?.toString()] = {
                        id: tempCategory?.value,
                        value: tempCategory?.lable,
                    };
                }
            });
            tabHandler("0");
            setcategoryList(Object.values(tempCategoryData));
        }
    }, [goldifyLinkType, profileData?.goldifyLinks]);

    useEffect(() => {
        if (categoryId === "0") {
            setFilteredItem(
                profileData?.goldifyLinks ? profileData?.goldifyLinks : []
            );
            return;
        }
        const newItem = profileData?.goldifyLinks?.filter((item) => {
            return item.type?.[0]?.toString() === categoryId;
        });
        setFilteredItem(newItem?.length ? newItem : []);
    }, [categoryId, profileData?.goldifyLinks]);

    /* Render */
    const linkCategories = reverseObjectKeyValue(goldifyLinkCategories);

    return (
        <>
            <Grid
                item
                marginTop={3}
                sx={{
                    marginTop: { xs: 3, [SB]: 6.5 },
                    paddingLeft: { xs: 2, [SB]: 6.5 },
                }}
            >
                <AppText
                    fontWeight="700"
                    color={"#FFFFFF"}
                    sx={{
                        fontSize: {
                            xs: 18,
                            [SB]: 24,
                        },
                        fontFamily: "Marcellus",
                    }}
                >
                    More from this creator
                </AppText>
            </Grid>
            <Grid
                item
                marginTop={2}
                sx={{
                    paddingLeft: { xs: 2, [SB]: 6.5 },
                    marginBottom: { xs: 2, [SB]: 2 },
                }}
            >
                <Grid container columnGap={1}>
                    {!!categoryList &&
                        categoryList?.map((item, index) => {
                            const id = item?.id?.toString();

                            return (
                                <Button
                                    key={index?.toString()}
                                    onClick={() => tabHandler(id)}
                                    sx={{
                                        margin: 0,
                                        padding: 0,
                                        minWidth: 0,
                                    }}
                                >
                                    <Grid
                                        item
                                        paddingX={1}
                                        paddingY={0.5}
                                        border={1}
                                        borderRadius={4}
                                        borderColor={
                                            categoryId === id
                                                ? "#fff"
                                                : "#ffffff24"
                                        }
                                        bgcolor={
                                            categoryId === id
                                                ? "#fff"
                                                : "transprent"
                                        }
                                        sx={{
                                            cursor: "pointer",
                                        }}
                                    >
                                        <AppText
                                            fontWeight="500"
                                            fontSize={13}
                                            color={
                                                categoryId === id
                                                    ? "#000"
                                                    : "#F6F7F8"
                                            }
                                            sx={{
                                                cursor: "pointer",
                                            }}
                                        >
                                            {toTitleCase(item?.value)}
                                        </AppText>
                                    </Grid>
                                </Button>
                            );
                        })}
                </Grid>
            </Grid>
            <Grid container direction="column" flexWrap={"nowrap"}>
                {filteredItem?.map((item, index) => {
                    return (
                        <Grid
                            item
                            key={index?.toString()}
                            onClick={() =>
                                paymentGeneralLinkHandler({
                                    shortId: item?.shortId,
                                })
                            }
                            sx={{
                                marginBottom: { xs: 2, [SB]: 2 },
                                paddingLeft: { xs: 2, [SB]: 6.5 },
                                cursor: "pointer",
                            }}
                        >
                            <Grid container columnGap={1}>
                                <Grid
                                    item
                                    position="relative"
                                    sx={{
                                        width: { xs: 120, [SB]: 244 },
                                        height: { xs: 90, [SB]: 170 },
                                    }}
                                >
                                    <img
                                        src={
                                            item?.image ? item?.image : NoVideo
                                        }
                                        alt=""
                                        width={"100%"}
                                        height={"100%"}
                                        style={{
                                            objectFit: "cover",
                                            borderRadius: 10,
                                            resize: "inline",
                                        }}
                                    />
                                    {item?.category?.toString() === "2" && (
                                        <Grid
                                            item
                                            position="absolute"
                                            bottom={0}
                                            top={0}
                                            left={0}
                                            right={0}
                                            style={{
                                                backdropFilter: "blur(2px)",
                                                WebkitBackdropFilter:
                                                    "blur(2px)",
                                                borderRadius: 10,
                                            }}
                                        >
                                            <Grid
                                                container
                                                height="100%"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Grid
                                                    item
                                                    sx={{
                                                        height: {
                                                            xs: "100%",
                                                        },
                                                    }}
                                                >
                                                    <AppImage
                                                        src={LockThumbnailImage}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                    {showLinkExpireDate(item?.expiryAt) && (
                                        <Grid
                                            item
                                            position="absolute"
                                            bottom={0}
                                            paddingY={0.5}
                                            width="100%"
                                        >
                                            <Grid container>
                                                <Grid item marginLeft={0.5}>
                                                    <Grid
                                                        container
                                                        alignItems="center"
                                                        paddingX={1}
                                                        borderRadius={40}
                                                        bgcolor="#000000"
                                                        sx={{
                                                            paddingY: {
                                                                [SB]: 0.4,
                                                            },
                                                        }}
                                                    >
                                                        <Grid item>
                                                            <PendingIcon
                                                                fill={
                                                                    isDateExpired(
                                                                        item?.expiryAt
                                                                    )
                                                                        ? "gray"
                                                                        : "#FF7171"
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            marginLeft={0.5}
                                                        >
                                                            <AppText
                                                                fontWeight="600"
                                                                color={
                                                                    isDateExpired(
                                                                        item?.expiryAt
                                                                    )
                                                                        ? "gray"
                                                                        : "#FF7171"
                                                                }
                                                                sx={{
                                                                    fontSize: {
                                                                        xs: 6,
                                                                        [SB]: 10,
                                                                    },
                                                                }}
                                                            >
                                                                {linkExpireDate(
                                                                    item?.expiryAt
                                                                )}
                                                            </AppText>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                                <Grid item xs={8} md={5} lg={8} flex={1}>
                                    <Grid
                                        container
                                        direction={"column"}
                                        height={"100%"}
                                    >
                                        <Grid container direction="column">
                                            <Grid item>
                                                <AppText
                                                    fontWeight="700"
                                                    sx={{
                                                        fontSize: {
                                                            xs: 14,
                                                            [SB]: 16,
                                                        },
                                                        display: "-webkit-box",
                                                        overflow: "hidden",
                                                        WebkitBoxOrient:
                                                            "vertical",
                                                        WebkitLineClamp: 2,
                                                    }}
                                                >
                                                    {item?.title}
                                                </AppText>
                                            </Grid>
                                            <Grid
                                                item
                                                marginTop={0.5}
                                                flexWrap={"wrap"}
                                            >
                                                <AppText
                                                    fontWeight="200"
                                                    lineHeight={1.2}
                                                    sx={{
                                                        display: "-webkit-box",
                                                        overflow: "hidden",
                                                        WebkitBoxOrient:
                                                            "vertical",
                                                        WebkitLineClamp: 2,

                                                        fontSize: {
                                                            xs: 10,
                                                            [SB]: 16,
                                                        },
                                                    }}
                                                >
                                                    {item?.description}
                                                </AppText>
                                            </Grid>
                                        </Grid>
                                        <Grid item marginTop={1}>
                                            <Grid container columnGap={1}>
                                                <Grid
                                                    item
                                                    paddingX={1}
                                                    paddingY={0.5}
                                                    border={1}
                                                    borderColor={"#1E1E1E"}
                                                    borderRadius={4.5}
                                                >
                                                    <Grid
                                                        container
                                                        alignItems="center"
                                                    >
                                                        {item?.category ===
                                                            lockedContentCategory && (
                                                            <Grid
                                                                item
                                                                paddingRight={1}
                                                            >
                                                                <LockIcon />
                                                            </Grid>
                                                        )}
                                                        <Grid item>
                                                            <AppText
                                                                fontWeight="400"
                                                                fontSize={10}
                                                                sx={{
                                                                    fontSize: {
                                                                        xs: 10,
                                                                        [SB]: 12,
                                                                    },
                                                                }}
                                                            >
                                                                {toTitleCase(
                                                                    snackCaseToSpace(
                                                                        linkCategories[
                                                                            item?.category
                                                                                ? item?.category
                                                                                : defaultLinkCatogery
                                                                        ]
                                                                    )
                                                                )}
                                                            </AppText>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    item
                                                    paddingX={1}
                                                    paddingY={0.5}
                                                    border={1}
                                                    borderColor={"#1E1E1E"}
                                                    borderRadius={4.5}
                                                >
                                                    <Grid
                                                        container
                                                        alignItems="center"
                                                        height="100%"
                                                    >
                                                        <Grid
                                                            item
                                                            paddingLeft={0.5}
                                                        >
                                                            <AppText
                                                                fontWeight="400"
                                                                sx={{
                                                                    fontSize: {
                                                                        xs: 8,
                                                                        [SB]: 12,
                                                                    },
                                                                }}
                                                            >
                                                                {moment(
                                                                    item?.createdAt
                                                                ).format("ll")}
                                                            </AppText>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>
        </>
    );
};

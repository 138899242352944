import React from "react";

export const DribbleIcon = () => {
    return (
        <svg
            width={56}
            height={56}
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 28c0 15.464 12.536 28 28 28s28 -12.536 28 -28S43.464 0 28 0 0 12.536 0 28Z"
                fill="#EA4C89"
            />
            <path
                d="M28 14c-7.728 0 -14 6.272 -14 14s6.272 14 14 14c7.7 0 14 -6.272 14 -14s-6.3 -14 -14 -14Zm9.24 6.44c1.68 2.044 2.66 4.62 2.716 7.448 -0.392 -0.084 -4.34 -0.868 -8.316 -0.392 -0.084 -0.196 -0.168 -0.42 -0.252 -0.616 -0.252 -0.588 -0.504 -1.176 -0.784 -1.736 4.368 -1.764 6.384 -4.368 6.636 -4.704ZM28 16.072c3.024 0 5.824 1.148 7.924 2.996 -0.224 0.308 -2.016 2.716 -6.272 4.312 -1.96 -3.612 -4.144 -6.552 -4.452 -7 0.896 -0.196 1.82 -0.308 2.8 -0.308Zm-5.096 1.12c0.308 0.42 2.436 3.388 4.424 6.916 -5.6 1.484 -10.528 1.456 -11.06 1.456a12.099 12.099 0 0 1 6.636 -8.372Zm-6.86 10.836v-0.364c0.504 0.028 6.328 0.084 12.292 -1.708 0.336 0.672 0.672 1.344 0.98 2.044 -0.14 0.056 -0.308 0.084 -0.476 0.14 -6.16 1.988 -9.436 7.42 -9.716 7.868 -1.932 -2.128 -3.08 -4.928 -3.08 -7.98ZM28 39.956c-2.772 0 -5.32 -0.952 -7.336 -2.52 0.224 -0.448 2.632 -5.124 9.38 -7.476 0.028 -0.028 0.056 -0.028 0.084 -0.028 1.68 4.368 2.38 8.008 2.548 9.072 -1.428 0.616 -3.024 0.952 -4.676 0.952Zm6.664 -2.044c-0.112 -0.728 -0.756 -4.228 -2.324 -8.512 3.752 -0.588 7.028 0.392 7.448 0.504a11.88 11.88 0 0 1 -5.124 8.008Z"
                fill="white"
            />
        </svg>
    );
};

import { Box, Grid, Typography } from "@mui/material";
import { RightBackIcon } from "../../../assets/icons/RightBackIcon";
import { useScreenType } from "../../../hooks/useScreenType";
import { CreatorTrending } from "../components/CreatorTrending";
import { CreatorOtherContent } from "../components/CreatorOtherContent";
import { SupportCreator } from "../components/SupportCreator";
import { UserBio } from "../components/UserBio";
import { UserLink } from "../components/UserLink";
import { GetInTouch } from "../components/GetInTouch";
import { useGetProfileRD } from "../hooks/useGetProfileRD";
import { BgImage, BgProfileNav, QR } from "../../../utils/Image";
import { useScreenDimension } from "../../../hooks/useScreenDimension";
import { ProfileShimmer } from "../components/ProfileShimmer";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getProfileAction } from "../../../redux/slices/profile/action/getProfileAction";
import { useParams } from "react-router-dom";
import { AppDispatch } from "../../../redux/store";
import { ErrorBoundary } from "../../../components/ErrorBoundary";
import { useUrlQuery } from "../../../hooks/useUrlQuery";
import { getContentConstantAction } from "../../../redux/slices/content/action/getContentConstantAction";
import { ResponsiveLayout } from "../../../components/ResponsiveLayout";
import { useSession } from "../../../hooks/useSession";
import { SearchEngineType } from "../../../components/SearchEngine";
import { MAIN_WEB_URL } from "../../../constants/AppConstant";

export const UserProfilePage = () => {
    /* Custom hooks */
    const { browserWidth } = useScreenDimension();
    const { profileloading, profileData, profileError } = useGetProfileRD();

    /* Hooks */
    const dispatch = useDispatch<AppDispatch>();
    const { username } = useParams();
    const { clearSession } = useSession();

    /* Effect */
    useEffect(() => {
        window.addEventListener("beforeunload", clearSession);
        return () => {
            window.removeEventListener("beforeunload", clearSession);
        };
    }, [clearSession]);

    useEffect(() => {
        // if (
        //     username &&
        //     (!profileData || username !== profileData?.creatorInfo?.username)
        // ) {
        if (username) {
            dispatch(getProfileAction({ username }));
            dispatch(getContentConstantAction());
            return;
        }
        // }, [username, profileData]);
    }, [username]);

    /* Render */
    if (profileError) {
        return <ErrorBoundary />;
    }

    if (profileloading) {
        return <ProfileShimmer />;
    }

    return (
        <>
            <SearchEngineType
                title={`${profileData?.creatorInfo?.firstName} ${profileData?.creatorInfo?.lastName}`}
                description={
                    profileData?.creatorInfo?.bio
                        ? profileData?.creatorInfo?.bio
                        : ""
                }
                image={
                    profileData?.creatorInfo?.profileImg
                        ? profileData?.creatorInfo?.profileImg
                        : ""
                }
            />
            <ResponsiveLayout
                mobile={
                    <>
                        <Grid container direction="column" bgcolor={"#0B0B0C"}>
                            <Grid item>
                                <Grid
                                    item
                                    position="absolute"
                                    top={0}
                                    width={"100%"}
                                >
                                    <img src={BgImage} width={"100%"} />
                                </Grid>
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    position={"relative"}
                                >
                                    <UserBio />
                                    <Grid
                                        item
                                        width={browserWidth}
                                        marginTop={2.5}
                                        marginBottom={4}
                                    >
                                        <Grid item paddingX={3}>
                                            <UserLink />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                position={"relative"}
                                bgcolor={"#0B0B0C"}
                                borderRadius="9px 9px 0px 0px"
                                paddingBottom={14}
                            >
                                <Grid container direction="column">
                                    <CreatorTrending />
                                    <CreatorOtherContent />
                                    {/* <Grid
                            item
                            marginTop={2}
                            marginX={2}
                            borderRadius={60}
                            border={1}
                            paddingY={1.5}
                            borderColor={"#353535"}
                            alignItems="center"
                        >
                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Grid item>
                                    <Typography
                                        fontWeight="700"
                                        fontSize={14}
                                        color={"#FFFFFF"}
                                        textAlign="center"
                                    >
                                        View All
                                    </Typography>
                                </Grid>
                                <Grid item marginLeft={1}>
                                    <RightBackIcon />
                                </Grid>
                            </Grid>
                        </Grid> */}
                                    <GetInTouch />
                                    <Grid
                                        item
                                        position="fixed"
                                        width="100%"
                                        bottom={0}
                                        sx={{
                                            background:
                                                "linear-gradient(transparent, #0B0B0C)",
                                        }}
                                    >
                                        <SupportCreator />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                }
                web={
                    <>
                        <Grid container position="fixed" bgcolor="#0B0B0C">
                            <Grid item md={5} lg={3.5}></Grid>
                            <Grid
                                item
                                md={5}
                                lg={3.5}
                                position="fixed"
                                height={"100%"}
                                sx={{
                                    width: {
                                        md: "50%",
                                    },
                                    paddingY: {
                                        md: 6,
                                        lg: 6,
                                        xl: 9,
                                    },
                                    paddingRight: {
                                        md: 2,
                                        lg: 2,
                                        xl: 4,
                                    },
                                    paddingLeft: {
                                        md: 4,
                                        lg: 6,
                                        xl: 9,
                                    },
                                }}
                                paddingLeft={9}
                                paddingRight={4}
                                paddingTop={9}
                                paddingBottom={9}
                            >
                                <Grid
                                    container
                                    direction="column"
                                    alignItems="center"
                                    height="100%"
                                    width="100%"
                                    justifyContent="space-between"
                                    position={"relative"}
                                    borderRadius={3}
                                    sx={{
                                        backgroundImage: `url(${BgProfileNav})`,
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                    }}
                                >
                                    <Grid
                                        item
                                        width={"100%"}
                                        position={"relative"}
                                        flex={1}
                                        sx={{
                                            overflowY: "scroll",
                                        }}
                                        xs={12}
                                    >
                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="center"
                                            position={"relative"}
                                            paddingBottom={8}
                                        >
                                            <UserBio />

                                            <Grid
                                                item
                                                marginX={3}
                                                marginTop={4}
                                                marginBottom={2}
                                                md={5}
                                                lg={3.5}
                                            >
                                                <UserLink />
                                            </Grid>
                                            <Grid item>
                                                <GetInTouch />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        item
                                        width={"100%"}
                                        position="absolute"
                                        bottom={0}
                                    >
                                        <Grid
                                            container
                                            flexDirection={"column"}
                                            justifyContent={"center"}
                                            alignItems={"center"}
                                        >
                                            <Grid item width={"100%"}>
                                                <SupportCreator />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                md={7}
                                lg={8.5}
                                height={"100vh"}
                                sx={{
                                    overflowY: "scroll",
                                }}
                            >
                                <Grid container direction="column">
                                    <CreatorTrending />
                                    <CreatorOtherContent />
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                }
            />
        </>
    );
};

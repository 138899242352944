import { Box, Grid } from "@mui/material";
import { PageContainer } from "../../../../components/PageContainer";
import { AppImage } from "../../../../components/AppImage";
import {
    LinkMoibleBgImage,
    PayAsYouWantIcon,
    SupportCreatorBackground,
} from "../../../../utils/Image";
import { FullSpaceGrid } from "../../../../components/FullSpaceGrid";
import { PayHeader } from "../PayHeader";
import {
    ACCEPT_ALL,
    BOUGHT_THIS,
    ENTER_AMOUNT,
    FIRST_LINK_USER,
    GO_TO_CONTENT,
    PAY,
    PAY_TO,
    PAY_TO_UNLOCK,
    SHOW_LESS,
    SHOW_MORE,
    SUPPORT_THEM,
    TERM_AND_CONDITIION,
    USERLINK_COPIED,
} from "../../../../constants/AppString";
import { useGetProfileRD } from "../../../Profile/hooks/useGetProfileRD";
import { UserProfileImage } from "../../../../components/UserProfileImage";
import { MultiplePayUser } from "../../../../components/MultiplePayUser";
import { usePaymentRD } from "../../hooks/usePaymentRD";
import { PayCard } from "../../../../components/PayCard";
import { ReactNode, useState } from "react";
import { useScreenDimension } from "../../../../hooks/useScreenDimension";
import { ColumnGrid } from "../../../../components/ColumnGrid";
import { Terms } from "../Terms";
import { PaymentTimer } from "../PaymentTimer";
import { PaymentButton } from "../PaymentButton";
import { AppSnackBar } from "../../../../components/AppSnackBar";
import { AppText } from "../../../../components/AppText";
import { HorizontalLine } from "../../../../components/HorizontalLine";
import moment from "moment";
import { PendingIcon } from "../../../../assets/icons/PendingIcon";
import { LockedContentSymbol } from "../../../../assets/images/LockedContentSymbol";
import { AppButton } from "../../../../components/AppButton";
import {
    isDateExpired,
    showLinkExpireDate,
    linkExpireDate,
} from "../../../../utils/DateHelper";
import { PaymentBreakDownSection } from "../PaymentBreakDownSection";
import { OtpVerifySection } from "../OptVerifySection";
import { TrendIcon } from "../../../../assets/icons/TrendIcon";

type LinkMobilePaymentBackgroundType = {
    bottomComponent: ReactNode;
    goldPrice: number | null;
    getNewGoldPrice: () => void;
    amount: string;
    supportUserPaymentFinalizer: any;
    disable: boolean;
    contentPaddingBottom?: number;
    minimumAmount?: number | string;
    showIcon?: boolean;
    gstRate: string;
    goldWeightPrice: string;
    goldWeight?: any;
    gstPrice?: any;
    openOtpVerify?: boolean;
    resetOtpHandler?: () => void;
    verifyOtpHandler?: any;
    resendOtpHandler?: any;
    optSentDetail?: any;
    mobileNumberVerified?: any;
    getGoldPrice?: any;
    loading?: any;
    mobileLoading?: any;
    resetPayment?: any;
    isPayment?: boolean;
    termCondition?: boolean;
};

export const LinkMobilePaymentBackground = (
    props: LinkMobilePaymentBackgroundType
) => {
    /* Props */
    const {
        bottomComponent,
        goldPrice,
        getNewGoldPrice,
        amount,
        supportUserPaymentFinalizer,
        disable,
        contentPaddingBottom = 8,
        minimumAmount = 0,
        showIcon,
        gstRate,
        goldWeightPrice,
        goldWeight,
        gstPrice,
        openOtpVerify,
        mobileNumberVerified,
        resendOtpHandler,
        optSentDetail,
        verifyOtpHandler,
        resetOtpHandler,
        loading,
        mobileLoading,
        resetPayment,
        isPayment,
        termCondition,
    } = props;

    /* Custom Hooks */
    const {
        title,
        description,
        createdAt,
        expiredAt,
        thumbnail,
        transactionCount,
        category,
    } = usePaymentRD();

    /* States */
    const [showMore, setShowMore] = useState(true);

    /* Render */

    const seeMoreFlag = description?.split(" ")?.length > 30;

    return (
        <PageContainer>
            <FullSpaceGrid position="relative" bgColor="#0B0B0C">
                <Grid
                    item
                    sx={{
                        backgroundImage: `url(${LinkMoibleBgImage})`,
                        backgroundSize: "cover",
                    }}
                >
                    <ColumnGrid paddingBottom={contentPaddingBottom}>
                        <Grid item paddingX={2} paddingTop={2}>
                            <PayHeader
                                title={PAY_TO_UNLOCK}
                                backHanlder={resetPayment}
                            />
                        </Grid>
                        <Grid item marginTop={2.5} xs={12} paddingX={2}>
                            <Grid
                                container
                                alignItems="center"
                                justifyContent="center"
                                position="relative"
                                alignSelf="center"
                            >
                                <Grid item height={150} width={280}>
                                    {thumbnail && (
                                        <>
                                            <img
                                                src={thumbnail}
                                                height={"100%"}
                                                width={"100%"}
                                                style={{
                                                    objectFit: "contain",
                                                    borderRadius: 12,
                                                }}
                                            />
                                            {category === "2" && (
                                                <Grid
                                                    item
                                                    position="absolute"
                                                    bottom={0}
                                                    top={0}
                                                    left={0}
                                                    right={0}
                                                    style={{
                                                        backdropFilter:
                                                            "blur(2px)",
                                                        "-webkit-backdrop-filter":
                                                            "blur(2px)",
                                                    }}
                                                >
                                                    <Grid
                                                        container
                                                        height="100%"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        paddingLeft={1}
                                                    >
                                                        <Grid item>
                                                            <LockedContentSymbol />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </>
                                    )}
                                </Grid>
                                {(!thumbnail || showIcon) && (
                                    <Grid item position="absolute">
                                        {category === "3" ? (
                                            <Grid container>
                                                <Grid
                                                    item
                                                    height={100}
                                                    width={100}
                                                >
                                                    <AppImage
                                                        src={PayAsYouWantIcon}
                                                    />
                                                </Grid>
                                            </Grid>
                                        ) : !thumbnail ? (
                                            <LockedContentSymbol />
                                        ) : (
                                            <></>
                                        )}
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} paddingTop={3} paddingX={2}>
                            <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Grid item>
                                    <AppText
                                        children={title}
                                        fontWeight="700"
                                        fontSize={16}
                                        textAlign="center"
                                        color="#FFFFFF"
                                        // sx={{
                                        //     display: "-webkit-box",
                                        //     overflow: "hidden",
                                        //     WebkitBoxOrient: "vertical",
                                        //     WebkitLineClamp: 1,
                                        // }}
                                    />
                                </Grid>
                                <Grid item paddingTop={1}>
                                    <AppText
                                        children={description}
                                        fontWeight="400"
                                        fontSize={12}
                                        textAlign="justify"
                                        color="#FFFFFF60"
                                        sx={{
                                            display: "-webkit-box",
                                            overflow: "hidden",
                                            WebkitBoxOrient: "vertical",
                                            WebkitLineClamp:
                                                showMore && seeMoreFlag
                                                    ? 2
                                                    : undefined,
                                            lineHeight: 1.6,
                                            letterSpacing: 0.5,
                                            wordSpacing: 1,
                                            // textIndent: 20,
                                            // marginBottom: 20,
                                            // padding: 10,
                                        }}
                                    />

                                    {seeMoreFlag && (
                                        <AppText
                                            onClick={() =>
                                                setShowMore((prev) => !prev)
                                            }
                                            children={
                                                showMore ? SHOW_MORE : SHOW_LESS
                                            }
                                            color="#FFFFFF"
                                            fontWeight="bold"
                                            justifyContent="right"
                                            fontSize={12}
                                        />
                                    )}
                                </Grid>
                                <Grid item>
                                    <AppText
                                        children={`Created on ${moment(
                                            createdAt
                                        ).format("ll")}`}
                                        fontWeight="400"
                                        fontSize={10}
                                        textAlign="center"
                                        color="#FFFFFF60"
                                    />
                                </Grid>
                                <Grid item paddingY={2}>
                                    <Grid
                                        container
                                        alignItems="center"
                                        columnGap={1}
                                    >
                                        <Grid
                                            item
                                            bgcolor="#F1CF6330"
                                            borderRadius={40}
                                            paddingY={0.5}
                                            paddingLeft={
                                                Number(transactionCount) === 1
                                                    ? 0.4
                                                    : undefined
                                            }
                                            paddingRight={
                                                Number(transactionCount) === 1
                                                    ? 0.4
                                                    : Number(
                                                          transactionCount
                                                      ) === 2
                                                    ? 0.6
                                                    : 1
                                            }
                                        >
                                            <MultiplePayUser
                                                showTextColor={false}
                                                noOfUser={transactionCount}
                                                userText={
                                                    transactionCount
                                                        ? `+${transactionCount} ${BOUGHT_THIS}`
                                                        : FIRST_LINK_USER
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} paddingY={1}>
                                <Grid
                                    container
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Grid item>
                                        <Grid
                                            container
                                            paddingX={1}
                                            paddingY={1}
                                            borderRadius={10}
                                            bgcolor="#F1CF6330"
                                        >
                                            <Grid item>
                                                <TrendIcon fill="#FFFFFF60" />
                                            </Grid>
                                            <Grid item marginLeft={0.5}>
                                                <AppText
                                                    children="Trending"
                                                    color="#FFFFFF60"
                                                    fontWeight="500"
                                                    fontSize={12}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        {showLinkExpireDate(expiredAt) && (
                                            <Grid item>
                                                <Grid
                                                    container
                                                    alignItems="center"
                                                    bgcolor={"#00000050"}
                                                    paddingX={1}
                                                    paddingY={1}
                                                    borderRadius={20}
                                                >
                                                    <Grid item>
                                                        <PendingIcon
                                                            height={12}
                                                            width={12}
                                                            fill={"#FF7171"}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        paddingLeft={0.5}
                                                    >
                                                        <AppText
                                                            children={linkExpireDate(
                                                                expiredAt
                                                            )}
                                                            fontSize={10}
                                                            color={"#FF7171"}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ColumnGrid>
                </Grid>
                <Grid item flex={1} paddingBottom={20}>
                    {bottomComponent}
                </Grid>
                <PaymentBreakDownSection
                    termsChecked={termCondition}
                    mobile
                    loading={loading}
                    minimumAmount={minimumAmount}
                    isPayment={isPayment}
                    goldPrice={goldPrice ? goldPrice?.toString() : "0"}
                    getNewGoldPrice={getNewGoldPrice}
                    amount={amount}
                    supportUserPaymentFinalizer={supportUserPaymentFinalizer}
                    disable={disable}
                    gstRate={gstRate}
                    goldWeightPrice={goldWeightPrice}
                    goldWeight={goldWeight}
                    gstPrice={gstPrice}
                    freeContentPage={() =>
                        supportUserPaymentFinalizer({
                            isFreeContentFlag: "YES",
                        })
                    }
                />
                <Grid item>
                    {!!openOtpVerify && (
                        <OtpVerifySection
                            mobile
                            mobileLoading={mobileLoading}
                            resetOtpHandler={resetOtpHandler}
                            verifyOtpHandler={verifyOtpHandler}
                            resendOtpHandler={resendOtpHandler}
                            optSentDetail={optSentDetail}
                            mobileNumberVerified={mobileNumberVerified}
                        />
                    )}
                </Grid>
            </FullSpaceGrid>
        </PageContainer>
    );
};

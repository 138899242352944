import { useEffect, useState } from "react";

const DURATION = 30;

export const useResendOtp = () => {
    /* States */
    const [second, setSecond] = useState(DURATION);
    const [running, setRunning] = useState(false);
    const [timerId, setTimerId] = useState(null);

    /* Effects */
    useEffect(() => {
        return () => {
            if (timerId) {
                clearInterval(timerId);
            }
        };
    }, [timerId]);

    useEffect(() => {
        if (!timerId && running) {
            const timer = setInterval(timerHandler, 1000);
            setTimerId(timer);
            return;
        }
        if (timerId && !running) {
            clearInterval(timerId);
            setSecond(DURATION);
            setTimerId(null);
        }
    }, [running, timerId]);

    useEffect(() => {
        if (!second) {
            setRunning(false);
        }
    }, [second]);

    /* Handler */
    const timerHandler = () => {
        setSecond((prev) => {
            return prev - 1;
        });
    };

    /* Render */
    return {
        second,
        running,
        setRunning,
    };
};

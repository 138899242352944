import React from "react";

export const LockedContentSymbol = () => {
    return (
        <svg
            width={108}
            height={120}
            viewBox="0 0 108 120"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_d_566_1708)">
                <path
                    d="M51.4436 27.8901L37.341 33.6703C34.4701 34.8475 32.5957 37.6417 32.5957 40.7444V59.2711C32.5957 75.9363 47.1419 81.1908 52.442 82.5523C53.6904 82.8735 54.9971 82.8735 56.2455 82.5523C61.5457 81.1908 76.0918 75.9363 76.0918 59.2711V40.7444C76.0918 37.6417 74.2174 34.8475 71.3465 33.6703L57.2439 27.8901C55.3859 27.1291 53.3034 27.1291 51.4455 27.8901H51.4436Z"
                    fill="#FFDF43"
                />
                <path
                    d="M54.3428 78.9696C54.0234 78.9696 53.7022 78.9294 53.3919 78.8491C51.1068 78.2614 46.7138 76.8141 42.9705 73.7078C38.6212 70.0995 36.4165 65.241 36.4165 59.2693V40.7425C36.4165 39.1839 37.3473 37.7968 38.7892 37.2055L52.8918 31.4253C53.3536 31.2355 53.8409 31.1406 54.3409 31.1406C54.841 31.1406 55.3283 31.2374 55.7901 31.4253L69.8927 37.2055C71.3346 37.7968 72.2654 39.1839 72.2654 40.7425V59.2693C72.2654 65.241 70.0606 70.0995 65.7114 73.7078C61.9681 76.8141 57.575 78.2614 55.29 78.8491C54.9797 78.9294 54.6585 78.9696 54.3391 78.9696H54.3428Z"
                    fill="#F3A13B"
                />
                <path
                    d="M54.3421 69.5798C62.3636 69.5798 68.8664 63.077 68.8664 55.0555C68.8664 47.034 62.3636 40.5312 54.3421 40.5312C46.3206 40.5312 39.8179 47.034 39.8179 55.0555C39.8179 63.077 46.3206 69.5798 54.3421 69.5798Z"
                    fill="#D1882D"
                />
                <path
                    d="M59.1989 52.8255V51.4204C59.1989 48.3898 56.7329 45.9238 53.7023 45.9238C50.6717 45.9238 48.2057 48.3898 48.2057 51.4204V52.8255C47.5518 53.2068 47.1064 53.908 47.1064 54.7183V61.3142C47.1064 62.5268 48.0925 63.5128 49.3051 63.5128H58.0996C59.3121 63.5128 60.2982 62.5268 60.2982 61.3142V54.7183C60.2982 53.908 59.8528 53.2068 59.1989 52.8255ZM53.7023 48.1224C55.5209 48.1224 57.0003 49.6018 57.0003 51.4204V52.5197H50.4044V51.4204C50.4044 49.6018 51.8837 48.1224 53.7023 48.1224ZM54.8016 58.6876V60.2149H52.603V58.6876C52.2679 58.3858 52.0533 57.9531 52.0533 57.4666C52.0533 56.5559 52.7916 55.8176 53.7023 55.8176C54.613 55.8176 55.3513 56.5559 55.3513 57.4666C55.3513 57.9531 55.1367 58.3858 54.8016 58.6876Z"
                    fill="#FFDF43"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_566_1708"
                    x={0.935678}
                    y={0.935982}
                    width={106.816}
                    height={118.794}
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy={5.27667} />
                    <feGaussianBlur stdDeviation={15.83} />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 1 0 0 0 0 0.87451 0 0 0 0 0.262745 0 0 0 0.39 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_566_1708"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_566_1708"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
};

import { Grid, Typography } from "@mui/material";
import { FireIcon } from "../../../../assets/icons/FireIcon";
import { PendingIcon } from "../../../../assets/icons/PendingIcon";
import {
    SB,
    defaultLinkCatogery,
    lockedContentCategory,
} from "../../../../constants/AppConstant";
import { useGetProfileRD } from "../../hooks/useGetProfileRD";
import moment from "moment";
import { ThumbnailPlaceholder } from "../../../../assets/images/ThumbnailPlaceholder";
import { useScreenDimension } from "../../../../hooks/useScreenDimension";
import { useScreenType } from "../../../../hooks/useScreenType";
import { EyeIcon, LockThumbnailImage, NoVideo } from "../../../../utils/Image";
import { useContentContextRD } from "../../../PayWall/hooks/useContentContextRD";
import { reverseObjectKeyValue } from "../../../../utils/ObjectHelper";
import {
    camelCaseToSpace,
    snackCaseToSpace,
    toTitleCase,
} from "../../../../utils/StringHelper";
import { LockIcon } from "../../../../assets/icons/LockIcon";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import { useAppNavigation } from "../../../../hooks/useAppNavigation";
import { updatePaymentDetail } from "../../../../redux/slices/content";
import {
    LOCKED_CONTENT_FIXED_ROUTE_NAME,
    LOCKED_CONTENT_ROUTE_NAME,
} from "../../../../constants/RouteConstant";
import { usePaymentRoute } from "../../../PayWall/hooks/usePaymentRoute";
import {
    getNumberOfDays,
    getDaysOrHour,
    isDateExpired,
    linkExpireDate,
    showLinkExpireDate,
} from "../../../../utils/DateHelper";
import { AppImage } from "../../../../components/AppImage";
import { AppText } from "../../../../components/AppText";
import { LockedContentSymbol } from "../../../../assets/images/LockedContentSymbol";

// Image
const TrendingImage = require("../../../../assets/testing/Rectangle5.png");

export const CreatorTrending = () => {
    /* Custom hooks */
    const { profileData } = useGetProfileRD();
    const { goldifyLinkCategories } = useContentContextRD();
    const { paymentLinkHandler, paymentGeneralLinkHandler } = usePaymentRoute();

    /* Render */
    const linkCategories = reverseObjectKeyValue(goldifyLinkCategories);

    return (
        <>
            <Grid
                item
                sx={{
                    marginTop: { [SB]: 5.5 },
                    marginLeft: { [SB]: 6.5 },
                    marginBottom: { [SB]: 2.5 },
                }}
            >
                <Grid container alignItems="center">
                    <Grid item>
                        <FireIcon />
                    </Grid>
                    <Grid item>
                        <AppText
                            fontWeight="700"
                            color={"#FFFFFF"}
                            variant="h1"
                            sx={{
                                fontSize: { xs: 22, [SB]: 30 },
                                fontFamily: "Marcellus",
                            }}
                        >
                            {`${profileData?.creatorInfo?.firstName?.trim()}’s Trending`}
                        </AppText>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                item
                position={"relative"}
                sx={{
                    marginTop: { xs: 1.5, [SB]: 0 },
                    paddingLeft: { xs: 2, [SB]: 6.5 },
                    width: {
                        xs: "100vw",
                        [SB]: "100%",
                    },
                }}
            >
                <Grid container overflow={"scroll"} flexWrap={"nowrap"}>
                    {profileData?.trendingLinks?.map((item, index) => {
                        return (
                            <Grid
                                onClick={() =>
                                    paymentGeneralLinkHandler({
                                        shortId: item?.shortId,
                                    })
                                }
                                key={index?.toString()}
                                container
                                direction={"column"}
                                sx={{
                                    marginRight: { xs: 2, [SB]: 4 },
                                    maxWidth: { xs: 200, [SB]: 300 },
                                    cursor: "pointer",
                                }}
                            >
                                <Grid item>
                                    <Grid container position="relative">
                                        {showLinkExpireDate(item?.expiryAt) && (
                                            <Grid
                                                item
                                                position="absolute"
                                                top={0}
                                                paddingY={1}
                                                width="100%"
                                            >
                                                <Grid container>
                                                    <Grid item marginLeft={1}>
                                                        <Grid
                                                            container
                                                            alignItems="center"
                                                            paddingX={1}
                                                            paddingY={0.5}
                                                            borderRadius={40}
                                                            bgcolor="#000000"
                                                        >
                                                            <Grid item>
                                                                <PendingIcon
                                                                    fill={
                                                                        isDateExpired(
                                                                            item?.expiryAt
                                                                        )
                                                                            ? "gray"
                                                                            : "#FF7171"
                                                                    }
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                marginLeft={0.5}
                                                            >
                                                                <AppText
                                                                    fontWeight="600"
                                                                    fontSize={
                                                                        10
                                                                    }
                                                                    color={
                                                                        isDateExpired(
                                                                            item?.expiryAt
                                                                        )
                                                                            ? "gray"
                                                                            : "#FF7171"
                                                                    }
                                                                >
                                                                    {linkExpireDate(
                                                                        item?.expiryAt
                                                                    )}
                                                                </AppText>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                        {item?.category?.toString() === "2" && (
                                            <Grid
                                                item
                                                position="absolute"
                                                bottom={0}
                                                top={0}
                                                left={0}
                                                right={0}
                                                borderRadius={1}
                                                style={{
                                                    backdropFilter: "blur(2px)",
                                                    "-webkit-backdrop-filter":
                                                        "blur(2px)",
                                                }}
                                            >
                                                <Grid
                                                    container
                                                    height="100%"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >
                                                    <Grid item height={"100%"}>
                                                        <AppImage
                                                            src={
                                                                LockThumbnailImage
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                        <Grid
                                            item
                                            position="absolute"
                                            bottom={-1}
                                            paddingY={1}
                                            width="100%"
                                            style={{
                                                background:
                                                    "linear-gradient(transparent, #0a0a0a)",
                                            }}
                                        >
                                            <Grid
                                                container
                                                alignItems="center"
                                                paddingLeft={1}
                                            >
                                                <Grid
                                                    item
                                                    height={24}
                                                    width={24}
                                                >
                                                    <AppImage src={EyeIcon} />
                                                </Grid>
                                                <Grid item marginLeft={0.5}>
                                                    <AppText
                                                        fontWeight="600"
                                                        fontSize={10}
                                                        color={"#FFFFFF"}
                                                    >
                                                        {item?.views
                                                            ? item?.views
                                                            : 0}{" "}
                                                        Views
                                                    </AppText>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            sx={{
                                                width: {
                                                    xs: 180,
                                                    [SB]: "100%",
                                                },
                                                minWidth: {
                                                    [SB]: 260,
                                                },
                                                height: { xs: 117, [SB]: 172 },
                                            }}
                                        >
                                            <AppImage
                                                src={
                                                    item?.image
                                                        ? item?.image
                                                        : NoVideo
                                                }
                                                borderRadius={6}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item marginTop={1}>
                                    <AppText
                                        fontWeight="700"
                                        color={"#FFFFFF"}
                                        sx={{
                                            fontSize: {
                                                xs: 14,
                                                [SB]: 16,
                                            },
                                            display: "-webkit-box",
                                            overflow: "hidden",
                                            WebkitBoxOrient: "vertical",
                                            WebkitLineClamp: 2,
                                            fontFamily: "Hanken Grotesk",
                                        }}
                                    >
                                        {item?.title}
                                    </AppText>
                                </Grid>
                                <Grid item marginY={0.5}>
                                    <AppText
                                        fontWeight="200"
                                        sx={{
                                            fontSize: {
                                                xs: 10,
                                                [SB]: 14,
                                            },
                                            display: "-webkit-box",
                                            overflow: "hidden",
                                            WebkitBoxOrient: "vertical",
                                            WebkitLineClamp: 2,
                                        }}
                                    >
                                        {item?.description}
                                    </AppText>
                                </Grid>
                                <Grid item>
                                    <Grid container columnGap={1}>
                                        <Grid
                                            item
                                            paddingX={1}
                                            paddingY={0.5}
                                            border={1}
                                            borderColor={"#1E1E1E"}
                                            borderRadius={4.5}
                                        >
                                            <Grid container alignItems="center">
                                                {item?.category ===
                                                    lockedContentCategory && (
                                                    <Grid item paddingRight={1}>
                                                        <LockIcon />
                                                    </Grid>
                                                )}
                                                <Grid item>
                                                    <AppText
                                                        fontWeight="400"
                                                        sx={{
                                                            fontSize: {
                                                                xs: 8,
                                                                [SB]: 12,
                                                            },
                                                        }}
                                                    >
                                                        {toTitleCase(
                                                            snackCaseToSpace(
                                                                linkCategories[
                                                                    item?.category
                                                                        ? item?.category
                                                                        : defaultLinkCatogery
                                                                ]
                                                            )
                                                        )}
                                                    </AppText>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            paddingX={1}
                                            border={1}
                                            borderColor={"#1E1E1E"}
                                            borderRadius={4.5}
                                        >
                                            <Grid
                                                container
                                                alignItems="center"
                                                paddingY={0.5}
                                                height="100%"
                                            >
                                                <Grid item paddingLeft={0.5}>
                                                    <AppText
                                                        fontWeight="400"
                                                        sx={{
                                                            fontSize: {
                                                                xs: 8,
                                                                [SB]: 12,
                                                            },
                                                        }}
                                                    >
                                                        {moment(
                                                            item?.createdAt
                                                        ).format("ll")}
                                                    </AppText>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        </>
    );
};

import { CircularProgress, Grid } from "@mui/material";
import { AppButton } from "../../../../components/AppButton";
import { PAY, RUPEE } from "../../../../constants/AppString";
import { AppText } from "../../../../components/AppText";
import { HandPayIcon } from "../../../../assets/icons/HandPayIcon";

type PaymentButtonType = {
    supportUserPaymentFinalizer: () => void;
    disable: boolean;
    title: string;
    loading?: boolean;
};

export const PaymentButton = (props: PaymentButtonType) => {
    /* Props */
    const {
        supportUserPaymentFinalizer,
        disable,
        title,
        loading = false,
    } = props;

    /* Render */
    return (
        <AppButton onClick={supportUserPaymentFinalizer} disabled={disable}>
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ cursor: "pointer" }}
            >
                {loading ? (
                    <CircularProgress size={20} />
                ) : (
                    <>
                        <Grid item>
                            <HandPayIcon />
                        </Grid>
                        <Grid item paddingLeft={1}>
                            <AppText
                                children={PAY + " " + RUPEE + title}
                                fontWeight="600"
                                fontSize={16}
                                color="#000"
                            />
                        </Grid>
                    </>
                )}
            </Grid>
        </AppButton>
    );
};

import { Box, Grid } from "@mui/material";
import { AppText } from "../../../../components/AppText";
import { PendingIcon } from "../../../../assets/icons/PendingIcon";
import { TrendIcon } from "../../../../assets/icons/TrendIcon";
import { ContentValueCard } from "../ContentValueCard";
import { Terms } from "../Terms";
import { AppButton } from "../../../../components/AppButton";
import { HandPayIcon } from "../../../../assets/icons/HandPayIcon";
import { PaySlider } from "../../../../components/PaySlider";
import { LockedContentWeb } from "../LockedContentWeb";
import { PayCard } from "../../../../components/PayCard";
import {
    ACCEPT_ALL,
    ENTER_AMOUNT,
    GO_TO_CONTENT,
    IN_GRAM,
    IN_GRAM_TEXT,
    IN_RUPEE,
    IN_RUPEE_TEXT,
    MIN_AMOUNT,
    MIN_AMOUNT_GM,
    MIN_AMOUNT_RUPEE,
    PAY,
    TERM_AND_CONDITIION,
} from "../../../../constants/AppString";
import { PaymentButton } from "../PaymentButton";
import { PaymentTimer } from "../PaymentTimer";
import { ColumnGrid } from "../../../../components/ColumnGrid";
import { PaymentBreakDownSection } from "../PaymentBreakDownSection";
import {
    GRAM_SLIDER_VALUE,
    RUPEE_SLIDER_VALUE,
} from "../../../../constants/AppConstant";
type PayAmountType = {
    input?: any;
    setinput?: any;
    inputAmountHandler?: any;
    amount?: any;
    amountHandler?: any;
    termCondition?: any;
    termConditionHandler?: any;
    goldPrice?: any;
    supportUserPaymentFinalizer?: any;
    getNewGoldPrice?: any;
    goldPriceTime?: any;
    minimumAmount?: any;
    gstRate?: any;
    goldWeightPrice?: any;
    disable: any;
    goldWeight?: any;
    gstPrice?: any;
    termOfUseHandler?: any;
    amountType?: any;
    amountTypeHandler?: any;
};

export const PayAmount = (props: PayAmountType) => {
    /* Props */
    const {
        amount,
        input,
        inputAmountHandler,
        setinput,
        amountHandler,
        goldPrice,
        termCondition,
        termConditionHandler,
        supportUserPaymentFinalizer,
        getNewGoldPrice,
        goldPriceTime,
        minimumAmount,
        goldWeightPrice,
        gstRate,
        disable,
        goldWeight,
        gstPrice,
        termOfUseHandler,
        amountType,
        amountTypeHandler,
    } = props;

    /* Render */
    return (
        <Grid md={6} item paddingX={10.5} paddingY={8}>
            <Grid container height="100%" alignContent="space-between">
                <Grid item md={12}>
                    <Grid container>
                        <Grid item md={12}>
                            <AppText
                                children="Pay to unlock content"
                                fontWeight="700"
                                fontSize={20}
                                color="#FFFFFF"
                            />
                        </Grid>
                    </Grid>
                    <Grid item md={12} paddingTop={1}>
                        <PayCard
                            isMinimumAmount={
                                Number(minimumAmount) > Number(input)
                            }
                            amountText={
                                minimumAmount
                                    ? amountType === IN_GRAM
                                        ? MIN_AMOUNT_GM
                                        : MIN_AMOUNT_RUPEE
                                    : amountType === IN_GRAM
                                    ? IN_GRAM_TEXT
                                    : IN_RUPEE_TEXT
                            }
                            value={input}
                            setValue={setinput}
                            minimumAmount={minimumAmount}
                            amountHandler={inputAmountHandler}
                            amount={amount}
                            inputType="text"
                            amountType={amountType}
                            amountTypeHandler={amountTypeHandler}
                            goldWeightPrice={goldWeightPrice}
                        />
                    </Grid>
                    <Grid item paddingTop={7} border={3}>
                        <PaySlider
                            goldWeight={input}
                            goldWeightHandler={amountHandler}
                            amountType={amountType}
                            scaleValue={
                                amountType === IN_RUPEE
                                    ? RUPEE_SLIDER_VALUE
                                    : GRAM_SLIDER_VALUE
                            }
                            goldWeightPrice={goldWeightPrice}
                        />
                    </Grid>
                </Grid>
                <Grid item paddingTop={10} xs={12}>
                    <ColumnGrid>
                        <Grid item>
                            <Terms
                                checked={termCondition}
                                title={ACCEPT_ALL}
                                subTitle={TERM_AND_CONDITIION}
                                checkHandler={termConditionHandler}
                                linkHandler={termOfUseHandler}
                            />
                        </Grid>
                        <Grid item paddingTop={2}>
                            <PaymentBreakDownSection
                                termsChecked={termCondition}
                                minimumAmount={minimumAmount}
                                goldPrice={
                                    goldPrice ? goldPrice?.toString() : "0"
                                }
                                getNewGoldPrice={getNewGoldPrice}
                                amount={amount}
                                supportUserPaymentFinalizer={
                                    supportUserPaymentFinalizer
                                }
                                disable={disable}
                                gstRate={gstRate}
                                goldWeightPrice={goldWeightPrice}
                                goldWeight={goldWeight}
                                gstPrice={gstPrice}
                                freeContentPage={() =>
                                    supportUserPaymentFinalizer({
                                        isFreeContentFlag: "YES",
                                    })
                                }
                            />
                        </Grid>
                    </ColumnGrid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const HOST_URL = process.env.REACT_APP_HOST_URL;

export const GET_PROFILE = BASE_URL + "/creator-info";
export const GET_CONSTANTS =
    BASE_URL +
    "/constants?constants=goldify_link_categories,registration_failed_reason";
export const GET_GOLD_PRICE = BASE_URL + "/gold-price";
export const GOLD_PAYMENT = BASE_URL + "/payment-order";

export const GET_INVOICE = BASE_URL + "/goldify-invoice";
export const GET_PAYMENT_DETAIL = BASE_URL + "/goldify-link-info";
export const VIEW_COUNT = BASE_URL + "/inc-view-counter";
export const PAYMENT_STATUS = BASE_URL + "/payment-status";
export const FREE_CONTENT_URL = BASE_URL + "/access-without-pay";

export const SEND_OTP = BASE_URL + "/v2/send-otp";
export const VERIFY_OTP = BASE_URL + "/v2/otp-verify";

import React from "react";

export const CopyIcon = () => {
    return (
        <svg
            width={12}
            height={12}
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1704_3980)">
                <path
                    d="M4.86722 9.74997C3.69571 9.74997 2.74219 8.79645 2.74219 7.62494V2.5H1.86722C1.1087 2.5 0.492188 3.11642 0.492188 3.87494V10.6249C0.492188 11.3835 1.1087 12 1.86722 12H8.11716C8.87567 12 9.49219 11.3835 9.49219 10.6249V9.74997H4.86722Z"
                    fill="#F1F1F1"
                />
                <path
                    d="M11.4922 1.37503C11.4922 0.615509 10.8766 0 10.1172 0H4.86722C4.1077 0 3.49219 0.615509 3.49219 1.37503V7.62497C3.49219 8.38449 4.1077 9 4.86722 9H10.1172C10.8766 9 11.4922 8.38449 11.4922 7.62497V1.37503Z"
                    fill="#F1F1F1"
                />
            </g>
            <defs>
                <clipPath id="clip0_1704_3980">
                    <rect width={12} height={12} fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

import { Fragment, ReactNode } from "react";
import { useScreenType } from "../../hooks/useScreenType";
import { AppSnackBar } from "../AppSnackBar";
import { Button, IconButton } from "@mui/material";

type ResponsiveLayoutType = {
    mobile: ReactNode;
    web: ReactNode;
    openToast?: boolean;
    handleToastClose?: () => void;
    paymentMessage?: string;
};

export const ResponsiveLayout = (props: ResponsiveLayoutType) => {
    /* Props */
    const {
        mobile,
        web,
        handleToastClose = (e: any) => {},
        openToast = false,
        paymentMessage,
    } = props;

    /* Custom hooks */
    const { rs } = useScreenType();

    /* Return */

    if (["xs", "sm"]?.includes(rs)) {
        return (
            <>
                {mobile}
                <AppSnackBar
                    open={openToast}
                    onClose={handleToastClose}
                    message={paymentMessage}
                />
            </>
        );
    }
    return (
        <>
            {web}
            <AppSnackBar
                open={openToast}
                onClose={handleToastClose}
                message={paymentMessage}
                anchorOrigin={{ horizontal: "center", vertical: "top" }}
            />
        </>
    );
};

type KeyValueObject<T> = {
    [key: string | number]: T;
};

export const reverseObjectKeyValue = (originalObject: any) => {
    const reversedObject: KeyValueObject<string | string> = {};
    for (const key in originalObject) {
        if (originalObject.hasOwnProperty(key)) {
            reversedObject[originalObject[key]] = key;
        }
    }
    return reversedObject;
};

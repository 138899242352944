import React from "react";

export const LinkedIn = () => {
    return (
        <svg
            width={56}
            height={56}
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 28c0 15.464 12.536 28 28 28s28 -12.536 28 -28S43.464 0 28 0 0 12.536 0 28Z"
                fill="#2867B2"
            />
            <path
                d="M20.3 42H14.35V23.274H20.3V42ZM17.324 20.65C15.4 20.65 14 19.25 14 17.324 14 15.4 15.576 14 17.326 14c1.924 0 3.324 1.4 3.324 3.326 0 1.924 -1.4 3.324 -3.326 3.324ZM42 42h-5.95v-10.15c0 -2.976 -1.226 -3.85 -2.976 -3.85 -1.747 0 -3.5 1.4 -3.5 4.026V42H23.626V23.274h5.6v2.626c0.524 -1.226 2.624 -3.15 5.6 -3.15 3.324 0 6.824 1.926 6.824 7.7V42H42Z"
                fill="white"
            />
        </svg>
    );
};

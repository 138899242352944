import React from "react";

export const DiscordIcon = () => {
    return (
        <svg
            width={56}
            height={56}
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M56 28c0 -15.464 -12.536 -28 -28 -28S0 12.536 0 28s12.536 28 28 28 28 -12.536 28 -28Z"
                fill="#7289DA"
            />
            <path
                d="M25.466 27.496c-0.798 0 -1.428 0.7 -1.428 1.554 0 0.854 0.644 1.554 1.428 1.554 0.798 0 1.428 -0.7 1.428 -1.554 0.014 -0.854 -0.63 -1.554 -1.428 -1.554Zm5.11 0c-0.798 0 -1.428 0.7 -1.428 1.554 0 0.854 0.644 1.554 1.428 1.554 0.798 0 1.428 -0.7 1.428 -1.554 0 -0.854 -0.63 -1.554 -1.428 -1.554Z"
                fill="white"
            />
            <path
                d="M37.38 15.75h-18.76c-1.582 0 -2.87 1.288 -2.87 2.884v18.928c0 1.596 1.288 2.884 2.87 2.884h15.876l-0.742 -2.59 1.792 1.666 1.694 1.568 3.01 2.66v-25.116c0 -1.596 -1.288 -2.884 -2.87 -2.884Zm-5.404 18.284s-0.504 -0.602 -0.924 -1.134c1.834 -0.518 2.534 -1.666 2.534 -1.666 -0.574 0.378 -1.12 0.644 -1.61 0.826a9.808 9.808 0 0 1 -5.656 0.588 11.752 11.752 0 0 1 -2.058 -0.602 8.204 8.204 0 0 1 -1.022 -0.476c-0.042 -0.028 -0.084 -0.042 -0.126 -0.07 -0.028 -0.014 -0.042 -0.028 -0.056 -0.042 -0.252 -0.14 -0.392 -0.238 -0.392 -0.238s0.672 1.12 2.45 1.652c-0.42 0.532 -0.938 1.162 -0.938 1.162 -3.094 -0.098 -4.27 -2.128 -4.27 -2.128 0 -4.508 2.016 -8.162 2.016 -8.162 2.016 -1.512 3.934 -1.47 3.934 -1.47l0.14 0.168c-2.52 0.728 -3.682 1.834 -3.682 1.834s0.308 -0.168 0.826 -0.406c1.498 -0.658 2.688 -0.84 3.178 -0.882 0.084 -0.014 0.154 -0.028 0.238 -0.028A11.413 11.413 0 0 1 33.6 24.276s-1.106 -1.05 -3.486 -1.778l0.196 -0.224s1.918 -0.042 3.934 1.47c0 0 2.016 3.654 2.016 8.162 0 0 -1.19 2.03 -4.284 2.128Z"
                fill="white"
            />
        </svg>
    );
};

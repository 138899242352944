import { Grid } from "@mui/material";
import { Terms } from "../Terms";
import { PayCard } from "../../../../components/PayCard";
import {
    ACCEPT_ALL,
    ENTER_AMOUNT,
    IN_GRAM,
    IN_GRAM_TEXT,
    IN_RUPEE,
    IN_RUPEE_TEXT,
    MIN_AMOUNT,
    MIN_AMOUNT_GM,
    MIN_AMOUNT_RUPEE,
    PAY,
    TERM_AND_CONDITIION,
} from "../../../../constants/AppString";
import { PaySlider } from "../../../../components/PaySlider";
import { FullSpaceGrid } from "../../../../components/FullSpaceGrid";
import { ColumnGrid } from "../../../../components/ColumnGrid";
import {
    GRAM_SLIDER_VALUE,
    RUPEE_SLIDER_VALUE,
} from "../../../../constants/AppConstant";

type UnlockContentDetailType = {
    input?: any;
    setinput?: any;
    inputAmountHandler?: any;
    amount?: any;
    amountHandler?: any;
    termCondition?: any;
    termConditionHandler?: any;
    goldPrice?: any;
    getNewGoldPrice?: any;
    goldPriceTime?: any;
    minimumAmount?: any;
    termOfUseHandler?: any;
    amountType?: any;
    amountTypeHandler?: any;
    goldWeightPrice?: any;
};

export const UnlockContentDetail = (props: UnlockContentDetailType) => {
    /* Props */
    const {
        amount,
        input,
        inputAmountHandler,
        setinput,
        amountHandler,
        goldPrice,
        termCondition,
        termConditionHandler,
        getNewGoldPrice,
        goldPriceTime,
        minimumAmount,
        termOfUseHandler,
        amountType,
        amountTypeHandler,
        goldWeightPrice,
    } = props;

    /* Render */
    return (
        <>
            <FullSpaceGrid
                borderRadius="9px 9px 0px 0px"
                bgColor="rgba(11, 11, 12, 1)"
                justifyContent="space-between"
            >
                <Grid item position="relative" paddingX={2} top={-65}>
                    <ColumnGrid>
                        <Grid item>
                            <PayCard
                                isMinimumAmount={
                                    amountType === IN_GRAM
                                        ? Number(minimumAmount) > Number(input)
                                        : Number(minimumAmount) *
                                              Number(goldPrice) >
                                          Number(goldWeightPrice)
                                }
                                amountText={
                                    minimumAmount
                                        ? amountType === IN_GRAM
                                            ? MIN_AMOUNT_GM
                                            : MIN_AMOUNT_RUPEE
                                        : amountType === IN_GRAM
                                        ? IN_GRAM_TEXT
                                        : IN_RUPEE_TEXT
                                }
                                value={input}
                                setValue={setinput}
                                minimumAmount={
                                    amountType === IN_GRAM
                                        ? minimumAmount
                                        : Number(minimumAmount) *
                                          Number(goldPrice)
                                }
                                amountHandler={inputAmountHandler}
                                amount={amount}
                                amountType={amountType}
                                amountTypeHandler={amountTypeHandler}
                                goldWeightPrice={goldWeightPrice}
                            />
                        </Grid>
                        <Grid item paddingTop={2}>
                            <PaySlider
                                goldWeight={input}
                                goldWeightHandler={amountHandler}
                                amountType={amountType}
                                scaleValue={
                                    amountType === IN_RUPEE
                                        ? RUPEE_SLIDER_VALUE
                                        : GRAM_SLIDER_VALUE
                                }
                                goldWeightPrice={goldWeightPrice}
                            />
                        </Grid>
                    </ColumnGrid>
                </Grid>

                <Grid item>
                    <Terms
                        checked={termCondition}
                        title={ACCEPT_ALL}
                        subTitle={TERM_AND_CONDITIION}
                        checkHandler={termConditionHandler}
                        linkHandler={termOfUseHandler}
                    />
                </Grid>
            </FullSpaceGrid>
        </>
    );
};

export const useShare = () => {
    const handleShare = async ({
        title,
        text,
        url,
    }: {
        title: string;
        text: string;
        url?: string;
    }) => {
        try {
            if (navigator.share) {
                await navigator.share({
                    title: title,
                    text: text,
                });
            } else {
                alert("Not able to share content");
            }
        } catch (error) {
            console.error("Error sharing content:", error);
        }
    };

    return { handleShare };
};

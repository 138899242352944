export const TwitterIcon = () => {
    return (
        <svg
            width={56}
            height={56}
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M56 28c0 -15.464 -12.536 -28 -28 -28S0 12.536 0 28s12.536 28 28 28 28 -12.536 28 -28Z"
                fill="#371D70"
            />
            <path
                d="M21.006 16.5 16.005 21.498v18.001h6v5.001l4.998 -5.001h4.001l8.999 -8.999v-14H21.006Zm16.999 12.998 -4.001 4.001h-3.998l-3.5 3.5v-3.5H22.005V18.5h15.999v10.998Z"
                fill="#BAA8E1"
            />
            <path d="M35.006 22h-2.002V28h1.999V22Z" fill="#BAA8E1" />
            <path d="M29.504 22h-1.999V28h1.999V22Z" fill="#BAA8E1" />
        </svg>
    );
};

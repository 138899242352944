import { Grid } from "@mui/material";
import React from "react";
import { BackIcon } from "../../../../assets/icons/BackIcon";
import { AppText } from "../../../../components/AppText";
import { useAppNavigation } from "../../../../hooks/useAppNavigation";
import { PayBackIcon } from "../PayBackIcon";
import { TrendIcon } from "../../../../assets/icons/TrendIcon";

type PayHeaderType = {
    title: string;
    backHanlder?: () => void;
};

export const PayHeader = (props: PayHeaderType) => {
    /* Props */
    const { title, backHanlder } = props;

    /* Custom hooks */
    const { goBackHandler } = useAppNavigation();

    /* Render */
    return (
        <Grid item xs={12}>
            <Grid container alignItems="center">
                <Grid item onClick={backHanlder ? backHanlder : goBackHandler}>
                    <PayBackIcon />
                </Grid>
                <Grid item paddingLeft={2}>
                    <AppText
                        children={title}
                        fontWeight="700"
                        fontSize={20}
                        textAlign={"center"}
                        color="#FFFFFF"
                        fontFamily="Marcellus"
                    />
                </Grid>
                <Grid item flex={1} />
            </Grid>
        </Grid>
    );
};

import { ResponsiveLayout } from "../../../../components/ResponsiveLayout";
import { Grid } from "@mui/material";
import { useScreenDimension } from "../../../../hooks/useScreenDimension";
import { PayHeader } from "../../components/PayHeader";
import {
    BOUGHT_THIS,
    ENTER_AMOUNT,
    ERROR_404,
    ERROR_SERVER,
    LINK_EXPIRED,
    PAN_ERROR,
    PAN_NUMBER,
    PAY,
    PAY_TO,
    SUPPORT_SUBTITLE,
} from "../../../../constants/AppString";
import { SupportCreatorPayBackground } from "../../../../assets/images/SupportCreatorPayBackground";
import { useGetProfileRD } from "../../../Profile/hooks/useGetProfileRD";
import { UserProfileImage } from "../../../../components/UserProfileImage";
import { AppText } from "../../../../components/AppText";
import { MultiplePayUser } from "../../../../components/MultiplePayUser";
import { PayCard } from "../../../../components/PayCard";
import { PaySlider } from "../../../../components/PaySlider";
import { AppButton } from "../../../../components/AppButton";
import { Terms } from "../../components/Terms";
import { DeleteIcon } from "../../../../assets/icons/DeleteIcon";
import { HandPayIcon } from "../../../../assets/icons/HandPayIcon";
import { useState } from "react";
import { WebPayBackground } from "../../components/WebPayBackground";
import { usePaymentRoute } from "../../hooks/usePaymentRoute";
import {
    validateDecimalHandler,
    validateNumericWithDecimalHandler,
} from "../../../../utils/StringHelper";
import { useGoldPrice } from "../../../../hooks/useGoldPrice";
import { LabelInput } from "../../../../components/LabelInput";
import { usePaymentRD } from "../../hooks/usePaymentRD";
import { postRequest } from "../../../../network/Request";
import { GOLD_PAYMENT } from "../../../../network/Url";
import { PAN_VALUE } from "../../../../constants/AppConstant";
import { PaymentButton } from "../../components/PaymentButton";
import { PaymentInput } from "../../components/PaymentInput";
import { PaymentTimer } from "../../components/PaymentTimer";
import { useUserPayment } from "../../hooks/useUserPayment";
import { SupportCreatorMobilePaymentBackground } from "../../components/SupportCreatorMobilePaymentBackground";
import { ColumnGrid } from "../../../../components/ColumnGrid";
import { AppSnackBar } from "../../../../components/AppSnackBar";
import { PaymentBreakDownSection } from "../../components/PaymentBreakDownSection";
import { OtpVerifySection } from "../../components/OptVerifySection";
import { AppModal } from "../../../../components/AppModal";
import { ErrorBoundary } from "../../../../components/ErrorBoundary";
// import { PaymentPage } from "../../../../components/PaymentPage";

export const SupportCreatorUserDetail = () => {
    /* Custom Hooks */
    const { browserHeight, browserWidth } = useScreenDimension();
    const { profileData } = useGetProfileRD();
    const {
        generatPaymentToken,
        getNewGoldPrice,
        loading,
        panHander,
        name,
        mobileNumber,
        setName,
        setmobileNumber,
        pan,
        paymentAmount,
        getGoldPrice,
        paymentValidate,
        openToast,
        handleToastClose,
        paymentMessage,
        panError,
        openOtpVerify,
        mobileLoading,
        resetOtpHandler,
        verifyOtpHandler,
        resendOtpHandler,
        optSentDetail,
        sendOtpHandler,
        mobileNumberVerified,
        whatsAppHandler,
        whatsAppFlag,
    } = useUserPayment();
    const {
        transactionCount,
        goldWeightPrice,
        gstRate,
        goldPrice,
        gstPrice,
        weight,
    } = usePaymentRD();

    /* Render */
    const USER_TEXT = `${PAY_TO} ${profileData?.creatorInfo?.firstName}`;

    const PAN_CONDITION = Number(paymentAmount) > PAN_VALUE;

    // if ([ERROR_404, ERROR_SERVER]?.includes(paymentStatus)) {
    //     return <ErrorBoundary errorType={paymentStatus} />;
    // }

    return (
        <>
            <ResponsiveLayout
                handleToastClose={handleToastClose}
                openToast={openToast}
                paymentMessage={paymentMessage}
                mobile={
                    <SupportCreatorMobilePaymentBackground
                        amount={paymentAmount}
                        getNewGoldPrice={getNewGoldPrice}
                        goldPrice={goldPrice ? Number(goldPrice) : 0}
                        supportUserPaymentFinalizer={generatPaymentToken}
                        disable={paymentValidate}
                        gstRate={gstRate}
                        goldWeightPrice={goldWeightPrice}
                        loading={loading}
                        openOtpVerify={openOtpVerify}
                        resetOtpHandler={resetOtpHandler}
                        verifyOtpHandler={verifyOtpHandler}
                        resendOtpHandler={resendOtpHandler}
                        optSentDetail={optSentDetail}
                        mobileNumberVerified={mobileNumberVerified}
                        getGoldPrice={getGoldPrice}
                        goldWeight={weight}
                        gstPrice={gstPrice}
                        mobileLoading={mobileLoading}
                        bottomComponent={
                            <ColumnGrid>
                                <Grid
                                    item
                                    position="relative"
                                    bgcolor="#0B0B0C"
                                    flex={1}
                                    paddingBottom={20}
                                >
                                    <Grid
                                        container
                                        paddingX={2}
                                        minHeight={(browserHeight * 2) / 3}
                                        direction="column"
                                    >
                                        <Grid
                                            item
                                            marginTop={4}
                                            marginBottom={2}
                                        >
                                            <AppText
                                                children={SUPPORT_SUBTITLE}
                                                fontWeight="700"
                                                fontSize={20}
                                                color="#FFFFFF"
                                            />
                                        </Grid>
                                        <PaymentInput
                                            name={name}
                                            mobileNumberVerified={
                                                mobileNumberVerified
                                            }
                                            sendOtpHandler={sendOtpHandler}
                                            mobileLoading={mobileLoading}
                                            panError={panError}
                                            nameHandler={setName}
                                            pan={pan}
                                            mobileNumber={mobileNumber}
                                            moblieHandler={setmobileNumber}
                                            panHandler={panHander}
                                            panCondition={PAN_CONDITION}
                                            whatsAppHandler={whatsAppHandler}
                                            whatsAppFlag={whatsAppFlag}
                                        />
                                    </Grid>
                                </Grid>
                            </ColumnGrid>
                        }
                    />
                }
                web={
                    <>
                        <WebPayBackground numberOfCol={0}>
                            <Grid container>
                                <Grid item paddingX={3} width={600}>
                                    <Grid
                                        container
                                        direction="column"
                                        height="100%"
                                        justifyContent="space-between"
                                    >
                                        <Grid item>
                                            <Grid
                                                item
                                                xs={12}
                                                paddingTop={2}
                                                alignSelf="center"
                                            >
                                                <Grid
                                                    container
                                                    justifyContent="center"
                                                >
                                                    <Grid item>
                                                        <UserProfileImage />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <AppText
                                                    children={USER_TEXT}
                                                    fontWeight="700"
                                                    fontSize={20}
                                                    textAlign={"center"}
                                                    color="#FFFFFF"
                                                    fontFamily="Marcellus"
                                                />
                                            </Grid>
                                            <Grid item xs={12} paddingTop={2}>
                                                <MultiplePayUser
                                                    noOfUser={transactionCount}
                                                    userText={
                                                        transactionCount
                                                            ? `+${transactionCount} ${BOUGHT_THIS}`
                                                            : LINK_EXPIRED
                                                    }
                                                />
                                            </Grid>
                                            <Grid item marginTop={4}>
                                                <AppText
                                                    children={SUPPORT_SUBTITLE}
                                                    fontWeight="700"
                                                    fontSize={20}
                                                    textAlign={"center"}
                                                    color="#FFFFFF"
                                                />
                                            </Grid>
                                            <PaymentInput
                                                panError={panError}
                                                name={name}
                                                mobileLoading={mobileLoading}
                                                nameHandler={setName}
                                                pan={pan}
                                                mobileNumber={mobileNumber}
                                                mobileNumberVerified={
                                                    mobileNumberVerified
                                                }
                                                sendOtpHandler={sendOtpHandler}
                                                moblieHandler={setmobileNumber}
                                                panHandler={panHander}
                                                panCondition={PAN_CONDITION}
                                                whatsAppHandler={
                                                    whatsAppHandler
                                                }
                                                whatsAppFlag={whatsAppFlag}
                                            />
                                        </Grid>

                                        <Grid item paddingTop={10}>
                                            <ColumnGrid>
                                                <Grid item paddingTop={2}>
                                                    <PaymentBreakDownSection
                                                        isPayment
                                                        goldWeight={weight}
                                                        gstPrice={gstPrice}
                                                        goldPrice={
                                                            goldPrice
                                                                ? goldPrice?.toString()
                                                                : "0"
                                                        }
                                                        getNewGoldPrice={
                                                            getNewGoldPrice
                                                        }
                                                        amount={paymentAmount}
                                                        supportUserPaymentFinalizer={
                                                            generatPaymentToken
                                                        }
                                                        loading={loading}
                                                        disable={
                                                            paymentValidate
                                                        }
                                                        gstRate={gstRate}
                                                        goldWeightPrice={
                                                            goldWeightPrice
                                                        }
                                                    />
                                                </Grid>
                                            </ColumnGrid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </WebPayBackground>
                        <AppModal open={openOtpVerify} handleClose={() => {}}>
                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                height="100%"
                            >
                                <Grid item>
                                    <OtpVerifySection
                                        mobileLoading={mobileLoading}
                                        resetOtpHandler={resetOtpHandler}
                                        verifyOtpHandler={verifyOtpHandler}
                                        resendOtpHandler={resendOtpHandler}
                                        optSentDetail={optSentDetail}
                                        mobileNumberVerified={
                                            mobileNumberVerified
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </AppModal>
                    </>
                }
            />
        </>
    );
};

import { useLocation, useParams } from "react-router-dom";

export const useUrlQuery = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const getUrlParam = (name: string) => {
        return queryParams.get(name);
    };

    return {
        getUrlParam,
        queryParams,
    };
};

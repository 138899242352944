import React, { useEffect, useState } from "react";
import { useGoldPrice } from "../../../../hooks/useGoldPrice";
import { postRequest } from "../../../../network/Request";
import {
    FREE_CONTENT_URL,
    GOLD_PAYMENT,
    SEND_OTP,
    VERIFY_OTP,
} from "../../../../network/Url";
import { usePaymentRD } from "../usePaymentRD";
import { useAppNavigation } from "../../../../hooks/useAppNavigation";
import {
    validateAlphaNumericHandler,
    validateAlphbetHandler,
    validateNumericHandler,
    validatePanHandler,
} from "../../../../utils/StringHelper";
import { useGetProfileRD } from "../../../Profile/hooks/useGetProfileRD";
import { VALID_PAN, VERIFY_MOBILE } from "../../../../constants/AppString";
import {
    OTP_SENT,
    OTP_SENT_ERROR,
    OTP_SENT_LOADING,
    PAYMENT_OTP_SCENARIO,
} from "../../../../constants/AppConstant";
import { resetPaymentDetail } from "../../../../redux/slices/content";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import { usePaymentRoute } from "../usePaymentRoute";
import { useParams } from "react-router-dom";

export const useUserPayment = () => {
    /* Custom Hooks */
    const { paymentAmount, shortId, weight, minWeight, isFreeContent } =
        usePaymentRD();
    const { getGoldPrice, goldPrice } = useGoldPrice();
    const { profileData } = useGetProfileRD();
    const { supportUserPaymentThanksHandler } = usePaymentRoute();

    /* Hooks */
    const dispatch = useDispatch<AppDispatch>();
    const { username, shortId: userShortId } = useParams();

    /* States */
    const [name, setName] = useState("");
    const [mobileLoading, setMobileLoading] = useState(false);
    const [pan, setPan] = useState("");
    const [mobileNumber, setmobileNumber] = useState("");
    const [loading, setLoading] = useState(false);
    const [openToast, setOpenToast] = useState(false);
    const [paymentMessage, setPaymentMessage] = useState("");
    const [panError, setPanError] = useState("");
    const [openOtpVerify, setOpenOtpVerify] = useState(false);
    const [optSentDetail, setOptSentDetail] = useState<any>();
    const [whatsAppFlag, setWhatsAppFlag] = useState(true);
    const [mobileNumberVerified, setMobileNumberVerified] = useState(false);

    const PAYMENT_VALIDATE = isFreeContent
        ? !mobileNumberVerified ||
          !(name?.length > 1) ||
          !(mobileNumber?.length === 10) ||
          (pan ? pan?.length !== 10 : false)
        : !mobileNumberVerified ||
          !Number(paymentAmount) ||
          !(name?.length > 1) ||
          !(mobileNumber?.length === 10) ||
          (pan ? pan?.length !== 10 : false);

    /* Effects */
    useEffect(() => {
        if (!profileData && username && userShortId) {
            window.location.replace(`/${username}/${userShortId}`);
            return;
        }
    }, [profileData, username, userShortId]);

    useEffect(() => {
        if (!PAYMENT_VALIDATE) {
            generatPaymentToken();
        }
    }, [PAYMENT_VALIDATE]);

    /* Handler */
    const panHander = (e: any) => {
        const number = e?.target?.value;
        if (panError) {
            setPanError("");
        }

        if (!number) {
            setPan(number);
            return;
        }
        if (number?.length < 11 && validateAlphaNumericHandler(number)) {
            setPan(number?.toString()?.toUpperCase());
            return;
        }
    };

    const handleToastClose = () => {
        setOpenToast(false);
        setPaymentMessage("");
    };

    const getNewGoldPrice = () => {
        getGoldPrice();
    };

    const resetOtpHandler = () => {
        setOpenOtpVerify(false);
    };

    const resendOtpHandler = () => {
        sendOtpHandler();
    };

    const freeContentPage = async () => {
        try {
            setLoading(true);
            const res: any = await postRequest({
                url: FREE_CONTENT_URL,
                payload: {
                    shortId,
                    name,
                    mobile: mobileNumber,
                    sendOnWhatsapp: whatsAppFlag,
                },
            });
            setLoading(false);
            if (res?.data) {
                supportUserPaymentThanksHandler({
                    orderId: "",
                    shortId,
                    username: profileData?.creatorInfo?.username
                        ? profileData?.creatorInfo?.username
                        : "",
                    isFreeConent: isFreeContent,
                });
                return;
            }
        } catch {
            setLoading(false);
        }
    };

    const sendOtpHandler = async () => {
        try {
            setMobileLoading(true);
            setMobileNumberVerified(false);
            setOptSentDetail(OTP_SENT_LOADING);
            const res: any = await postRequest({
                url: SEND_OTP,
                payload: {
                    mobile: mobileNumber,
                    endpointType: PAYMENT_OTP_SCENARIO,
                },
            });
            setMobileLoading(false);
            if (res?.data?.Message === "OTP Sent successfully") {
                setOpenOtpVerify(true);
                setOptSentDetail({
                    success: true,
                    message: res?.data?.Message,
                    sendOtp: true,
                });
                return;
            }

            setOptSentDetail({
                success: false,
                message: res?.data?.Message,
                sendOtp: true,
            });
            if (!openOtpVerify) {
                setOpenToast(true);
                setPaymentMessage(res?.data?.Message);
            }
        } catch (e: any) {
            setMobileLoading(false);
            setOptSentDetail(e?.response?.data?.Message);
            setOptSentDetail({
                success: false,
                message: e?.response?.data?.Message,
                sendOtp: true,
            });
            if (!openOtpVerify) {
                setMobileNumberVerified(false);
                setOpenToast(true);
                setPaymentMessage(e?.response?.data?.Message);
            }
        }
    };

    const verifyOtpHandler = async ({ otp }: { otp: string }) => {
        try {
            setMobileLoading(true);
            setMobileNumberVerified(false);
            const res: any = await postRequest({
                url: VERIFY_OTP,
                payload: {
                    mobile: mobileNumber,
                    otp: otp,
                    endpointType: PAYMENT_OTP_SCENARIO,
                },
            });
            setMobileLoading(false);
            if (res?.data?.Message === "OTP verified successfully") {
                setMobileNumberVerified(true);
                resetOtpHandler();

                return;
            }
            setOptSentDetail({
                success: false,
                message: res?.data?.Message,
                verifyOtp: true,
            });
            if (!openOtpVerify) {
                setOpenToast(true);
                setPaymentMessage(res?.data?.Message);
            }
        } catch (e: any) {
            setMobileNumberVerified(false);
            setMobileLoading(false);
            setOptSentDetail(e?.response?.data?.Message);
            setOptSentDetail({
                success: false,
                message: e?.response?.data?.Message,
                verifyOtp: true,
            });
            if (!openOtpVerify) {
                setOpenToast(true);
                setPaymentMessage(e?.response?.data?.Message);
            }
        }
    };

    const generatPaymentToken = async () => {
        if (isFreeContent) {
            freeContentPage();
            return;
        }
        if (pan && !validatePanHandler(pan)) {
            setPanError(VALID_PAN);
            return;
        }

        try {
            setLoading(true);
            if (panError) {
                setPanError("");
            }
            const res: any = await postRequest({
                url: GOLD_PAYMENT,
                payload: {
                    shortId: shortId,
                    name: name,
                    mobile: mobileNumber,
                    amount: weight,
                    pancard: pan,
                    creatorUsername: profileData?.creatorInfo?.username,
                    sendOnWhatsapp: whatsAppFlag,
                },
            });
            setLoading(false);
            if (res?.data?.link) {
                dispatch(resetPaymentDetail());
                window.open(res?.data?.link, "_self");
                return;
            }
            setOpenToast(true);
            setPaymentMessage(res?.data?.Message);
        } catch (e: any) {
            setLoading(false);
            setOpenToast(true);

            const msg = e?.response?.data?.Message;
            if (msg?.toUpperCase()?.includes?.("PAN")) {
                setPanError(msg);
            }
            setPaymentMessage(e?.response?.data?.Message);
        }
    };

    const nameHandler = (name: string = "") => {
        if (!name) {
            setName(name);
            return;
        }
        if (name?.length < 25) {
            setName(name);
            return;
        }
    };

    const mobileNumberHandler = (number: string = "") => {
        if (!number) {
            setmobileNumber(number);
            setMobileNumberVerified(false);
            return;
        }
        if (number?.length < 11 && validateNumericHandler(number)) {
            setmobileNumber(number);
            setMobileNumberVerified(false);
            return;
        }
    };

    const whatsAppHandler = () => {
        setWhatsAppFlag((prev) => !prev);
    };

    return {
        pan,
        generatPaymentToken,
        getNewGoldPrice,
        panHander,
        loading,
        name,
        setName: nameHandler,
        setmobileNumber: mobileNumberHandler,
        mobileNumber,
        paymentAmount,
        weight,
        shortId,
        getGoldPrice,
        goldPrice,
        whatsAppHandler,
        whatsAppFlag,
        paymentValidate: PAYMENT_VALIDATE,
        openToast,
        handleToastClose,
        paymentMessage,
        panError,
        sendOtpHandler,
        openOtpVerify,
        mobileLoading,
        resetOtpHandler,
        verifyOtpHandler,
        resendOtpHandler,
        optSentDetail,
        mobileNumberVerified,
        freeContentPage,
        minWeight,
    };
};

import { Grid } from "@mui/material";
import { useScreenDimension } from "../../hooks/useScreenDimension";
import { useScreenType } from "../../hooks/useScreenType";
import { AppText } from "../AppText";
import { SOMETHING_WENT_WRONG } from "../../constants/StringConstant";
import { Error404, ErrorServer } from "../../utils/Image";
import { AppImage } from "../AppImage";
import {
    ERROR_404,
    ERROR_404_SUB,
    ERROR_SERVER,
    ERROR_SERVER_SUB,
} from "../../constants/AppString";

type ErrorBoundaryType = {
    errorType?: "ERROR_SERVER" | "Error 404";
};

export const ErrorBoundary = (props: ErrorBoundaryType) => {
    /* Props */
    const { errorType = ERROR_404 } = props;

    /* Custom hooks */
    const { rs } = useScreenType();
    const { browserHeight } = useScreenDimension();

    /* Render */

    const ERROR_IMAGE = errorType === ERROR_404 ? Error404 : ErrorServer;
    const ERROR_TITLE = errorType === ERROR_404 ? ERROR_404 : ERROR_SERVER;
    const ERROR_SUB =
        errorType === ERROR_404 ? ERROR_404_SUB : ERROR_SERVER_SUB;

    if (["xs", "sm"]?.includes(rs)) {
        return (
            <Grid
                container
                xs={12}
                width="100vw"
                height={browserHeight}
                justifyContent="center"
                alignItems="center"
                bgcolor="black"
                direction="column"
            >
                <Grid item>
                    <AppImage src={ERROR_IMAGE} />
                </Grid>
                <Grid item>
                    <AppText
                        children={ERROR_TITLE}
                        color="white"
                        fontWeight="bold"
                        fontSize={22}
                    />
                </Grid>
                <Grid item paddingX={8} paddingTop={3}>
                    <AppText
                        children={ERROR_SUB}
                        color="white"
                        textAlign="center"
                    />
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid
            container
            md={12}
            width="100vw"
            height={browserHeight}
            justifyContent="center"
            alignItems="center"
            bgcolor="black"
            direction="column"
        >
            <Grid item>
                <AppImage src={Error404} />
            </Grid>
            <Grid item>
                <AppText
                    children={ERROR_404}
                    color="white"
                    fontWeight="bold"
                    fontSize={30}
                />
            </Grid>
            <Grid item paddingX={8} paddingTop={3}>
                <AppText
                    children={ERROR_404_SUB}
                    color="white"
                    textAlign="center"
                />
            </Grid>
        </Grid>
    );
};

import { Grid, Tooltip, Typography } from "@mui/material";
import { LinkIcon } from "../../../../assets/icons/linkIcon";
import { ShareIcon } from "../../../../assets/icons/ShareIcon";
import { useGetProfileRD } from "../../hooks/useGetProfileRD";
import { useCopy } from "../../../../hooks/useCopy";
import { useShare } from "../../../../hooks/useShare";
import { useScreenType } from "../../../../hooks/useScreenType";
import { CopyIcon } from "../../../../assets/icons/CopyIcon";
import { AppSnackBar } from "../../../../components/AppSnackBar";
import { useState } from "react";
import { USERLINK_COPIED } from "../../../../constants/AppString";
import { AppText } from "../../../../components/AppText";
import { HOST_URL } from "../../../../network/Url";
import { SB } from "../../../../constants/AppConstant";

export const UserLink = () => {
    /* Custom hooks */
    const { profileData } = useGetProfileRD();
    const { copyText } = useCopy();
    const { handleShare } = useShare();
    const { rs } = useScreenType();

    /* States */
    const [openToast, setOpenToast] = useState(false);

    /* Handler */
    const openToastHandler = () => {
        setOpenToast(true);
    };

    const handleClose = (
        event: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenToast(false);
    };

    const shareCopyHandler = async () => {
        if (["xs", "sm"]?.includes(rs)) {
            await handleShare({
                title: `${HOST_URL}/${profileData?.creatorInfo?.username}`,
                text: `${HOST_URL}/${profileData?.creatorInfo?.username}`,
            });
            return;
        }
        copyText(`${HOST_URL}/${profileData?.creatorInfo?.username}`);
        openToastHandler();
    };

    /* Render */
    return (
        <>
            <Grid
                container
                border="1px dashed #423755"
                borderRadius={1.5}
                paddingY={1.5}
                paddingX={1.5}
                bgcolor={"#rgba(66, 55, 85, 1)"}
                flexWrap="nowrap"
            >
                <Grid item>
                    <LinkIcon />
                </Grid>
                <Grid
                    item
                    paddingLeft={0.5}
                    sx={{
                        cursor: "pointer",
                        width: {
                            xs: 100,
                        },
                    }}
                    onClick={shareCopyHandler}
                    flex={1}
                >
                    <Grid container overflow="hidden">
                        <Grid item>
                            <AppText
                                fontWeight="600"
                                fontSize={12}
                                textAlign={"center"}
                                color={"#FFFFFF"}
                                paddingX={1.5}
                                noWrap
                                sx={{
                                    display: "-webkit-box",
                                    overflow: "hidden",
                                    WebkitBoxOrient: "vertical",
                                    WebkitLineClamp: 1,
                                }}
                            >
                                {`${HOST_URL}/${profileData?.creatorInfo?.username}`}
                            </AppText>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    onClick={shareCopyHandler}
                    sx={{
                        cursor: "pointer",
                    }}
                    paddingLeft={0.8}
                >
                    {["xs", "sm"]?.includes(rs) ? <ShareIcon /> : <CopyIcon />}
                </Grid>
            </Grid>
            <AppSnackBar
                open={openToast}
                onClose={handleClose}
                message={USERLINK_COPIED}
            />
        </>
    );
};

import { createBrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "../../components/ErrorBoundary";
import { UserProfilePage } from "../../segments/Profile/pages/UserProfilePage";
import { SupportCreatorPage } from "../../segments/PayWall/pages/SupportCreatorPage";
import {
    EXPIRED_RN,
    LOCKED_CONTENT_FIXED_ROUTE_NAME,
    LOCKED_CONTENT_ROUTE_NAME,
    PAYMENT_DETAIL_ROUTE_NAME,
    SHOW_INVOICE,
    SUPPORT_CREATOR_ROUTE_NAME,
    SUPPORT_CREATOR_THANKS_ROUTE_NAME,
    SUPPORT_CREATOR_USER_DETAIL_ROUTE_NAME,
} from "../../constants/RouteConstant";
import { PaymentDetailPage } from "../../segments/PayWall/pages/PaymentDetailPage";
import { CreatorPaymentThanksPage } from "../../segments/PayWall/pages/CreatorPaymentThanksPage";
import { LockedContentPage } from "../../segments/PayWall/pages/LockedContentPage";
import { SupportCreatorUserDetail } from "../../segments/PayWall/pages/SupportCreatorUserDetail";
import { UnlockContentPage } from "../../segments/PayWall/pages/UnlockContentPage";
import { ExpiredLinkPage } from "../../segments/PayWall/pages/ExpiredLinkPage";
import { ShortLinkPaymentPage } from "../../segments/PayWall/pages/ShortLinkPaymentPage";
import { InvoicePage } from "../../segments/PayWall/pages/InvoicePage";
import { PAYMENT_COMPLETE_ORDER_ID } from "../../constants/AppConstant";
import { NoUserNamePage } from "../../segments/Profile/pages/NoUserNamePage";

export const rootRouter = createBrowserRouter([
    {
        path: "/",
        element: <NoUserNamePage />,
        errorElement: <ErrorBoundary />,
    },
    {
        path: "/:username/:shortId",
        element: <ShortLinkPaymentPage />,
        errorElement: <ErrorBoundary />,
    },
    {
        path: "/:username",
        element: <UserProfilePage />,
        errorElement: <ErrorBoundary />,
    },
    {
        path: "/:username/:shortId" + SUPPORT_CREATOR_ROUTE_NAME,
        element: <SupportCreatorPage />,
        errorElement: <ErrorBoundary />,
    },
    {
        path: "/:username/:shortId" + PAYMENT_DETAIL_ROUTE_NAME,
        element: <PaymentDetailPage />,
        errorElement: <ErrorBoundary />,
    },
    {
        path: SUPPORT_CREATOR_THANKS_ROUTE_NAME,
        element: <CreatorPaymentThanksPage />,
        errorElement: <ErrorBoundary />,
    },
    {
        path: "/:username/:shortId" + LOCKED_CONTENT_ROUTE_NAME,
        element: <UnlockContentPage />,
        errorElement: <ErrorBoundary />,
    },
    {
        path: "/:username/:shortId" + LOCKED_CONTENT_FIXED_ROUTE_NAME,
        element: <LockedContentPage />,
        errorElement: <ErrorBoundary />,
    },
    {
        path: "/:username/:shortId" + SUPPORT_CREATOR_USER_DETAIL_ROUTE_NAME,
        element: <SupportCreatorUserDetail />,
        errorElement: <ErrorBoundary />,
    },
    {
        path: EXPIRED_RN,
        element: <ExpiredLinkPage />,
        errorElement: <ErrorBoundary />,
    },
    {
        path: `${SHOW_INVOICE}/:${PAYMENT_COMPLETE_ORDER_ID}`,
        element: <InvoicePage />,
        errorElement: <ErrorBoundary />,
    },
]);

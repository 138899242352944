import React, { useState } from "react";
import { getRequest } from "../../../../network/Request";
import { GET_INVOICE } from "../../../../network/Url";
import { PAYMENT_COMPLETE_ORDER_ID } from "../../../../constants/AppConstant";
import { useUrlQuery } from "../../../../hooks/useUrlQuery";
import { useAppNavigation } from "../../../../hooks/useAppNavigation";
import { SHOW_INVOICE } from "../../../../constants/RouteConstant";

export const useInvoice = () => {
    /* States */
    const [invoice, setInvoice] = useState("");
    const [loading, setLoading] = useState(false);

    /* Handler */
    const getInvoice = async ({ orderId }: { orderId: string }) => {
        try {
            setLoading(true);
            const res: any = await getRequest({
                url: GET_INVOICE + "/" + orderId,
            });
            setLoading(false);
            if (res?.data?.html) {
                setInvoice(res?.data?.html);
            }
        } catch {
            setLoading(false);
        }
    };

    return {
        getInvoice,
        invoice,
        loading,
    };
};

import { Grid } from "@mui/material";
import { AppText } from "../../../../components/AppText";
import { AMOUNT } from "../../../../constants/AppString";
import { SB } from "../../../../constants/AppConstant";
import { AppImage } from "../../../../components/AppImage";
import { GoldBricks } from "../../../../assets/icons/GoldBricks";
import { GoldBrickIcon } from "../../../../utils/Image";

type LockedContentCardType = {
    weight?: string;
    amount?: string;
    isWeb?: boolean;
};

export const LockedContentCard = (props: LockedContentCardType) => {
    /* Props */
    const { amount = 0, weight = 0, isWeb = true } = props;

    /* Render */
    return (
        <Grid
            container
            direction="column"
            borderRadius={2}
            paddingX={1.5}
            paddingTop={1.5}
            paddingBottom={isWeb ? 1 : 2}
            bgcolor="#B18D2437"
            sx={{
                backdropFilter: "blur(8px)",
                "-webkit-backdrop-filter": "blur(8px)",
            }}
        >
            <Grid container justifyContent="space-between">
                <Grid item>
                    <Grid container direction="column">
                        <Grid item>
                            <AppText
                                children={AMOUNT}
                                fontWeight="500"
                                fontSize={10}
                                color="white"
                            />
                        </Grid>
                        <Grid item paddingTop={isWeb ? 3 : 1}>
                            <Grid container alignItems="center">
                                {isWeb && (
                                    <Grid item paddingRight={2} height={30}>
                                        <AppImage src={GoldBrickIcon} />
                                    </Grid>
                                )}
                                <Grid item>
                                    <AppText
                                        children={`${
                                            weight ? weight : 0
                                        } ${"gm"}`}
                                        color="white"
                                        sx={{
                                            fontSize: { xs: 16, [SB]: 22 },
                                            fontWeight: {
                                                xs: "700",
                                                [SB]: "900",
                                            },
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <AppText
                        paddingX={3}
                        paddingY={0.8}
                        borderRadius={3}
                        bgcolor="#FFFFFF15"
                        children={`₹${amount} Value`}
                        fontWeight="500"
                        fontSize={12}
                        color="white"
                    />
                </Grid>
            </Grid>
        </Grid>
        // </Grid>
    );
};

import { useParams } from "react-router-dom";
import { getRequest } from "../../../../network/Request";
import {
    GET_INVOICE,
    GET_PAYMENT_DETAIL,
    PAYMENT_STATUS,
} from "../../../../network/Url";
import { useUrlQuery } from "../../../../hooks/useUrlQuery";
import {
    ERROR,
    LOADING,
    PAYMENT_COMPLETE_ORDER_ID,
    PAYMENT_COMPLETE_SHORT_ID,
    PAYMENT_USERNAME,
    SUCCESS,
} from "../../../../constants/AppConstant";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import { updatePaymentDetail } from "../../../../redux/slices/content";
import { useAppNavigation } from "../../../../hooks/useAppNavigation";
import { SHOW_INVOICE } from "../../../../constants/RouteConstant";
import { SOMETHING_WENT_WRONG } from "../../../../constants/StringConstant";
import {
    ERROR_404,
    ERROR_SERVER,
    PAYMENT_FAILED,
} from "../../../../constants/AppString";

export const usePaymentComplete = () => {
    /* Hooks */
    const { getUrlParam } = useUrlQuery();
    const dispatch = useDispatch<AppDispatch>();

    /* States */
    const [paymentDetail, setPaymentDetail] = useState<null | any>();
    const [paymentStatus, setPaymentStatus] = useState<any>("");

    /* Custom hooks */
    const { navigate } = useAppNavigation();

    /* Handler */
    const getPaymentStatus = async ({
        orderId,
        second,
    }: {
        orderId: string;
        second: number;
    }) => {
        try {
            setPaymentStatus(LOADING);
            const res: any = await getRequest({
                url: PAYMENT_STATUS + "/" + orderId,
            });
            if (res?.data?.status === "inProcess") {
                if (second > 60) {
                    setPaymentStatus(ERROR_404);
                    return;
                }
                const orderId = getUrlParam(PAYMENT_COMPLETE_ORDER_ID);
                if (orderId) {
                    const timer = setTimeout(() => {
                        getPaymentStatus({
                            orderId,
                            second: second + 5,
                        });
                    }, 5000);
                    return () => clearTimeout(timer);
                }
            }
            if (!paymentDetail) {
                getPaymentDetail();
            }
            if (res?.data?.status === "processed") {
                setPaymentStatus(SUCCESS);
                return;
            }
            if (res?.data?.status === "paymentFailedWebhook") {
                setPaymentStatus(PAYMENT_FAILED);
                return;
            }
            setPaymentStatus(ERROR_404);
        } catch {
            setPaymentStatus(ERROR_SERVER);
        }
    };

    const getPaymentDetail = async () => {
        try {
            const res: any = await getRequest({
                url:
                    GET_PAYMENT_DETAIL +
                    "/" +
                    getUrlParam(PAYMENT_COMPLETE_SHORT_ID),
            });
            if (res?.data) {
                dispatch(
                    updatePaymentDetail({
                        title: res?.data?.title,
                        description: res?.data?.description,
                        createdAt: res?.data?.createdAt,
                        expiredAt: res?.data?.expiredAt,
                        thumbnail: res?.data?.image,
                    })
                );
                setPaymentDetail(res?.data);
            }
        } catch {}
    };

    const openInvoice = () => {
        navigate(SHOW_INVOICE + "/" + getUrlParam(PAYMENT_COMPLETE_ORDER_ID));
    };

    /* Effects */
    // useEffect(() => {
    //     if (!paymentDetail) {
    //         getPaymentDetail();
    //         return;
    //     }
    // }, [paymentDetail]);

    // useEffect(() => {
    //     if (!invoice) {
    //         getInvoice();
    //         return;
    //     }
    // }, [invoice]);

    useEffect(() => {
        const orderId = getUrlParam(PAYMENT_COMPLETE_ORDER_ID);
        if (orderId && !getUrlParam("isFreeConent")) {
            setPaymentStatus(LOADING);
            getPaymentStatus({ orderId, second: 5 });
            // const timer = setTimeout(() => {

            // }, 5000);
            // return () => clearTimeout(timer);
        }
    }, []);

    useEffect(() => {
        if (getUrlParam("isFreeConent") && !paymentDetail) {
            getPaymentDetail();
        }
    }, []);

    useEffect(() => {
        if (getUrlParam("isFreeConent")) {
            setPaymentStatus(SUCCESS);
            return;
        }
    }, []);

    return {
        paymentStatus,
        getPaymentDetail,
        paymentDetail,
        username: getUrlParam(PAYMENT_USERNAME),
        isFreeContent: !!getUrlParam("isFreeConent"),
        openInvoice,
    };
};

import React from "react";

export const ShareYoutubeIcon = () => {
    return (
        <svg
            width={56}
            height={56}
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 28c0 15.464 12.536 28 28 28s28 -12.536 28 -28S43.464 0 28 0 0 12.536 0 28Z"
                fill="#FF0000"
            />
            <path
                d="M41.3 21.176a3.466 3.466 0 0 0 -2.45 -2.45C36.75 18.2 27.824 18.2 27.824 18.2S19.076 18.2 16.8 18.726c-1.226 0.35 -2.1 1.224 -2.45 2.45C14 23.447 14 28 14 28s0 4.55 0.526 6.826c0.35 1.224 1.224 2.1 2.45 2.447 2.1 0.526 11.024 0.526 11.024 0.526s8.75 0 11.026 -0.526c1.224 -0.347 2.1 -1.224 2.447 -2.447C42 32.547 42 28 42 28s0 -4.55 -0.7 -6.826ZM25.2 32.2v-8.4l7.35 4.2L25.2 32.2Z"
                fill="white"
            />
        </svg>
    );
};

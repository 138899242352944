import { Grid } from "@mui/material";
import { PageContainer } from "../../../../components/PageContainer";
import { AppImage } from "../../../../components/AppImage";
import { SupportCreatorBackground } from "../../../../utils/Image";
import { FullSpaceGrid } from "../../../../components/FullSpaceGrid";
import { PayHeader } from "../PayHeader";
import {
    ACCEPT_ALL,
    ENTER_AMOUNT,
    FIRST_SUPPORT_USER,
    GOLD_VALUE,
    GST,
    PAY,
    PAY_TO,
    RUPEE,
    SUPPORT_THEM,
    TERM_AND_CONDITIION,
    USERLINK_COPIED,
} from "../../../../constants/AppString";
import { useGetProfileRD } from "../../../Profile/hooks/useGetProfileRD";
import { UserProfileImage } from "../../../../components/UserProfileImage";
import { MultiplePayUser } from "../../../../components/MultiplePayUser";
import { usePaymentRD } from "../../hooks/usePaymentRD";
import { PayCard } from "../../../../components/PayCard";
import { ReactNode, useState } from "react";
import { useScreenDimension } from "../../../../hooks/useScreenDimension";
import { ColumnGrid } from "../../../../components/ColumnGrid";
import { Terms } from "../Terms";
import { PaymentTimer } from "../PaymentTimer";
import { PaymentButton } from "../PaymentButton";
import { AppSnackBar } from "../../../../components/AppSnackBar";
import { usePaymentRoute } from "../../hooks/usePaymentRoute";
import { GoldBricks } from "../../../../assets/icons/GoldBricks";
import { AppText } from "../../../../components/AppText";
import { HorizontalLine } from "../../../../components/HorizontalLine";
import { PaymentBreakDown } from "../PaymentBreakDown";
import { PaymentBreakDownSection } from "../PaymentBreakDownSection";
import { MobilePaymentBreakDownSectionContainer } from "../MobilePaymentBreakDownSectionContainer";
import { OtpVerifySection } from "../OptVerifySection";

type SupportCreatorMobilePaymentBackgroundType = {
    bottomComponent: ReactNode;
    goldPrice: number | null;
    getNewGoldPrice: () => void;
    amount: string;
    supportUserPaymentFinalizer: () => void;
    disable: boolean;
    backHandler?: () => void;
    gstRate: string;
    goldWeightPrice: string;
    loading?: boolean;
    openOtpVerify?: boolean;
    resetOtpHandler?: () => void;
    verifyOtpHandler?: any;
    resendOtpHandler?: any;
    optSentDetail?: any;
    mobileNumberVerified?: any;
    getGoldPrice?: any;
    goldWeight?: any;
    gstPrice?: any;
    mobileLoading?: any;
};

export const SupportCreatorMobilePaymentBackground = (
    props: SupportCreatorMobilePaymentBackgroundType
) => {
    /* Props */
    const {
        bottomComponent,
        goldPrice,
        getNewGoldPrice,
        amount,
        supportUserPaymentFinalizer,
        disable,
        backHandler,
        gstRate,
        goldWeightPrice,
        loading,
        openOtpVerify,
        resetOtpHandler,
        verifyOtpHandler,
        resendOtpHandler,
        optSentDetail,
        mobileNumberVerified,
        getGoldPrice,
        goldWeight,
        gstPrice,
        mobileLoading,
    } = props;

    /* Custom Hooks */
    const { profileData } = useGetProfileRD();
    const { transactionCount } = usePaymentRD();

    /* Render */
    const USER_TEXT = `${PAY_TO} ${profileData?.creatorInfo?.firstName}`;

    return (
        <PageContainer>
            <FullSpaceGrid position="relative" bgColor="#0B0B0C">
                <Grid item>
                    <AppImage src={SupportCreatorBackground} />
                </Grid>
                <Grid item position="absolute" right={0} left={0}>
                    <ColumnGrid>
                        <Grid item paddingX={2} paddingTop={2}>
                            <PayHeader
                                title={USER_TEXT}
                                backHanlder={backHandler}
                            />
                        </Grid>
                        <Grid item paddingTop={2}>
                            <Grid container justifyContent="center">
                                <Grid item>
                                    <UserProfileImage />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item paddingTop={2} paddingBottom={4}>
                            <MultiplePayUser
                                noOfUser={transactionCount}
                                userText={
                                    transactionCount
                                        ? `+${transactionCount} ${SUPPORT_THEM}`
                                        : FIRST_SUPPORT_USER
                                }
                            />
                        </Grid>
                        <Grid item>{bottomComponent}</Grid>
                    </ColumnGrid>
                </Grid>
                <PaymentBreakDownSection
                    mobile
                    isPayment
                    goldPrice={goldPrice ? goldPrice?.toString() : "0"}
                    getNewGoldPrice={getNewGoldPrice}
                    amount={amount}
                    supportUserPaymentFinalizer={supportUserPaymentFinalizer}
                    disable={disable}
                    gstRate={gstRate}
                    goldWeightPrice={goldWeightPrice}
                    loading={loading}
                    goldWeight={goldWeight}
                    gstPrice={gstPrice}
                />
                {openOtpVerify && (
                    <OtpVerifySection
                        mobile
                        mobileLoading={mobileLoading}
                        resetOtpHandler={resetOtpHandler}
                        verifyOtpHandler={verifyOtpHandler}
                        resendOtpHandler={resendOtpHandler}
                        optSentDetail={optSentDetail}
                        mobileNumberVerified={mobileNumberVerified}
                    />
                )}
            </FullSpaceGrid>
        </PageContainer>
    );
};

import axios, { InternalAxiosRequestConfig } from "axios";

const apiRequest = axios.create({});

export const getAuthHeader = () => {
    return {
        Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1ODAyYzVkOTg1ZjU1NTIxMDYwMDVhMiIsImVtYWlsIjoiRXZhbnNfWmllbWUyNEBob3RtYWlsLmNvbSIsImFnZW5jeUlkIjoiIiwidXNlclR5cGUiOiIiLCJpYXQiOjE3MDI4OTg3ODEsImV4cCI6MzQxNDQzNzU2MiwiaXNzIjoiWW91ckdvbGQifQ.47vpRYR06Y9zWEUlmTF-f-zzHhJe9WrrGRNJPJLXlHw",
    };
};

// const getAccessToken = async () => {
//     return await window.sessionStorage.getItem("Token");
// };

const setAuthorizationHeader = async (
    config: InternalAxiosRequestConfig<any>
) => {
    // const accessToken = await getAccessToken(); // Replace with your token retrieval logic
    // if (accessToken) {
    //     config.headers["Authorization"] = Bearer ${accessToken};
    // }
    return config;
};

apiRequest.interceptors.request.use(setAuthorizationHeader);

// const responseManager = () => {
//     return {
//         data: null,
//         error: "",
//     };
// };

export const postRequest = async ({
    url,
    payload,
    headers,
}: {
    url: any;
    payload: any;
    headers?: any;
}) => {
    const res = await apiRequest.post(url, payload, {
        headers,
    });
    return res;
};

export const getRequest = async ({
    url,
    headers,
}: {
    url: any;
    headers?: any;
}) => {
    return await apiRequest.get(url, { headers });
};

export const putRequest = async ({
    url,
    payload,
    headers,
}: {
    url: any;
    payload?: any;
    headers?: any;
}) => {
    return await apiRequest.put(url, payload, {
        headers,
    });
};

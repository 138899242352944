const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

export const getFirstName = (name: string): string => {
    if (!name) {
        return "";
    }
    return name?.split(" ")?.slice(0, 1)?.toString();
};

export const getMiddleName = (name: string): string => {
    if (!name) {
        return "";
    }
    return name?.split(" ")?.slice(1, -1)?.toString();
};

export const getLastName = (name: string): string => {
    if (!name) {
        return "";
    }
    return name?.split(" ")?.slice(-1)?.toString();
};

export const addZeroBefore = (text: string) => {
    if (!text) {
        return;
    }
    return text?.length === 1 ? `0${text}` : text;
};

type VALIDATOR_LIST_TYPE = {
    valueType:
        | typeof VALIDATE_ALPHABET
        | typeof VALIDATE_NUMBER
        | typeof VALIDATE_EMAIL
        | typeof VALIDATE_PASSWORD
        | typeof VALIDATE_ALPHA_NUMERIC;
    value: string;
    valueName: string;
}[];

export const VALIDATE_ALPHABET = "Albhabet";
export const VALIDATE_NUMBER = "number";
export const VALIDATE_EMAIL = "email";
export const VALIDATE_PASSWORD = "password";
export const VALIDATE_ALPHA_NUMERIC = "Alpha numeric";

const validateAlphbet = /^[a-zA-Z ]+$/;

const validateAlphabetWithSpace = /^[a-zA-Z ]*$/;

const validateNumber = /^[0-9]+$/;

const validateAlphaNumeric = /^[a-z0-9]+$/i;

const validateNumberWithDecimal = /^\d+(\.\d{1,2})?$/;

const validatePan = /^[A-Z]{5}[0-9]{4}[A-Z]$/;

const validateEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const validatePassword = /^(?=.[0-9])(?=.[!@#$%^&])[a-zA-Z0-9!@#$%^&]{6,16}$/;

export const validateAlphbetHandler = (text: string) => {
    return text?.match(validateAlphbet);
};

export const validateAlphabetWithSpaceHandler = (text: string) => {
    return text?.match(validateAlphabetWithSpace);
};

export const validateNumericHandler = (value: string) => {
    return value?.match(validateNumber);
};

export const validateDecimalHandler = (value: string) => {
    if (!value) {
        return;
    }
    const number = parseFloat(value);

    return !isNaN(number) && !Number.isInteger(number);
};

export const validateNumericWithDecimalHandler = (value: string) => {
    return value?.match(validateNumberWithDecimal);
};

export const validatePanHandler = (value: string) => {
    if (!value) {
        return;
    }
    return value?.match(validatePan);
};

export const validateAlphaNumericHandler = (value: string) => {
    return value?.match(validateAlphaNumeric);
};

export const validateEmailHandler = (email: string) => {
    return email?.match(validateEmail);
};

export const validatePasswordHandler = (password: string) => {
    return password?.match(validatePassword);
};

export const isValueSame = (value1: string, value2: string) => {
    if (value1 === value2) {
        return true;
    }
    return false;
};

export const valueValidatorHandler = (
    valueList: VALIDATOR_LIST_TYPE
): string => {
    const errorValue = valueList.find((item) => {
        switch (item.valueType) {
            case VALIDATE_ALPHABET:
                return !validateAlphbetHandler(item?.value);
            case VALIDATE_NUMBER:
                return !validateNumericHandler(item?.value);
            case VALIDATE_EMAIL:
                return !validateEmailHandler(item?.value);
            case VALIDATE_PASSWORD:
                return !validatePasswordHandler(item?.value);
            case VALIDATE_ALPHA_NUMERIC:
                return !validateAlphaNumericHandler(item?.value);
            default:
                return false;
        }
    });
    if (errorValue) {
        const { valueName } = errorValue;
        return `${valueName} is not a valid ${errorValue.valueType}`;
    }
    return "";
};

export const generateRandomString = (length: number) => {
    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        );
    }
    return result;
};

export const camelCaseToSpace = (input: string): string => {
    if (!input) {
        return input;
    }
    const parts = input.split(/(?=[A-Z])/);
    const result = parts.join(" ");
    return result.charAt(0).toLowerCase() + result.slice(1);
};

export const snackCaseToSpace = (input: string): string => {
    if (!input) {
        return input;
    }
    const result = input.split("_")?.join(" ");
    return result;
};

export const toTitleCase = (input: string): string => {
    if (!input) {
        return input;
    }
    return input.replace(/\w\S*/g, (word) => {
        return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
    });
};

export const trimDecimalDigits = ({ inputNumber }: { inputNumber: number }) => {
    // Check if the input is a valid number
    if (typeof inputNumber !== "number" || isNaN(inputNumber)) {
        return "Invalid input";
    }

    // Convert the number to a string
    const numberString = inputNumber.toString();

    // Check if the number is a decimal (contains a decimal point)
    if (numberString.includes(".")) {
        // Split the number into integer and decimal parts
        const [integerPart, decimalPart] = numberString.split(".");

        // Check if the decimal part has more than 4 digits
        if (decimalPart.length > 4) {
            // Trim the decimal part to 4 digits
            const trimmedDecimalPart = decimalPart.slice(0, 4);

            // Combine the integer part and the trimmed decimal part
            const result = `${integerPart}.${trimmedDecimalPart}`;

            return parseFloat(result); // Convert the result back to a number
        }
    }

    // Return the original number if it's not a decimal or if the decimal part has 4 or fewer digits
    return inputNumber;
};

import { Theme } from "@emotion/react";
import {
    Box,
    FilledInputProps,
    FilledTextFieldProps,
    Input,
    InputProps,
    OutlinedInputProps,
    OutlinedTextFieldProps,
    StandardTextFieldProps,
    SxProps,
    TextField,
    TextFieldProps,
    TextFieldVariants,
} from "@mui/material";
import { PAN_ERROR } from "../../constants/AppString";
import { AppText } from "../AppText";
import { forwardRef } from "react";

export type AppInputType = {
    InputProps?:
        | Partial<FilledInputProps>
        | Partial<OutlinedInputProps>
        | Partial<InputProps>
        | undefined;
    variant?: "standard" | "outlined" | "standard";
    sx?: SxProps<Theme> | undefined;
    placeholder?: string | undefined;
    InputLabelProps?: any;
    onChange?: any;
    value?: any;
    type?: React.HTMLInputTypeAttribute | undefined;
    msg?: {
        value: string;
        color?: string;
        error: boolean;
    };
} & TextFieldProps;

export const AppInput = forwardRef((props: AppInputType, ref: any) => {
    /* Props */
    const {
        InputProps,
        variant = "standard",
        sx,
        placeholder,
        InputLabelProps,
        value,
        onChange,
        type,
        onBlur,
        msg,
    } = props;

    /* Render */
    return (
        <>
            <TextField
                inputRef={ref}
                variant={variant}
                fullWidth
                InputProps={InputProps}
                sx={sx}
                placeholder={placeholder}
                InputLabelProps={InputLabelProps}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                type={type}
                {...props}
            />
            {!!msg && (
                <Box paddingTop={1}>
                    <AppText
                        children={msg?.value}
                        color={msg?.color ? msg?.color : "#fff"}
                        fontSize={10}
                    />
                </Box>
            )}
        </>
    );
});

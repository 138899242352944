import React from "react";

export const BeIcon = () => {
    return (
        <svg
            width={56}
            height={56}
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 28C0 12.536 12.536 0 28 0s28 12.536 28 28 -12.536 28 -28 28S0 43.464 0 28Z"
                fill="#1157FF"
            />
            <path
                d="M22.145 19.25c0.806 0 1.554 0.062 2.237 0.249 0.683 0.123 1.243 0.372 1.742 0.683 0.496 0.311 0.868 0.745 1.12 1.305 0.246 0.56 0.37 1.243 0.37 1.988 0 0.874 -0.185 1.618 -0.622 2.178 -0.372 0.56 -0.994 1.056 -1.739 1.428 1.056 0.314 1.865 0.871 2.36 1.618 0.498 0.745 0.812 1.68 0.812 2.736 0 0.868 -0.188 1.616 -0.501 2.24a4.136 4.136 0 0 1 -1.366 1.551c-0.56 0.372 -1.243 0.683 -1.988 0.871a9.248 9.248 0 0 1 -2.24 0.311H14V19.25h8.145ZM21.644 26.214c0.683 0 1.243 -0.188 1.68 -0.498 0.434 -0.308 0.619 -0.868 0.619 -1.554a2.366 2.366 0 0 0 -0.185 -0.994 1.764 1.764 0 0 0 -0.56 -0.622c-0.249 -0.126 -0.498 -0.252 -0.809 -0.311 -0.308 -0.062 -0.622 -0.062 -0.994 -0.062H17.791v4.04h3.856Zm0.188 7.336c0.372 0 0.745 -0.062 1.056 -0.126a2.181 2.181 0 0 0 1.492 -1.056 2.968 2.968 0 0 0 0.249 -1.12c0 -0.868 -0.252 -1.492 -0.748 -1.926 -0.496 -0.372 -1.182 -0.56 -1.988 -0.56H17.791v4.788h4.043Zm11.998 -0.062c0.496 0.496 1.243 0.745 2.237 0.745 0.683 0 1.305 -0.188 1.803 -0.498 0.498 -0.372 0.809 -0.745 0.932 -1.12h3.046c-0.498 1.495 -1.243 2.548 -2.24 3.234a6.642 6.642 0 0 1 -3.604 0.994 7.308 7.308 0 0 1 -2.674 -0.496 4.964 4.964 0 0 1 -1.988 -1.369c-0.56 -0.56 -0.997 -1.243 -1.243 -2.05a7.314 7.314 0 0 1 -0.437 -2.674c0 -0.932 0.123 -1.803 0.434 -2.612 0.314 -0.806 0.748 -1.49 1.308 -2.114 0.56 -0.56 1.243 -1.056 1.988 -1.366 0.809 -0.311 1.618 -0.498 2.612 -0.498 1.056 0 1.988 0.188 2.797 0.622 0.809 0.437 1.428 0.932 1.926 1.68 0.498 0.683 0.871 1.492 1.12 2.363 0.123 0.868 0.188 1.739 0.123 2.733h-9.016c0 0.997 0.375 1.929 0.874 2.428Zm3.917 -6.53c-0.437 -0.434 -1.12 -0.683 -1.929 -0.683 -0.56 0 -0.994 0.123 -1.366 0.308 -0.372 0.19 -0.622 0.437 -0.871 0.686a1.658 1.658 0 0 0 -0.434 0.871c-0.064 0.311 -0.126 0.56 -0.126 0.809h5.594c-0.123 -0.932 -0.434 -1.554 -0.868 -1.988Zm-5.471 -6.527h6.961v1.68h-6.961v-1.68Z"
                fill="white"
            />
        </svg>
    );
};

import React from "react";

type AppImageType = {
    src: string | undefined;
    borderRadius?: string | number;
    objectFit?: "fill" | "contain" | "cover" | "none" | "scale-down";
};

export const AppImage = (props: AppImageType) => {
    /* Props */
    const { src, borderRadius, objectFit = "cover" } = props;

    /* Render */
    return (
        <img
            src={src}
            height="100%"
            width="100%"
            style={{
                borderRadius,
                objectFit,
            }}
        />
    );
};

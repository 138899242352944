import { Grid } from "@mui/material";
import { AppInput, AppInputType } from "../AppInput";
import { AppText } from "../AppText";

type LabelInputType = {
    labelText: string;
    placeholder?: string;
} & AppInputType;

export const LabelInput = (props: LabelInputType) => {
    /* Props */
    const { labelText, placeholder, onChange, value, msg } = props;

    /* Render */
    return (
        <Grid container>
            <Grid item xs={12}>
                <AppText
                    children={labelText}
                    fontWeight="400"
                    fontSize={14}
                    color={msg?.error ? msg?.color : "#ffffff"}
                />
            </Grid>
            <Grid item xs={12} paddingTop={0.5}>
                <AppInput
                    {...props}
                    onChange={onChange}
                    value={value}
                    sx={{
                        border: `1px solid ${
                            msg?.error ? msg?.color : "#2A2A2A"
                        }`,
                        borderRadius: 4,
                        "& .MuiInput-underline:before": {
                            borderBottomWidth: 0,
                        },
                        "& .MuiInput-underline:after": {
                            borderBottomWidth: 0,
                        },
                        "& input": {
                            color: msg?.error ? msg?.color : "#ffffff",
                            paddingY: 2,
                            paddingX: 1.5,
                        },
                    }}
                    InputProps={{
                        ...props?.InputProps,
                        style: {
                            fontFamily: "Hanken Grotesk",
                        },
                    }}
                />
                ;
            </Grid>
        </Grid>
    );
};

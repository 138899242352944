import { useLocation, useNavigate } from "react-router-dom";

export const useAppNavigation = () => {
    /* Hooks */
    const navigate = useNavigate();
    const location = useLocation();

    /* Handler */
    const goBackHandler = () => {
        navigate(-1);
    };

    return {
        navigate,
        currentRoute: location?.pathname,
        goBackHandler,
        navigationRoute: location?.state,
        location,
    };
};

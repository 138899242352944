import { CircularProgress, Grid, LinearProgress } from "@mui/material";
import { ResponsiveLayout } from "../../../../components/ResponsiveLayout";
import { PageContainer } from "../../../../components/PageContainer";
import { FullSpaceGrid } from "../../../../components/FullSpaceGrid";
import { useParams } from "react-router-dom";
import { useShortIdPayment } from "../../hooks/useShortIdPayment";
import { useEffect } from "react";
import { usePaymentRoute } from "../../hooks/usePaymentRoute";
import { useGetProfileRD } from "../../../Profile/hooks/useGetProfileRD";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import { useSession } from "../../../../hooks/useSession";
import { ErrorBoundary } from "../../../../components/ErrorBoundary";
import { ERROR } from "../../../../constants/AppConstant";
import { ERROR_404, ERROR_SERVER } from "../../../../constants/AppString";

export const ShortLinkPaymentPage = () => {
    /* Custom hooks */
    const { getPaymentDetail, linkDetail } = useShortIdPayment();

    /* Effects */
    useEffect(() => {
        getPaymentDetail();
    }, []);

    /* Render */
    const LOADER = (
        <PageContainer>
            <FullSpaceGrid
                justifyContent="center"
                alignItems="center"
                height="100%"
                bgColor={"#0B0B0C"}
            >
                <Grid item>
                    <CircularProgress size={24} />
                </Grid>
            </FullSpaceGrid>
        </PageContainer>
    );

    if ([ERROR_404, ERROR_SERVER]?.includes(linkDetail)) {
        return <ErrorBoundary errorType={linkDetail} />;
    }

    return <ResponsiveLayout mobile={LOADER} web={LOADER} />;
};

import { Grid, Typography } from "@mui/material";
import { HandPayIcon } from "../../../../assets/icons/HandPayIcon";
import { useAppNavigation } from "../../../../hooks/useAppNavigation";
import { SUPPORT_CREATOR } from "../../../../constants/AppString";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import { useGetProfileRD } from "../../hooks/useGetProfileRD";
import { usePaymentRoute } from "../../../PayWall/hooks/usePaymentRoute";
import { AppText } from "../../../../components/AppText";

export const SupportCreator = () => {
    /* Hooks */
    const dispatch = useDispatch<AppDispatch>();

    /* Custom hooks */
    const { navigate } = useAppNavigation();
    const { profileData } = useGetProfileRD();
    const { paymentGeneralLinkHandler } = usePaymentRoute();

    /* Handler */
    const supportTheCreatorHandler = () => {
        if (profileData?.creatorInfo?.defaultLinkData?.shortId) {
            paymentGeneralLinkHandler({
                shortId: profileData?.creatorInfo?.defaultLinkData?.shortId,
            });
        }
    };

    /* Render */
    return (
        <Grid
            item
            marginTop={2}
            marginBottom={3}
            marginX={2}
            borderRadius={44}
            border={1}
            paddingY={1.8}
            borderColor={"#353535"}
            alignItems="center"
            onClick={supportTheCreatorHandler}
            style={{
                background: "linear-gradient(#F8DC71, #CC8718)",
                cursor: "pointer",
            }}
            boxShadow={"0px 0px 24px 0px #FFDF4340"}
        >
            <Grid container justifyContent="center" alignItems="center">
                <Grid item>
                    <HandPayIcon />
                </Grid>
                <Grid item marginLeft={1}>
                    <AppText
                        color="#000"
                        fontWeight="700"
                        fontSize={14}
                        textAlign="center"
                    >
                        {SUPPORT_CREATOR}
                    </AppText>
                </Grid>
            </Grid>
        </Grid>
    );
};

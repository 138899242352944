import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AppText } from "../../../../components/AppText";
import {
    HIDE_BREAKDOWN,
    LIVE_GOLD_PRICE,
    VALID_FOR,
    VIEW_BREAKDOWN,
} from "../../../../constants/AppString";
import moment from "moment";
import { addZeroBefore } from "../../../../utils/StringHelper";
import { GOLD_PRICE_TIME } from "../../../../constants/AppConstant";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import { updatePaymentDetail } from "../../../../redux/slices/content";
import { usePaymentRD } from "../../hooks/usePaymentRD";
import { ColumnGrid } from "../../../../components/ColumnGrid";
import { AppImage } from "../../../../components/AppImage";
import { DownIcon, UpIcon } from "../../../../utils/Image";

type PaymentTimerType = {
    price?: string;
    time?: string;
    getNewGoldPrice: () => void;
    open: boolean;
    paymentBrakDownHandler: () => void;
};

export const PaymentTimer = (props: PaymentTimerType) => {
    /* Props */
    const {
        price = 0,
        time = 0,
        getNewGoldPrice,
        open,
        paymentBrakDownHandler,
    } = props;

    /* Hooks */
    const dispatch = useDispatch<AppDispatch>();

    /* Custom hooks */
    const { priceMinute, priceSecond } = usePaymentRD();

    /* Handlers */
    const resetTimer = () => {
        getNewGoldPrice();
        dispatch(
            updatePaymentDetail({
                priceMinute: GOLD_PRICE_TIME?.toString(),
                priceSecond: 59,
            })
        );
    };

    /* Effects */
    useEffect(() => {
        const intervalId = setInterval(() => {
            // console.log("teimer", priceMinute, priceSecond);
            if (priceMinute === 0 && priceSecond === 0) {
                clearInterval(intervalId);
                resetTimer();
            } else {
                if (priceSecond === 0) {
                    dispatch(
                        updatePaymentDetail({
                            priceSecond: 59,
                            priceMinute: priceMinute - 1,
                        })
                    );
                } else {
                    dispatch(
                        updatePaymentDetail({
                            priceSecond: priceSecond - 1,
                        })
                    );
                }
            }
        }, 1000);
        return () => {
            clearInterval(intervalId);
        };
    }, [priceMinute, priceSecond]);

    /* Render */
    return (
        <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
                <ColumnGrid paddingX={1.5} borderRadius={3}>
                    <Grid item>
                        <AppText
                            children={`${LIVE_GOLD_PRICE} ${price}/gm`}
                            fontSize={10}
                            color={"#ffffff80"}
                        />
                    </Grid>
                    <Grid item>
                        <AppText
                            children={`${VALID_FOR}  ${addZeroBefore(
                                priceMinute?.toString()
                            )}:${addZeroBefore(priceSecond?.toString())} m`}
                            fontSize={10}
                            color="#ECC458"
                        />
                    </Grid>
                </ColumnGrid>
            </Grid>
            <Grid item>
                <Grid
                    container
                    alignItems="center"
                    onClick={paymentBrakDownHandler}
                    sx={{
                        cursor: "pointer",
                    }}
                >
                    <Grid item>
                        <AppText
                            children={open ? HIDE_BREAKDOWN : VIEW_BREAKDOWN}
                            fontSize={12}
                            fontWeight="700"
                        />
                    </Grid>
                    <Grid item>
                        <Grid container>
                            <Grid item paddingLeft={1}>
                                <AppImage src={open ? DownIcon : UpIcon} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

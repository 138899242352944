import { Grid } from "@mui/material";
import React from "react";
import { TrendIcon } from "../../assets/icons/TrendIcon";
import { AppText } from "../AppText";

export const Trending = () => {
    return (
        <Grid
            container
            bgcolor="#F1CF6330"
            paddingX={1}
            paddingY={0.2}
            borderRadius={10}
        >
            <Grid item>
                <TrendIcon fill="#F1CF63" />
            </Grid>
            <Grid item marginLeft={0.5}>
                <AppText
                    children="Trending"
                    color="#F1CF63"
                    fontWeight="500"
                    fontSize={12}
                />
            </Grid>
        </Grid>
    );
};

import React from "react";
import { ColumnGrid } from "../ColumnGrid";
import { useScreenDimension } from "../../hooks/useScreenDimension";
import { Grid } from "@mui/material";
import { ChildrenType } from "../../types/ObjectType";

type PageContainerType = {
    children: ChildrenType;
};

export const PageContainer = (props: PageContainerType) => {
    /* Props */
    const { children } = props;

    /* Custom Hooks */
    const { browserHeight, browserWidth } = useScreenDimension();

    /* Render */
    return (
        <Grid container minHeight={browserHeight} minWidth={browserWidth}>
            <Grid item flex={1}>
                {children}
            </Grid>
        </Grid>
    );
};
